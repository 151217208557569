import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

// import { createLogger } from 'redux-logger';
import { rootReducer } from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ['']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, promise];

// if (process.env.NODE_ENV !== 'production') {
//   middlewares.push(createLogger())
// }

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
