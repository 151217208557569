import { useEffect, useState } from "react";

import { AddGroupToDb } from "@/Database/Message";
import { GroupDocInterface, UpdateableGroup } from "@/Database/Message/Group.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { TagType } from "@/types";

import styles from "./AddMemberModal.module.css";

type AddMemberModalProps = {
  group: GroupDocInterface;
  groups: Map<string, GroupDocInterface>;
  setGroups: React.Dispatch<React.SetStateAction<Map<string, GroupDocInterface> | null | undefined>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddMemberModal = ({ group, groups, setGroups, setModal }: AddMemberModalProps) => {
  const { currentUserData, usersData } = useAuth();

  const [people, setPeople] = useState<TagType[]>([]);
  const [searchedPerson, setSearchedPerson] = useState("");
  const [tags, setTags] = useState<TagType[]>([]);

  useEffect(() => {
    setTags(people.filter(elem => elem.selected));
  }, [people]);

  useEffect(() => {
    if (currentUserData == null) return;
    if (!currentUserData.followers && !currentUserData.followers) return;

    const followers = currentUserData?.followers ? currentUserData.followers : [];
    const following = currentUserData?.following ? currentUserData.following : [];
    const followersfollowing = followers.concat(following).filter((e, i, a) => a.indexOf(e) === i);

    let available = Array<TagType>();

    for (let followerfollowing of followersfollowing) {
      if (!group.members.includes(followerfollowing) && usersData?.has(followerfollowing)) {
        let user = usersData.get(followerfollowing);
        available.push({ name: user!.username!, img: user!.avatarPhoto!, selected: false, uuid: user!.uuid });
      }
    }
    available.sort((a, b) => (a.name > b.name ? 1 : -1));

    setPeople(available);
  }, [currentUserData, usersData, group.members]);

  const addingPeople = (idx: number) => {
    setPeople(prev => {
      const copy = [...prev];
      copy[idx].selected = !prev[idx].selected;
      return copy;
    });
  };

  const removeTag = (uuid: string) => {
    setPeople(prev => {
      const copy = [...prev];
      const idx = prev.findIndex(e => e.uuid === uuid);
      copy[idx].selected = false;
      return copy;
    });
  };

  const handleAdd = () => {
    const newMembers = group.members ? [...group.members] : [];
    for (let tag of tags) {
      if (!newMembers.includes(tag.uuid)) newMembers.push(tag.uuid);
    }
    const newGroup: UpdateableGroup = {
      uuid: group.uuid,
      members: newMembers,
    };
    AddGroupToDb(newGroup);
    setModal(false);
  };

  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>Add Member</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className={styles.addGrid}>
          <p>To:</p>
          <div className={styles.addedPeople}>
            {tags.map((elem: TagType, idx: number) => {
              return (
                <TaggedPerson
                  key={idx + "tagged"}
                  name={elem.name}
                  changeHandler={removeTag}
                  uuid={elem.uuid}
                />
              );
            })}

            <input
              value={searchedPerson}
              onChange={e => setSearchedPerson(e.target.value)}
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
        <div className={`${styles.suggestionDiv} grScrollbar`}>
          <h3>Suggestions</h3>
          {people
            .filter(elem => elem.name.includes(searchedPerson))
            .map((elem, idx) => {
              return (
                <div
                  key={idx + new Date().getTime() + "suggest"}
                  className={styles.suggestedPerson}
                  onClick={() => addingPeople(idx)}
                >
                  <div className={styles.leftSuggest}>
                    <HexagonImage src={elem.img} background={""} />
                    <p>{elem.name}</p>
                  </div>
                  <button
                    style={{
                      backgroundColor: elem.selected ? "#41c6da" : "transparent",
                    }}
                    className={styles.checkBox}
                  >
                    {elem.selected && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="black"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    )}
                  </button>
                </div>
              );
            })}
        </div>
        <button className={styles.button} onClick={handleAdd}>
          Add
        </button>
      </div>
    </>
  );
};

export default AddMemberModal;

type TaggedPersonProps = {
  name: string;
  uuid: string;
  changeHandler: (uuid: string) => void;
};

const TaggedPerson = ({ name, uuid, changeHandler }: TaggedPersonProps) => {
  return (
    <div className={styles.taggedPerson}>
      <p>{name}</p>{" "}
      <svg
        onClick={() => changeHandler(uuid)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  );
};
