import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "@/context/AuthContext";

type RouteDefinition = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: RouteDefinition) => {
  const { currentUserData } = useAuth();

  if (!currentUserData) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
