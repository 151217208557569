import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

import { AddGroupToDb, getCurrentChat } from "@/Database/Message";
import { GroupDocInterface, UpdateableGroup } from "@/Database/Message/Group.definition";
import { imagePlaceholder } from "@/assets/images/image";
import { useAuth } from "@/context/AuthContext";
import { MessageType, NFTType } from "@/types";

import styles from "./styles.module.css";

type ShareWithModalProps = {
  nft: NFTType;
  postId: string;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type People = {
  name: string;
  img: string;
  selected: boolean;
  id: string;
};

const ShareWithModal = ({ nft, postId, setModal }: ShareWithModalProps) => {
  const [availablePeople, setAvailablePeople] = useState<People[]>([]);
  const { usersData, currentUserData } = useAuth();

  useEffect(() => {
    if (currentUserData == null) return;
    if (!currentUserData.followers && !currentUserData.followers) return;

    const followers = currentUserData.followers ? currentUserData.followers : [];
    const following = currentUserData.following ? currentUserData.following : [];
    const followersfollowing = followers.concat(following).filter((e, i, a) => a.indexOf(e) === i);

    let available = Array<People>();

    for (let followerfollowing of followersfollowing) {
      if (usersData?.has(followerfollowing)) {
        let user = usersData.get(followerfollowing);
        available.push({ name: user!.username!, img: user!.avatarPhoto!, selected: false, id: user!.uuid });
      }
    }
    available.sort((a, b) => (a.name > b.name ? 1 : -1));

    setAvailablePeople(available);
  }, [currentUserData, usersData]);

  const [searchedPerson, setSearchedPerson] = useState("");
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    setTags(availablePeople.filter(elem => elem.selected));
  }, [availablePeople]);

  const addingPeople = (idx: number) => {
    setAvailablePeople(prev => {
      const copy = [...prev];
      copy[idx].selected = !prev[idx].selected;
      return copy;
    });
  };

  const removeTag = (idx: string) => {
    setAvailablePeople(prev => {
      const copy = [...prev];
      copy.forEach(elem => {
        if (elem.id === idx) {
          elem.selected = false;
        } else return;
      });
      return copy;
    });
  };

  const sendMessage = async (to: People) => {
    if (!currentUserData) return;

    const newMessage: MessageType = {
      message: nft.image ?? imagePlaceholder,
      sentBy: currentUserData.uuid,
      sentAt: Timestamp.fromDate(new Date()),
      type: "image",
      link: `/post/${postId}`,
    };

    const group = await getCurrentChat(currentUserData.uuid, to.id);
    if (group) {
      const newMessages = [...group.messages];
      newMessages.push(newMessage);

      const newGroup: UpdateableGroup = {
        uuid: group.uuid,
        messages: newMessages,
        modifiedAt: Timestamp.fromDate(new Date()),
      };
      AddGroupToDb(newGroup);
    } else {
      const newGroup: GroupDocInterface = {
        uuid: "",
        name: to.name,
        description: "",
        createdAt: Timestamp.fromDate(new Date()),
        createdBy: currentUserData!.uuid,
        admin: currentUserData!.uuid,
        modifiedAt: Timestamp.fromDate(new Date()),
        deleted: false,
        members: [currentUserData!.uuid, to.id],
        avatarPhoto: "",
        typing: [],
        latestMsg: "",
        time: "",
        messages: [newMessage],
        readers: [],
      };
      AddGroupToDb(newGroup);
    }
  };

  const SharePost = () => {
    let sendTo = availablePeople.filter(e => e.selected === true);
    //TODO SEND Message to sendTo selected / filter People.id.
    for (let to of sendTo) {
      sendMessage(to);
    }
    setModal(false);
  };

  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>Share with</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className={styles.addGrid}>
          <p>To:</p>
          <div className={styles.addedPeople}>
            {tags.map((elem, idx) => {
              return (
                <TaggedPerson key={idx + "tagged"} name={elem.name} changeHandler={removeTag} idx={elem.id} />
              );
            })}

            <input
              value={searchedPerson}
              onChange={e => setSearchedPerson(e.target.value)}
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
        <div className={`${styles.suggestionDiv} grScrollbar`}>
          <h3>Suggestions</h3>
          {availablePeople
            .filter(elem => elem.name.includes(searchedPerson))
            .map((elem, idx) => {
              return (
                <div key={idx + new Date().getTime() + "suggest"} className={styles.suggestedPerson}>
                  <div className={styles.leftSuggest}>
                    <img src={elem.img} alt="" />
                    <p>{elem.name}</p>
                  </div>
                  <button
                    style={{
                      backgroundColor: elem.selected ? "#41c6da" : "transparent",
                    }}
                    onClick={() => {
                      addingPeople(idx);
                    }}
                    className={styles.checkBox}
                  >
                    {elem.selected && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="black"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    )}
                  </button>
                </div>
              );
            })}
        </div>
        <button className={`primaryButton ${styles.button}`} onClick={SharePost}>
          Share
        </button>
      </div>
    </>
  );
};

export default ShareWithModal;

type TaggedPersonProps = {
  name: string;
  idx: string;
  changeHandler: (idx: string) => void;
};

const TaggedPerson = ({ name, idx, changeHandler }: TaggedPersonProps) => {
  return (
    <div className={styles.taggedPerson}>
      <p>{name}</p>{" "}
      <svg
        onClick={() => changeHandler(idx)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  );
};
