import { MdClose } from "react-icons/md";

import { useAuth } from "@/context/AuthContext";

import styles from "./styles.module.css";

type ConnectUnsuccessFullProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  haveToLogout?: boolean;
};

const ConnectUnsuccessFull = ({ setModal, message, haveToLogout }: ConnectUnsuccessFullProps) => {
  const { Logout } = useAuth();

  const LogoutElement = () => {
    return (
      <p className={styles.tex} onClick={Logout}>
        Logout
      </p>
    );
  };

  return (
    <>
      {" "}
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={`${styles.modalWrapper} `}>
        {" "}
        <div className={`${styles.smallModal} ${styles.connectUnsuccessFull}`}>
          <MdClose className={styles.close} color="#fff" onClick={() => setModal(false)} />
          <h4 className={styles.heading}>Unable to connect your wallet</h4>
          {message.length > 0 && <p className={styles.error}> {message} </p>}
          <p className={styles.text}>Oops! There was an error when trying to connect with your wallet.</p>
          <p className={styles.text}>Please try again!</p>
          {haveToLogout && <LogoutElement />}
        </div>
      </div>
    </>
  );
};

export default ConnectUnsuccessFull;
