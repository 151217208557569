export const SET_USER_WALLET_CONNECT = "SET_USER_WALLET_CONNECT";

export type UserState = {
  wallet: string;
};

type SetUserWalletAction = {
  type: "SET_USER_WALLET_CONNECT";
  payload: string;
};

export type UserAction = SetUserWalletAction;

export type SetUserWalletPayload = {
  walletName: string;
};
