import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserDocInterface } from "@/Database/User/User.definition";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { useUser } from "@/hooks";

import styles from "./styles.module.css";

type UserProps = {
  user: UserDocInterface;
};

const User = ({ user }: UserProps) => {
  const [followStatus, setFollowStatus] = useState("");
  const { currentUserData } = useAuth();
  const { follow, unfollow } = useUser();
  const navigate = useNavigate();
  const { setShowConnectWalletModal } = useGeneralContext();

  useEffect(() => {
    setFollowStatus(currentUserData?.following?.includes(user.uuid) ? "Unfollow" : "Follow");
  }, [currentUserData, user]);

  const handleUserClick = () => {
    navigate(`/userProfile/${user.username}`);
  };

  const handleFollowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!user) return;

    if (followStatus === "Follow") {
      follow(user.uuid);
    }

    if (followStatus === "Unfollow") {
      unfollow(user.uuid);
    }
  };

  return (
    <div className={styles.userContainer}>
      <div className={styles.uerImageAndName}>
        <div className={styles.userImage}>
          <HexagonImage src={user.avatarPhoto} background={""} />
        </div>
        <p className={styles.userName} onClick={handleUserClick}>
          {user.username}
        </p>
      </div>
      <button className={styles.addFriend} onClick={handleFollowClick}>
        {followStatus}
      </button>
    </div>
  );
};

export default User;
