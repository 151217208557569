import Cardano, { CSL } from "./cardano-auth/csl";

export interface CardanoProvider {
  connect(): Promise<void>;
  getAddress(): Promise<any>;
  getStakeAddress(address: string): string;
  signMessage(message: string): Promise<DataSignature>;
  getSigner(): any;
}

export interface Extension {
  cip: number;
}

export interface DataSignature {
  signature: string;
  key: string;
}

export interface CardanoAPI {
  getExtensions(): Promise<Extension[]>;
  getNetworkId(): Promise<number>;
  getBalance(): Promise<string>;
  getChangeAddress(): Promise<string>;
  signData(address: string, message: string): Promise<DataSignature>;
}

export class CIP30CardanoProvider implements CardanoProvider {
  provider: any;
  api?: CardanoAPI;
  csl?: CSL;
  constructor(wallet: string) {
    if (typeof cardano == undefined) {
      throw new Error("Cardano global object not found");
    }
    const provider = cardano[wallet];
    if (!provider) {
      throw new Error(`'${wallet}' provider not found`);
    }
    this.provider = provider;
  }

  async connect(): Promise<void> {
    if (!this.api) {
      await Cardano.load();
      this.csl = Cardano.Instance;
      this.api = await this.provider.enable();
    }
  }

  async getAddress(): Promise<any> {
    const address = await this.api!.getChangeAddress();
    return this.csl!.Address.from_hex(address);
  }

  async signMessage(message: string): Promise<DataSignature> {
    const address = await this.api!.getChangeAddress();
    return this.api!.signData(address, message);
  }

  getStakeAddress(address: string): string {
    try {
      const addr = this.csl!.Address.from_bech32(address);
      const baseAddr = this.csl!.BaseAddress.from_address(addr);
      let stakeAddr = "";
      if (baseAddr) {
        const stakeCredential = baseAddr.stake_cred();
        const reward = this.csl!.RewardAddress.new(addr.network_id(), stakeCredential);
        stakeAddr = reward.to_address().to_bech32();
      }
      return stakeAddr;
    } catch (err) {
      return "";
    }
  }

  getSigner() {
    return this.api;
  }
}
