import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

import { CommentDocInterface } from "@/Database/Posts/Post.definition";
import { GetUserFromDb } from "@/Database/User";
//import { numberFormating } from "@/utils";
import { UserDocInterface } from "@/Database/User/User.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { formatTime } from "@/utils";

//import { lovefill, love } from "@/assets/images/image";
import styles from "./styles.module.css";

//import { useAuth } from "@/context/AuthContext";
//import { love, lovefill } from "@/assets/images/image";
//import { LikeComment, UnlikeComment } from "@/Database/Posts";

type CommentProps = {
  data: CommentDocInterface;
};

const Comment = ({ data }: CommentProps) => {
  //const { currentUserData } = useAuth();
  const [userData, setUserData] = useState<UserDocInterface>();
  //const [like, setLike] = useState(data.likes?.includes(currentUserData?.uuid ?? ''));
  //const [likeCount, setLikeCount] = useState(data.likes?.length ?? 0)
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    GetUserFromDb(data.userId).then(d => {
      if (d) setUserData(d);
      setIsLoading(false);
    });
  }, []);

  /*const handleLike = async () => {
    if (!data.id || !currentUserData)
      return
    if (like) {
        setLike(false)
        setLikeCount(prev => prev - 1)
        await UnlikeComment(data.id, currentUserData?.uuid)
      }
      else {
        setLike(true)
        setLikeCount(prev => prev + 1)
        await LikeComment(data.id, currentUserData?.uuid)
      }
  }*/

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={styles.commentContainer}>
      <div className={styles.aboutPost}>
        <div className={styles.userImage}>
          <HexagonImage src={userData?.avatarPhoto} background={""} />
        </div>

        <div>
          <div className={`${styles.commentAndUserName} `}>
            <p className={styles.text}>{userData?.username} </p>
            <p className={`${styles.text} ${styles.comment}`}>{data.text} &nbsp;</p>
          </div>
          <div className={styles.timeAndReply}>
            <p className={styles.time}>{formatTime(new Timestamp(data.createdAt / 1000, 0))}</p>
          </div>
        </div>
      </div>
      {/*
      <div className={styles.like}>
        <img
          src={like ? lovefill : love}
          alt="#"
          className={styles.icon}
          onClick={handleLike}
        />
         <span className={styles.number}>{likeCount}</span> 
      </div>
      */}
    </div>
  );
};

export default Comment;
