export type CSL = typeof import("@emurgo/cardano-serialization-lib-browser");

class Cardano {
  _wasm?: CSL;
  async load() {
    if (this._wasm) return;
    try {
      this._wasm = await import("@emurgo/cardano-serialization-lib-browser");
    } catch (error) {
      throw error;
    }
  }

  get Instance(): CSL {
    return this._wasm!;
  }
}

export default new Cardano();
