import { useState } from "react";

import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { ContractType, NFTType } from "@/types";

import styles from "./CancelListingModal.module.css";

type CancelListingModalProps = {
  nft: NFTType | undefined | null;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CancelListingModal = ({ nft, setModal }: CancelListingModalProps) => {
  const { cancelSell } = use0xProtocol();
  const { getNfts } = useNFT();
  const { currentUserData } = useAuth();
  const [cancellingOrder, setCancellingOrder] = useState(false);
  const network = currentUserData?.address.startsWith("0x") ? "EVM" : "SOLANA";

  if (!nft) return <></>;

  const handleCancelList = async () => {
    if (!nft.order) return;
    setCancellingOrder(true);
    try {
      await cancelSell(nft.order?.nonce, nft.contract_type as ContractType);
      getNfts(currentUserData!, network);
    } finally {
      setCancellingOrder(false);
      setModal(false);
    }
  };
  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>Would you like to delist NFT?</p>
        <div className={styles.btnDiv}>
          <button className={styles.invertBtn} onClick={handleCancelList}>
            <div className={styles.buttonLoader}>
              {cancellingOrder && <Loader size={20} padding={"0px"} width={"20px"} />}
              <span style={{ paddingLeft: cancellingOrder ? "10px" : "0px" }}>Yes</span>
            </div>
          </button>
          <button className={styles.button} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default CancelListingModal;
