import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { NFTType } from "@/types";
import { formatShortAddress } from "@/utils";

import styles from "./styles.module.css";

type DetailsProps = {
  nft: NFTType;
};

const Details = ({ nft }: DetailsProps) => {
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={{ border: !showDetails ? "none" : "" }}>
        <p className={styles.heading}>Details </p>
        {showDetails ? (
          <BsChevronUp className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        ) : (
          <BsChevronDown className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        )}
      </div>

      {showDetails && (
        <div className={styles.infoContainer}>
          <div className={`${styles.info} `}>
            <p className={`${styles.text} ${styles.title}`}>Contract Address</p>{" "}
            <p className={`${styles.text} ${styles.address}`}>{formatShortAddress(nft.token_address)}</p>{" "}
          </div>{" "}
          <div className={`${styles.info} `}>
            <p className={`${styles.text} ${styles.title}`}>Token ID</p>{" "}
            <p className={`${styles.text} `}>{nft.token_id}</p>{" "}
          </div>
          <div className={`${styles.info} `}>
            <p className={`${styles.text} ${styles.title}`}>Token standard</p>
            <p className={`${styles.text} `}>{nft.contract_type}</p>
          </div>
          <div className={`${styles.info} `}>
            <p className={`${styles.text} ${styles.title}`}>Blockchain</p>
            <p className={`${styles.text} `}>
              {nft.token_address.startsWith("0x") ? "Ethereum" : "Solana"}
            </p>{" "}
          </div>{" "}
          <div className={`${styles.info} `}>
            <p className={`${styles.text} ${styles.title}`}>Creator</p>{" "}
            <p className={`${styles.text} ${styles.address}`}>{formatShortAddress(nft.minter_address)}</p>{" "}
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default Details;
