import { useState } from "react";
// import { BiUser } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";

import {
  // edit,
  walletMoney,
} from "@/assets/images/image";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";
import SetValue from "@/components/pages/CreateNewPost/AddPost/SetValue/SetValue";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { ContractType, NFTType } from "@/types";

import CheckBox from "./Checkbox/CheckBox";

import styles from "./styles.module.css";

type EditInformationProps = {
  nft: NFTType;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditInformation = ({ setModal, nft }: EditInformationProps) => {
  const [notForSale, setNotForSale] = useState(true);
  const [listForSale, setListForSale] = useState(false);
  const [priceNotValid, setPriceNotValid] = useState(false);
  const [value, setValue] = useState("0.00");
  const [editingPost, setEditingPost] = useState(false);

  const { currentUserData } = useAuth();
  const { sellNFT, cancelSell } = use0xProtocol();
  const { getNfts } = useNFT();
  const network = currentUserData?.address.startsWith("0x") ? "EVM" : "SOLANA";

  const handleNotForSale = () => {
    setNotForSale(true);
    setListForSale(false);
  };
  const handleListForSale = () => {
    setNotForSale(false);
    setListForSale(true);
  };

  const handleSave = async () => {
    if (!currentUserData) return;
    const priceNotValid = value.length === 0 || isNaN(parseFloat(value)) || parseFloat(value) === 0;
    if (listForSale && priceNotValid) {
      setPriceNotValid(true);
      return;
    }
    setEditingPost(true);
    try {
      if (nft.order?.price) await cancelSell(nft.order?.nonce, nft.contract_type as ContractType);
      if (listForSale)
        await sellNFT(nft.token_address, nft.token_id, nft.contract_type as ContractType, value);
      getNfts(currentUserData, network);
    } finally {
      setEditingPost(false);
      setModal(false);
    }
  };
  return (
    <>
      <div className={styles.editInformationContainer}>
        <div className={styles.editInformation}>
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>Edit Price</h4>
            <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
          </div>
          <div className={`${styles.wrapper} grScrollbar`}>
            <img src={nft.image} alt="#" className={styles.image} />
            <div className={styles.details}>
              <div className={styles.userContainer}>
                <div className={styles.profile}>
                  <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
                </div>{" "}
                <p className={styles.text}>{currentUserData?.username}</p>
              </div>
              <div className={styles.edits}>
                {/* <img src={edit} alt="#" className={styles.edit} /> */}
                <p className={`${styles.text} ${styles.editText}`}>{nft.post?.text}</p>
              </div>
              {/* <div className={styles.tagPeople}>
                <BiUser className={styles.userIcon} />
                <p className={`${styles.text} `}>Tag people</p>
              </div> */}
              <div className={styles.listForSaleContainer}>
                <div className={styles.listForSale}>
                  <img src={walletMoney} alt="#" className={styles.edit} />
                  <p className={`${styles.text}`}>List for sale?</p>
                </div>
                <div className={styles.checkboxContainer}>
                  <CheckBox label="No" onChange={handleNotForSale} checked={notForSale} />
                  <CheckBox label="Yes" onChange={handleListForSale} checked={listForSale} />
                </div>
                {listForSale && (
                  <SetValue
                    value={value}
                    priceNotValid={priceNotValid}
                    setPriceNotValid={setPriceNotValid}
                    setValue={setValue}
                  />
                )}
              </div>
              <div className={styles.btnDiv}>
                <button className={styles.invertBtn} onClick={() => setModal(false)}>
                  Cancel
                </button>
                <button className={styles.button} onClick={handleSave}>
                  <div className={styles.buttonLoader}>
                    {editingPost && <Loader size={20} padding={"0px"} width={"20px"} />}
                    <span style={{ paddingLeft: editingPost ? "10px" : "0px" }}>Save</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default EditInformation;
