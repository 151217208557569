import { useAuth } from "@/context/AuthContext";
import { PostType } from "@/types";

import SinglePost from "./SinglePost/SinglePost";

import styles from "./styles.module.css";

const ForSale = () => {
  const { myPosts } = useAuth();

  const nftForSale = myPosts?.filter(e => e.nft?.order?.price);

  return (
    <>
      <div className={styles.posts}>
        {!nftForSale?.length
          ? "No Posts Yet"
          : nftForSale.map((el: PostType, i: number) => <SinglePost post={el} key={i} />)}
      </div>
    </>
  );
};

export default ForSale;
