import { NetworkType as MoralisNetworkType } from "@moralisweb3/client-firebase-auth-utils/lib/NetworkType";
import { httpsCallable } from "firebase/functions";

import { TangocryptoAuth } from "@/utils/FirebaseCardanoAuth";

export type CardanoNetwork = "mainnet" | "testnet" | "preprod";
export type CardanoNetworkType = "cardano";
export type NetworkType = MoralisNetworkType | "cardano";

export interface RequestCardanoMessageOptions {
  /**
   * @description Network type.
   * @example "cardano"
   */
  networkType: "cardano";

  /**
   * @description Cardano wallet address.
   * @example "addr1q8l3lm4s6cndxdeuphtgqru4fflhj9avz0ltuersjnqzktjwycuetvj6k83jfetpphjq3vetuscjszh3eqvypfl4m37stpw4gm"
   */
  address: string;

  /**
   * @description Cardano network type.
   * @example "mainnet"
   */
  network: CardanoNetwork;

  /**
   * @description Cardano wallet
   * @example "eternl"
   */
  wallet: string;
}

export interface SignInContext {
  /**
   * @description Network type.
   * @example "evm"
   * @example "solana"
   * @example "cardano"
   */
  networkType: NetworkType;

  /**
   * @description Message to sign by user's wallet.
   */
  message: string;

  /**
   * @description Unique user id.
   */
  uid: string;
}

interface RequestMessageRawResponse {
  id: string;
  message: string;
  profileId: string;
}

export async function requestMessage(
  auth: TangocryptoAuth,
  options: RequestCardanoMessageOptions
): Promise<SignInContext> {
  const functionName = auth.functionNamePrefix.concat("requestMessage");
  const response = await httpsCallable<unknown, RequestMessageRawResponse>(
    auth.functions,
    functionName
  )(options);
  return {
    networkType: options.networkType,
    message: response.data.message,
    uid: response.data.profileId,
  };
}
