import { useEffect, useState } from "react";
import { BsFillCaretLeftSquareFill, BsFillCaretRightSquareFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

import { GetPostById } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { useGeneralContext } from "@/components/Context";
import UploadingNft from "@/components/pages/CreateNewPost/UploadingNft/UploadingNft";
import WhoToFollow from "@/components/pages/Home/FindFriends/FindFriends";

import SinglePost from "./SinglePost/SinglePost";

import styles from "./styles.module.css";

const SinglePostDetail = () => {
  const [showWhotoFollow, setShowWhotoFollow] = useState(false);
  const [postData, setPostData] = useState<PostDocInterface[]>();
  const { cancelUploading } = useGeneralContext();

  const navigate = useNavigate();

  const params = useParams();
  const id = params.id ?? "";

  useEffect(() => {
    GetPostById(id).then(post => {
      if (post) {
        setPostData([post]);
      } else {
        navigate("/feed");
      }
    });
  }, [id, navigate]);

  return (
    <>
      <div className={`marginTop ${styles.postContainer}`}>
        <div className={`${styles.postsWrapper} `}>
          {!cancelUploading && <UploadingNft />}
          <div className={`${styles.posts} `}>
            {postData &&
              postData.map((el: PostDocInterface, i: number) => (
                <SinglePost postId={el.id!} i={el.id!} key={i} />
              ))}
          </div>

          {showWhotoFollow ? (
            <BsFillCaretLeftSquareFill
              className={styles.arrow}
              onClick={() => setShowWhotoFollow(prev => !prev)}
            />
          ) : (
            <BsFillCaretRightSquareFill
              className={styles.arrow}
              onClick={() => setShowWhotoFollow(prev => !prev)}
            />
          )}

          <WhoToFollow showWhotoFollow={showWhotoFollow} />
        </div>{" "}
      </div>
    </>
  );
};

export default SinglePostDetail;
