import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { UserDocInterface } from "@/Database/User/User.definition";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { useUser } from "@/hooks";

import styles from "./SingleUser.module.css";

type SingleUserProps = {
  userData: UserDocInterface;
};

const SingleUser = ({ userData }: SingleUserProps) => {
  const { setShowConnectWalletModal } = useGeneralContext();
  const [followStatus, setFollowStatus] = useState("");
  const { currentUserData } = useAuth();
  const { follow, unfollow } = useUser();

  useEffect(() => {
    setFollowStatus(currentUserData?.following?.includes(userData.uuid) ? "Unfollow" : "Follow");
  }, [currentUserData, userData]);

  const handleFollowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!userData) return;

    if (followStatus === "Follow") {
      follow(userData.uuid);
    }

    if (followStatus === "Unfollow") {
      unfollow(userData.uuid);
    }
  };

  return (
    <div className={styles.topUser}>
      <Link key={userData.username} to={"/userProfile/" + userData.username}>
        <div className={styles.userImage}>
          <HexagonImage src={userData.avatarPhoto} background={""} />
        </div>
      </Link>
      <p className={styles.userName}>{userData.username}</p>
      <button className={styles.addFriend} onClick={handleFollowClick}>
        {followStatus}
      </button>
    </div>
  );
};

export default SingleUser;
