import {
  //addDoc,
  //updateDoc,
  doc, //orderBy,
  //onSnapshot,
  //  collection,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  where, //serverTimestamp,
  //arrayUnion
} from "firebase/firestore";

import { GetGroupsFromDb } from "@/Database/Message";
import {
  /*db ,*/
  createCollection,
} from "@/firebase";

import { UpdateableUser, UserDocInterface } from "./User.definition";

const TABLE_NAME = "User";

export const UserCollection = createCollection<UserDocInterface>(TABLE_NAME);

export const AddUserToDb = async (user: UpdateableUser) => {
  const r = await getDocs(
    query(UserCollection, where("username", "==", user.username), where("uuid", "!=", user.uuid))
  );
  if (r.docs.length > 0 && r.docs[0].data()) {
    throw new Error("Username is already used.");
  }
  const document = doc(UserCollection, user.uuid);
  return await setDoc(document, user, { merge: true });
};

export const GetUserFromDb = async (userId: string): Promise<UserDocInterface | null> => {
  const userRef = doc(UserCollection, userId);
  const r = await getDoc(userRef);

  if (r.data()) {
    return r.data() as UserDocInterface;
  } else {
    return null;
  }
};

export const getAllUsers = async (): Promise<UserDocInterface[]> => {
  const r = await getDocs(UserCollection);
  if (r.docs.length === 0) {
    return [];
  }
  return r.docs.map(doc => doc.data() as UserDocInterface);
};

export const GetUserFromDbByUsername = async (username: string): Promise<UserDocInterface | null> => {
  const r = await getDocs(query(UserCollection, where("username", "==", username)));

  if (r.docs.length > 0 && r.docs[0].data()) {
    return r.docs[0].data() as UserDocInterface;
  } else {
    return null;
  }
};

export const GetUserFromDbByReferral = async (referral: string): Promise<UserDocInterface | null> => {
  const r = await getDocs(query(UserCollection, where("referral", "==", referral)));

  if (r.docs.length > 0 && r.docs[0].data()) {
    return r.docs[0].data() as UserDocInterface;
  } else {
    return null;
  }
};

export const GetUsersReferredByUser = async (userId: string): Promise<string[]> => {
  const r = await GetUserFromDb(userId);

  if (r) {
    return r.referredUsers ?? [];
  }

  return [];
};

export const addExperienceToUser = async (uuid: string, experience: number) => {
  const user = await GetUserFromDb(uuid);
  let newExperience = 0;
  if (!user) return;
  if (user.experience) {
    newExperience = user.experience + experience;
  } else {
    newExperience = experience;
  }
  const newUser = { ...user, experience: newExperience };

  const document = doc(UserCollection, uuid);
  return await setDoc(document, newUser, { merge: true });
};

export const getExperienceFromUser = async (uuid: string) => {
  const user = await GetUserFromDb(uuid);
  if (!user) return 0;
  return user.experience ?? 0;
};

export const addUserToReferredUsers = async (uuid: string, referralUser: string) => {
  const refUser = await GetUserFromDb(referralUser);
  if (!refUser) return;
  const referredUsers = refUser.referredUsers ?? [];
  if (referredUsers.includes(uuid)) return;
  const newReferredUsers = [...referredUsers, uuid];
  const newUser = { ...refUser, referredUsers: newReferredUsers };

  const document = doc(UserCollection, refUser.uuid);
  return await setDoc(document, newUser, { merge: true });
};

export const addReferralToUser = async (uuid: string, referral: string) => {
  const user = await GetUserFromDb(uuid);
  if (!user) return;
  if (user.referral) return;
  const newUser = { ...user, referral };

  const document = doc(UserCollection, uuid);
  return await setDoc(document, newUser, { merge: true });
};

// get leaderboard with pagination and sorting
export const getLeaderboard = async (limitPerPage: number, pageNumber: number) => {
  const queryOptions = [
    orderBy("experience", "desc"),
    limit(limitPerPage),
    startAfter(pageNumber || ""),
    where("experience", ">", 0),
  ];
  const usersQuery = query(UserCollection, ...queryOptions);

  const usersSnapshot = await getDocs(usersQuery);

  const users = usersSnapshot.docs.map(doc => doc.data() as UserDocInterface);

  return users;
};

export const addGroupToUser = async (uuid: string, groupId: string) => {
  const group = await GetGroupsFromDb([groupId]);
  // User must exists in Members of Group
  if (!group || !group[0].members.includes(uuid)) return;
  const user = await GetUserFromDb(uuid);
  const groups = user?.groups ?? [];
  if (groups.includes(groupId)) return;
  const newGroups = [...groups, groupId];
  const newUser = { ...user, groups: newGroups };

  const document = doc(UserCollection, uuid);
  return await setDoc(document, newUser, { merge: true });
};

export const removeGroupFromUser = async (uuid: string, groupId: string) => {
  const group = await GetGroupsFromDb([groupId]);
  // User must not exists in Members of Group
  if (!group || group[0].members.includes(uuid)) return;
  const user = await GetUserFromDb(uuid);
  if (!user?.groups || !user?.groups.includes(groupId)) return;
  const newGroups = user.groups.filter(e => e !== groupId);
  const newUser = { ...user, groups: newGroups };

  const document = doc(UserCollection, uuid);
  return await setDoc(document, newUser, { merge: true });
};
