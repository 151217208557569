import { Web3Provider } from "@ethersproject/providers";
import {
  NftSwapV4,
  UserFacingERC20AssetDataSerializedV4,
  UserFacingFeeStruct,
} from "@traderxyz/nft-swap-sdk";
import { BigNumberish, ethers } from "ethers";

import { useAuth } from "@/context/AuthContext";
import { ContractType } from "@/types";

// import WalletConnectProvider from '@walletconnect/web3-provider';

export const use0xProtocol = () => {
  const Auth = useAuth();

  const chainId = process.env.REACT_APP_NETWORK === "GOERLI" ? 5 : 1;
  const ETHAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

  const fixedProvider = process.env.REACT_APP_USE_FIXED_PROVIDER ?? "";
  let provider: Web3Provider | null | undefined;
  if (["metamask", "coinbase"].includes(fixedProvider.toLocaleLowerCase())) {
    if (!window.ethereum.providerMap) provider = new Web3Provider(window.ethereum);
    else if (["coinbase"].includes(fixedProvider.toLocaleLowerCase()))
      provider = new Web3Provider(window.ethereum.providerMap.get("CoinbaseWallet"));
    else provider = new Web3Provider(window.ethereum.providerMap.get("MetaMask"));
  } else {
    provider = Auth.provider;
  }

  if (!provider) throw new Error("Invalid Provider");
  const signer = provider.getSigner();
  if (!signer) throw new Error("Invalid Signer");

  const nftSwapSdk = new NftSwapV4(provider, signer, chainId);

  const sellNFT = async (tokenAddress: string, tokenId: string, type: ContractType, price: string) => {
    try {
      // const providerWC = new WalletConnectProvider({
      //   rpc: {
      //     1: process.env.REACT_APP_WALLETCONNECT_RPC_ETHEREUM ?? '',
      //     5: process.env.REACT_APP_WALLETCONNECT_RPC_GOERLI ?? ''
      //   },
      //   chainId
      // })
      // await providerWC.enable();

      // const provider =  new Web3Provider(providerWC)
      // const signer = provider.getSigner()

      // await signer.sendTransaction({from: signer._address, to: "0xd89E0A4Ce7EEC6194e42a91701395F378ff4F27e", value: 1})
      // return

      checkInvariants(tokenAddress, tokenId, chainId);
      const priceInWei = ethers.utils.parseEther(price);
      const account = await signer.getAddress();
      if (!account) throw new Error("Invalid Signer/Account");

      const feeRecipient = process.env.REACT_APP_FEE_RECIPIENT ?? "";
      if (feeRecipient.length > 0 && !ethers.utils.isAddress(feeRecipient))
        throw new Error("Invalid Fee Recipient");
      const feePercentage = parseFloat(process.env.REACT_APP_FEE_PERCENTAGE ?? "0");
      if (feePercentage < 0) throw new Error("Invalid Fee Percentage");

      const NFTToSell: any = {
        tokenAddress,
        tokenId,
        type,
      };

      const sellAmount: UserFacingERC20AssetDataSerializedV4 = {
        tokenAddress: ETHAddress,
        amount: priceInWei.toString(),
        type: "ERC20",
      };

      const approvalStatus = await nftSwapSdk.loadApprovalStatus(NFTToSell, account);

      if (!approvalStatus.contractApproved) {
        const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
          NFTToSell,
          account,
          {},
          { approvalOnlyTokenIdIfErc721: true }
        );

        const approvalTxReceipt = await approvalTx.wait();
        console.log(
          `Approved ${NFTToSell.tokenAddress} contract to swap with 0x (txHash: ${approvalTxReceipt.transactionHash})`
        );
      }

      let fees: UserFacingFeeStruct[] = [];
      if (feeRecipient.length > 0 && feePercentage > 0) {
        const fee = priceInWei.mul(BigInt(feePercentage * 100)).div(BigInt(10000));
        fees.push({ recipient: feeRecipient, amount: fee.toString() });
      }

      const order = nftSwapSdk.buildOrder(NFTToSell, sellAmount, account, { fees });
      const signedOrder = await nftSwapSdk.signOrder(order);
      await nftSwapSdk.postOrder(signedOrder, chainId);
    } catch (error: any) {
      console.log("Error on: SellNFT", error);
      throw error;
    }
  };

  const buyNFT = async (nonce: string) => {
    try {
      const account = await signer.getAddress();
      if (!signer || !account) throw new Error("Invalid Signer/Account");

      const orders = await nftSwapSdk.getOrders({ nonce });
      if (!orders || orders?.orders.length === 0) throw new Error("Order Not Found");

      const signedOrder = orders.orders[0].order;

      const fillTx = await nftSwapSdk.fillSignedOrder(signedOrder);
      await nftSwapSdk.awaitTransactionHash(fillTx.hash);
    } catch (error: any) {
      console.log("Error on: BuyNFT");
      throw error;
    }
  };

  const cancelSell = async (nonce: BigNumberish, type: ContractType) => {
    try {
      const cancelTx = await nftSwapSdk.cancelOrder(nonce, type);
      await nftSwapSdk.awaitTransactionHash(cancelTx.hash);
    } catch (error: any) {
      console.log("Error on: CancelSell");
      throw error;
    }
  };

  const getOrders = async (address: string) => {
    try {
      if (!ethers.utils.isAddress(address)) throw new Error("Invalid Address");
      const url = process.env.REACT_APP_ORDERBOOK_URL;

      const response = await fetch(`${url}?maker=${address}`);
      const orders = await response.json();
      return orders;
    } catch (error: any) {
      console.log("Error on: getOrders");
      throw error;
    }
  };

  const checkInvariants = (tokenAddress: string, tokenId: string, chainId: number) => {
    if (!tokenAddress || !ethers.utils.isAddress(tokenAddress)) throw new Error("Invalid tokenAddress");
    if (isNaN(parseInt(tokenId))) {
      throw new Error("Invalid tokenId");
    }
    if (chainId !== 1 && chainId !== 5)
      throw new Error("Invalid ChainID (Must be 1-Ethereum Mainnet or 5-Ethereum Testnet (Goerli)");
  };

  return { sellNFT, buyNFT, cancelSell, getOrders };
};
