import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";

import styles from "./styles.module.css";

const ChooseWallet = () => {
  const { currentUserData } = useAuth();
  const [showWallets, setShowWallets] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(0);
  const wallets = [
    {
      icon: currentUserData?.avatarPhoto,
      name: currentUserData?.username,
      address: currentUserData?.address,
    },
  ];
  return (
    <div className={styles.chooseWallet}>
      <div className={styles.wallet}>
        <div className={styles.imageContainer}>
          <HexagonImage src={wallets[selectedWallet].icon} background={""} />
        </div>
        <div className={styles.nameAndAddress}>
          <p className={styles.name}>{wallets[selectedWallet].name}</p>
          <p className={styles.address}>{wallets[selectedWallet].address}</p>
        </div>
      </div>
      {showWallets ? (
        <BsChevronUp className={styles.arrow} onClick={() => setShowWallets(prev => !prev)} />
      ) : (
        <BsChevronDown className={styles.arrow} onClick={() => setShowWallets(prev => !prev)} />
      )}
      {showWallets && (
        <div className={styles.allWallet}>
          {" "}
          {wallets.map((wallet, i) => (
            <div
              className={styles.wallet}
              key={i}
              onClick={() => {
                setSelectedWallet(i);
                setShowWallets(false);
              }}
            >
              <div className={styles.imageContainer}>
                <HexagonImage src={wallet.icon} background={""} />
              </div>

              <p className={styles.name}>{wallet.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChooseWallet;
