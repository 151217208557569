import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";

import { ethIcon, imagePlaceholder } from "@/assets/images/image";
import Loader from "@/components/Loader/Loader";
import Modal from "@/components/Modal/Modal";
import PostBuy from "@/components/pages/Wallet/Posts/Nfts/BuyNow/BuyNow";
import { useAuth } from "@/context/AuthContext";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import { NFTType } from "@/types";
import { getBlockchain } from "@/utils";

import Description from "./DetailsDescriptionAndProperties/Description";
import Details from "./DetailsDescriptionAndProperties/Details";
import Properties from "./DetailsDescriptionAndProperties/Properties";

import styles from "./styles.module.css";

const NftDetails = () => {
  const navigate = useNavigate();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nft, setNft] = useState<NFTType | undefined | null>(null);

  const { currentUserData } = useAuth();
  const params = useParams();
  const owner = params.owner ?? "";
  const tokenAddress = params.tokenAddress ?? "";
  const tokenId = params.tokenId ?? "";
  const blockchain = getBlockchain(currentUserData?.address);

  useEffect(() => {
    const getNFT = async () => {
      setLoading(true);
      let currentNFT: NFTType[] = [];
      if (blockchain === "CARDANO") {
        currentNFT = (await getNftsBlockfrost(owner, "", tokenAddress, tokenId, false, false)).nfts;
      } else {
        currentNFT = (await getNftsMoralis(owner, "", tokenAddress, tokenId, false, false, blockchain)).nfts;
      }
      const newNFT = { ...currentNFT[0], owner_of: owner };
      setNft(newNFT);
    };

    getNFT().finally(() => setLoading(false));
  }, [owner, tokenAddress, tokenId, blockchain]);

  if (loading)
    return (
      <div className={`${styles.wrapper} marginTop`}>
        <div className={styles.loading}>
          <Loader />
        </div>
      </div>
    );

  if (!nft) return <></>;

  const handleBuy = () => {
    if (currentUserData?.address !== nft.owner_of) setShowBuyModal(prev => !prev);
  };

  return (
    <>
      <div className={`${styles.wrapper} marginTop`}>
        <div className={styles.postDetails}>
          <div>
            <div className={styles.header}>
              <HiOutlineArrowNarrowLeft className={styles.backArrow} onClick={() => navigate(-1)} />
              <h3 className={styles.title}>{`${nft.metadata?.name ?? nft?.name ?? "No Name"} `}</h3>
              {nft.order?.price && (
                <p className={styles.price} onClick={handleBuy}>
                  {" "}
                  <span>{formatEther(nft.order?.price ?? "0")}</span>
                  <img src={ethIcon} alt="#" className={styles.ethIcon} />
                </p>
              )}
            </div>{" "}
            <p className={styles.name}>{nft?.name}</p>
            <div className={styles.imageContainer}>
              <img
                src={nft?.image}
                alt="#"
                className={styles.image}
                onError={e => {
                  e.currentTarget.src = imagePlaceholder;
                }}
              />
            </div>
          </div>
          <div className={styles.detailsContainer}>
            {/* <div className={styles.priceContainer}>
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Est. Value</p>
                <div className={styles.valueContainer}>
                  <span className={styles.value}>0.0389</span>
                  <img src={ethIcon} alt="" className={styles.ethIcon} />
                </div>
              </div>
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Last</p>

                <span className={styles.value}>0.0389 ETH</span>
              </div>{" "}
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Supply</p>

                <span className={styles.value}>1</span>
              </div>{" "}
            </div>{" "} */}
            <Details nft={nft} />
            <Description nft={nft} />
            <Properties nft={nft} />
          </div>
        </div>
      </div>
      {showBuyModal && nft && (
        <Modal setModal={setShowBuyModal} background={""}>
          <PostBuy setModal={setShowBuyModal} nft={nft} />
        </Modal>
      )}
    </>
  );
};

export default NftDetails;
