import { useNavigate } from "react-router-dom";

import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";

import styles from "./MutualFriendModal.module.css";

type MutualFriendModalProps = {
  mutualFriends: string[] | undefined | null;
  setMutualFriendModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

const MutualFriendModal = ({ mutualFriends, setMutualFriendModal, title }: MutualFriendModalProps) => {
  const { usersData } = useAuth();
  const navigate = useNavigate();

  const handleClick = (username: string | undefined | null) => {
    if (username) navigate(`/userProfile/${username}`);
    setMutualFriendModal(false);
  };
  return (
    <>
      <div onClick={() => setMutualFriendModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>{title}</h3>
          <svg
            onClick={() => setMutualFriendModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>

        <div className={`${styles.suggestionDiv} grScrollbar`}>
          {mutualFriends &&
            mutualFriends.map((elem, idx) => {
              return (
                <div key={idx + new Date().getTime() + "suggest"} className={styles.suggestedPerson}>
                  <div
                    className={styles.leftSuggest}
                    onClick={() => handleClick(usersData?.get(elem)?.username)}
                  >
                    <div className={styles.userImage}>
                      <HexagonImage src={usersData?.get(elem)?.avatarPhoto} background={""} />
                    </div>

                    <p>{usersData?.get(elem)?.username}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MutualFriendModal;
