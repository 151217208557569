import styles from "./styles.module.css";

type DiscardPostProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAddingPost: React.Dispatch<React.SetStateAction<boolean>>;
};

const DiscardPost = ({ setModal, setAddingPost }: DiscardPostProps) => {
  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>
          Are you sure you want to discard this <br /> post?
        </p>
        <div className={styles.btnDiv}>
          <button
            className={`primaryButton ${styles.invertBtn}`}
            onClick={() => {
              setModal(false);
              setAddingPost(false);
            }}
          >
            Yes
          </button>
          <button className={`primaryButton ${styles.button}`} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};
export default DiscardPost;
