import {
  //  where,
  deleteDoc, //serverTimestamp,
  //arrayUnion
  //  getDocs,
  //addDoc,
  //updateDoc,
  //  documentId,
  doc, //  query,
  //orderBy,
  //onSnapshot,
  //  collection,
  getDoc,
  setDoc,
} from "firebase/firestore";

import { AddUserToDb, GetUserFromDb } from "@/Database/User";
import { UpdateableUser } from "@/Database/User/User.definition";
import {
  /*db ,*/
  createCollection,
} from "@/firebase";

import { NotificationDocInterface } from "./Notification.definition";

const TABLE_NAME = "Notification";

export const NotificationCollection = createCollection<NotificationDocInterface>(TABLE_NAME);

export const AddNotificationToDb = async (notification: NotificationDocInterface) => {
  const document = doc(NotificationCollection);
  await setDoc(document, { ...notification, uuid: document.id }, { merge: true });

  const user = await GetUserFromDb(notification.userId);
  if (!user) return;
  const notifications = user?.notifications ?? [];
  const newNotifications = [...notifications, document.id];
  const newUser: UpdateableUser = { ...user, notifications: newNotifications };
  await AddUserToDb(newUser);

  return document.id;
};

export const DeleteNotificationToDb = async (notificationId: string) => {
  const document = doc(NotificationCollection, notificationId);
  const notification = (await getDoc(document)).data();
  await deleteDoc(document);

  if (!notification) return;
  const user = await GetUserFromDb(notification.userId);
  if (!user) return;
  const notifications = user?.notifications ?? [];
  const newNotifications = notifications.filter(e => e !== notificationId);
  const newUser: UpdateableUser = { ...user, notifications: newNotifications };
  await AddUserToDb(newUser);
};

export const GetNotificationFromDb = async (
  notificationId: string
): Promise<NotificationDocInterface | null> => {
  const userRef = doc(NotificationCollection, notificationId);
  const r = await getDoc(userRef);

  if (r.data()) {
    return r.data() as NotificationDocInterface;
  } else {
    return null;
  }
};

export const MarkNotificationReadToDb = async (notificationId: string) => {
  const document = doc(NotificationCollection, notificationId);
  return await setDoc(document, { read: true }, { merge: true });
};
