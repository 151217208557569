// const HEXAGON_MASK = `
//   M77.944 0H41.2a24.03 24.03 0 0 0-20.453 11.417L3.822 38.86a24.03
//   24.03 0 0 0 .066 25.33L20.73 91.19A24.03 24.03 0 0 0 41.117 102.5h36.909a24.03
//   24.03 0 0 0 20.387-11.311l16.842-26.998a24.028 24.028 0 0 0 .065-25.33L98.397
//   11.417A24.03 24.03 0 0 0 77.944 0Z
import { Timestamp } from "firebase/firestore";

import Cardano, { CSL } from "./cardano-auth/csl";

// `
export const resolveIpfsLink = (url: any) => {
  if (!url || !url.includes("ipfs://")) return url;
  return url.replace("ipfs://", "https://gateway.ipfs.io/ipfs/");
};

export const resolveLink = (url: any) => {
  if (!url || url.startsWith("https://")) return url;
  return `https://${url}`;
};

export const formatShortAddress = (address: string | undefined | null): string => {
  if (!address) return "";
  if (address.startsWith("addr")) {
    // Cardano address
    return `${address.startsWith("addr_test") ? address.slice(0, 9) : address.slice(0, 4)}...${address.slice(
      -4
    )}`;
  }
  if (address.startsWith("stake")) {
    // Cardano stake address
    return `${
      address.startsWith("stake_test") ? address.slice(0, 10) : address.slice(0, 5)
    }...${address.slice(-4)}`;
  }
  if (address.length === 42 && address.startsWith("0x"))
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  if (address.length === 44) return `${address.slice(0, 4)}...${address.slice(-4)}`;
  if (address.length === 56) {
    // Cardano policy id
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  }
  return "No specified";
};

export const numberFormating = (number: number) => {
  if (number >= 1000 && number % 1000 === 0) return (number / 1000).toFixed(0) + "k";
  if (number >= 1000 && number % 1000 !== 0) return (number / 1000).toFixed(2) + "k";
  return number.toFixed(0);
};

export const getRandomId = () => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const second = date.getSeconds().toString().padStart(2, "0");
  const millisecond = date.getMilliseconds().toString().padStart(4, "0");
  const random = Math.floor(Math.random() * 10000000000)
    .toString()
    .padStart(10, "0");
  return random + "_" + year + month + day + hour + minute + second + millisecond;
};

export const formatTime = (date: Timestamp) => {
  const now = new Date();
  const notificationDate = new Date(date.seconds * 1000);
  const secondsDifference = (now.getTime() - notificationDate.getTime()) / 1000;
  if (secondsDifference < 60)
    return Math.floor((now.getTime() - notificationDate.getTime()) / 1000).toString() + "s";
  if (secondsDifference < 3600)
    return Math.floor((now.getTime() - notificationDate.getTime()) / (1000 * 60)).toString() + "m";
  if (secondsDifference < 86400)
    return Math.floor((now.getTime() - notificationDate.getTime()) / (1000 * 60 * 60)).toString() + "h";
  return notificationDate.toLocaleDateString();
};

export const formatDate = (currentDate: Date) => {
  //@ts-ignore
  return Math.round((Date.now() - currentDate) / 1000 / 60 / 60) + "h";
};

export const DisplayAddr = (currentAddr: string) => {
  if (!currentAddr || currentAddr === undefined) return "";

  if (currentAddr.length <= 11) return currentAddr;

  return (
    currentAddr.substring(0, 4) + "..." + currentAddr.substring(currentAddr.length - 4, currentAddr.length)
  );
};

// export const drawImage = (canvasRef: React.RefObject<HTMLCanvasElement>, imageUrl: string) => {
//   const mask = new Path2D(HEXAGON_MASK)

//   if (canvasRef.current) {
//     const ctx = canvasRef.current.getContext('2d')

//     if (ctx && imageUrl) {
//       ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
//       const image = new Image()
//       image.src = imageUrl
//       image.crossOrigin = 'anonymous'

//       image.onload = () => {
//         ctx.clip(mask)
//         const scale_factor = Math.min(
//           canvasRef.current.width / image.width,
//           canvasRef.current.height / image.height,
//         )
//         const newWidth = image.width * scale_factor
//         const newHeight = image.height * scale_factor
//         const x = canvasRef.current.width / 2 - newWidth / 2
//         const y = canvasRef.current.height / 2 - newHeight / 2

//         ctx.drawImage(image, x, y, newWidth, newHeight)
//       }
//     }
//   }
// }

export const strToHex = (str: string) => {
  return bufferToStr(new TextEncoder().encode(str));
};

export const bufferToStr = (buffer: Uint8Array) => {
  return Array.from(buffer)
    .map(byte => ("0" + (byte & 0xff).toString(16)).slice(-2))
    .join("");
};

export const strToBuffer = (hexString: string) => {
  // ensure even number of characters
  if (hexString.length % 2 != 0) {
    console.log("WARNING: expecting an even number of characters in the hexString");
  }

  // check for some non-hex characters
  var bad = hexString.match(/[G-Z\s]/i);
  if (bad) {
    throw new Error("ERROR: found non-hex characters");
  }

  // split the string into pairs of octets
  var pairs = hexString.match(/[\dA-F]{2}/gi);
  if (!pairs) {
    throw new Error("ERROR: missing pairs of octets characters");
  }

  // convert the octets to integers
  var integers = pairs.map(function (s) {
    return parseInt(s, 16);
  });

  return new Uint8Array(integers);
};

const UNPRINTABLE_CHARACTERS_REGEXP = /[\p{Cc}\p{Cn}\p{Cs}]+/u;
const ONLY_HEX_CHAREACTERS_REGEXP = /^([0-9A-Fa-f])+$/;

const CIP68_REFERENCE_PREFIX = "000643b0";
const CIP68_NFT_PREFIX = "000de140";
const CIP68_FT_PREFIX = "0014de40";
const CIP68_STANDARD: { [key: string]: number } = {
  [CIP68_REFERENCE_PREFIX]: 100, // Reference Token
  [CIP68_NFT_PREFIX]: 222, // NFT Token
  [CIP68_FT_PREFIX]: 333, // FT token
};

export const isPrintableUtf8 = (text: string) => {
  try {
    const buffer = strToBuffer(text);
    const t = new TextDecoder().decode(buffer);
    return { utf8: !UNPRINTABLE_CHARACTERS_REGEXP.test(t), text: t };
  } catch (error) {
    return { utf8: false, text };
  }
};

export const convertString = (str: string | null | undefined) => {
  if (!str || !ONLY_HEX_CHAREACTERS_REGEXP.test(str)) {
    return str;
  }
  const { utf8, text } = isPrintableUtf8(str);
  return utf8 ? text : str;
};

export const convertAssetName = (asset_name: string | null | undefined) => {
  if (!asset_name || !ONLY_HEX_CHAREACTERS_REGEXP.test(asset_name)) {
    return asset_name;
  }
  const result: any = {};
  if (!asset_name) return result;
  const asset_name_label = CIP68_STANDARD[asset_name.substring(0, 8)];
  const real_asset_name = asset_name_label ? asset_name.substring(8) : asset_name;
  const { utf8, text } = isPrintableUtf8(real_asset_name);
  return utf8 ? text : real_asset_name;
};

export const getBlockchain = (address: string | null | undefined) => {
  return address?.startsWith("0x")
    ? "EVM"
    : address?.startsWith("addr") || isPolicyId(address)
    ? "CARDANO"
    : "SOLANA";
};

export const getTokenSymbol = (blockchain: string) => {
  return blockchain === "EVM" ? "ETH" : blockchain === "SOLANA" ? "SOL" : "ADA";
};

export const lovelaceToAda = (lovelace: number) => {
  return lovelace / 1_000_000;
};

export const isPolicyId = (str: string | null | undefined) => {
  return str?.length === 56;
};

export const getStakeAddress = async (address: string) => {
  await Cardano.load();
  const csl: CSL = Cardano.Instance;
  try {
    const addr = csl.Address.from_bech32(address);
    const baseAddr = csl.BaseAddress.from_address(addr);
    let stakeAddr = "";
    if (baseAddr) {
      const stakeCredential = baseAddr.stake_cred();
      const reward = csl!.RewardAddress.new(addr.network_id(), stakeCredential);
      stakeAddr = reward.to_address().to_bech32();
    }
    return stakeAddr;
  } catch (err) {
    return "";
  }
};
