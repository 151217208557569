import {
  /*useEffect,*/
  useState,
} from "react";

import { useGeneralContext } from "@/components/Context";
import Modal from "@/components/Modal/Modal";
import { useNFT } from "@/hooks";

import AddPost from "./AddPost/AddPost";
import AllNft from "./AllNft/AllNft";
import ChooseWallet from "./ChooseWallet/ChooseWallet";
import DiscardPost from "./DiscardPost/DiscardPost";
import ProgressBar from "./ProgressBar/ProgressBar";

import styles from "./styles.module.css";

const CreateNewPost = () => {
  const [selectedNft, setSelectedNft] = useState(-1);
  const [addingPost, setAddingPost] = useState(false);
  const [disCardPost, setDiscardPost] = useState(false);
  const { setUploadingProgress } = useGeneralContext();
  const { nfts } = useNFT();

  //const nftsToPost = nfts.filter(e => !e.post)
  const nftsToPost = nfts;

  return (
    <>
      <div className="wrapper">
        <div className={`${styles.createNewPostContainer} marginTop `}>
          <ProgressBar />
          <h4 className={styles.title}>Create new post</h4>
          <p className={styles.text}>Choose your wallet:</p>
          <div className={styles.chooseWalletWrapper}>
            <div className={styles.chooseWallet}>
              <ChooseWallet />
              <button
                className={`primaryButton ${styles.button} ${selectedNft !== -1 && styles.buttonActive}`}
                onClick={() => {
                  if (selectedNft !== -1) {
                    setAddingPost(true);
                    setUploadingProgress(70);
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>

          <AllNft data={nftsToPost} selectedNft={selectedNft} setSelectedNft={setSelectedNft} />
        </div>
      </div>
      {addingPost && selectedNft !== -1 && (
        <Modal setModal={setAddingPost} background={""}>
          <AddPost
            setModal={setAddingPost}
            setDiscardPost={setDiscardPost}
            nft={nftsToPost[selectedNft - 1]}
          />
        </Modal>
      )}
      {disCardPost && <DiscardPost setModal={setDiscardPost} setAddingPost={setAddingPost} />}
    </>
  );
};

export default CreateNewPost;
