import { UserDocInterface } from "@/Database/User/User.definition";
import { GET_MORE_NFTs, GET_NFTs, SELECT_NFT_TO_DETAIL } from "@/redux/types/NFT";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import {
  /*MoralisNFTType, */
  NFTType,
} from "@/types";

export const getNFTs = (
  currentUserData: UserDocInterface | undefined | null,
  blockchain: string,
  page?: number,
  cursor?: string,
  size?: number
) => ({
  type: !page || page === 1 ? GET_NFTs : GET_MORE_NFTs,
  payload: async () => {
    try {
      if (!currentUserData) return [] as NFTType[];
      const address = currentUserData.address;
      if (!address) return [] as NFTType[];

      if (blockchain === "CARDANO") {
        return await getNftsBlockfrost(
          address,
          currentUserData.uuid,
          undefined,
          undefined,
          false,
          false,
          page,
          size
        );
      } else {
        return await getNftsMoralis(
          address,
          currentUserData.uuid,
          undefined,
          undefined,
          false,
          false,
          blockchain,
          cursor,
          size
        );
      }
    } catch (error) {
      console.log("getNFTs-ERROR", error);
    }
  },
});

export const setNFTDetail = ({ nftDetail }: any) => ({
  type: SELECT_NFT_TO_DETAIL,
  payload: () => {
    return nftDetail;
  },
});
