import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { NFTType } from "@/types";

import styles from "./styles.module.css";

type PropertiesProps = {
  nft: NFTType;
};

type formattedAttributesType = {
  title: string;
  value: string;
};

const Properties = ({ nft }: PropertiesProps) => {
  const [showDetails, setShowDetails] = useState(true);
  const NFTAttributes = nft.metadata?.attributes;
  const attributes = Array.isArray(NFTAttributes) ? NFTAttributes : NFTAttributes ? [NFTAttributes] : [];
  const formattedAttributes: formattedAttributesType[] = attributes.map(e => {
    const keys = Object.keys(e);
    const keyA = keys[0] || null;
    const title = keyA != null ? e[keyA] : "";

    const keyB = keys[1] || null;
    const value = keyB != null ? e[keyB] : "";
    return { title, value };
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.heading}>Properties </p>
        {showDetails ? (
          <BsChevronUp className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        ) : (
          <BsChevronDown className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        )}
      </div>

      {attributes && showDetails && (
        <div className={styles.propertyContainer}>
          {formattedAttributes.map((el: formattedAttributesType, i: number) => (
            <div className={styles.propertyBox} key={i}>
              <p className={styles.address}>{el.title}</p> <p className={styles.text}>{el.value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Properties;
