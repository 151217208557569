import { useState } from "react";

import { UpdatePost } from "@/Database/Posts";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { ContractType, NFTType } from "@/types";

import styles from "./styles.module.css";

type DeletePostProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  nft: NFTType;
  setCommentAndDetailsModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeletePost = ({ setModal, nft, setCommentAndDetailsModal }: DeletePostProps) => {
  const { currentUserData } = useAuth();
  const { getNfts } = useNFT();
  const [deletingOrder, setDeletingOrder] = useState(false);
  const { cancelSell } = use0xProtocol();
  const network = currentUserData?.address.startsWith("0x") ? "EVM" : "SOLANA";

  const handleDelete = async () => {
    if (!nft.post?.id) return;
    setDeletingOrder(true);
    try {
      if (nft.order?.nonce) await cancelSell(nft.order?.nonce, nft.contract_type as ContractType);
      await UpdatePost({ id: nft.post?.id, deleted: true });
      getNfts(currentUserData!, network);
    } finally {
      if (setCommentAndDetailsModal) setCommentAndDetailsModal(false);
      setDeletingOrder(false);
      setModal(false);
    }
  };

  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>
          Are your sure you want to delete this <br /> post?
        </p>
        <div className={styles.btnDiv}>
          <button className={styles.invertBtn} onClick={handleDelete}>
            <div className={styles.buttonLoader}>
              {deletingOrder && <Loader size={20} padding={"0px"} width={"20px"} />}
              <span style={{ paddingLeft: deletingOrder ? "10px" : "0px" }}>Yes</span>
            </div>
          </button>
          <button className={styles.button} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};
export default DeletePost;
