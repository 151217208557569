import { CombinedState, combineReducers } from "redux";

import { NFTAction, NFTState } from "@/redux/types/NFT";
import { UserAction, UserState } from "@/redux/types/user";
import { REQUEST_USER_SIGNOUT, UserSetupAction, UserSetupState } from "@/redux/types/userSetup";

import { NFTReducer } from "./NFT";
import { UserReducer } from "./user";
import { UserSetupReducer } from "./userSetup";

const appReducer = combineReducers({
  User: UserReducer,
  NFT: NFTReducer,
  UserSetup: UserSetupReducer,
});

const rootReducer = (
  state: CombinedState<{ User: UserState; NFT: NFTState; UserSetup: UserSetupState }> | undefined,
  action: UserAction | NFTAction | UserSetupAction
) => {
  if (action.type === REQUEST_USER_SIGNOUT) {
    // // for all keys defined in your persistConfig(s)
    // // storage.removeItem('persist:otherKey')
    // AsyncStorage.clear()

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export { rootReducer };
