import { useEffect, useRef, useState } from "react";
import { BsFillPlayFill, BsPause } from "react-icons/bs";
// import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";

import styles from "./Waveform.module.css";

type WaveformProps = {
  audio: any;
};

const Waveform = ({ audio }: WaveformProps) => {
  const containerRef = useRef<HTMLInputElement>(null);
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [waveform, setWaveform] = useState<any>(null);

  useEffect(() => {
    if (!audio) return;
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      barWidth: 2,
      barHeight: 4,
      cursorWidth: 0,
      progressColor: "#41C6DA",
      waveColor: "#FAFAFAB8",
      height: 50,
    });
    waveSurfer.loadBlob(audio);
    waveSurfer.on("ready", () => {
      setWaveform(waveSurfer);
    });
    waveSurfer.on("pause", () => {
      toggleIsPlaying(false);
    });
    waveSurfer.on("finish", () => {
      toggleIsPlaying(true);
    });
    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  return (
    <div className={styles.waveform}>
      <button
        onClick={() => {
          if (waveform.isPlaying()) waveform.pause();
          else waveform.play();
          toggleIsPlaying(waveform.isPlaying());
        }}
        type="button"
      >
        {isPlaying ? <BsPause /> : <BsFillPlayFill />}
      </button>
      <div className={styles.mainWave} ref={containerRef}></div>
    </div>
  );
};

export default Waveform;
