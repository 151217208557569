import { Timestamp, documentId, onSnapshot, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { BiSearch } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";
import { useParams } from "react-router-dom";

import { AddGroupToDb, GroupCollection, updateTyping } from "@/Database/Message";
import { GroupDocInterface, UpdateableGroup } from "@/Database/Message/Group.definition";
import { imageInput, micImage } from "@/assets/images/image";
import { useAuth } from "@/context/AuthContext";
import { MessageType } from "@/types";
import { getRandomId } from "@/utils";

import ChatRoom from "./ChatRoom/ChatRoom";
import ChatRoomSidebar from "./ChatRoomSidebar/ChatRoomSidebar";
import NewMsgModal from "./NewMsgModal/NewMsgModal";

import styles from "./Message.module.css";

const Message = () => {
  const { currentUserData, usersData } = useAuth();
  const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder();
  const { id } = useParams();
  const [openNewMsgModal, setOpenNewMsgModal] = useState(false);
  const [roomToggle, setRoomToggle] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [inputMsg, setInputMsg] = useState("");
  const [groups, setGroups] = useState<Map<string, GroupDocInterface> | undefined | null>(null);
  const [loading, setLoading] = useState(true);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [searchMessage, setSearchMessage] = useState("");

  const recordingRef = useRef<Blob | null>(null);

  useEffect(() => {
    const getGroups = async () => {
      if (!currentUserData?.groups) return;
      setLoading(true);

      const q = query(
        GroupCollection,
        where(documentId(), "in", currentUserData?.groups),
        where("deleted", "==", false)
      );
      const unsuscribe = onSnapshot(q, querySnapshot => {
        let newGroups: Map<string, GroupDocInterface> = new Map<string, GroupDocInterface>();
        querySnapshot.forEach(doc => {
          newGroups.set(doc.id, doc.data());
        });
        setGroups(newGroups);
      });
      setLoading(false);
      return () => unsuscribe;
    };
    getGroups();
  }, [currentUserData, id]);

  // scrolling to the bottom of the messages everytime a new message is typed
  // useLayoutEffect(() => {
  //   const chatRoom = document.querySelector(".chatMsgs");
  //   if (chatRoom) {
  //     chatRoom.scrollTop = chatRoom.scrollHeight;
  //   }
  // }, [groups, id]);

  useEffect(() => {
    if (recordingBlob) recordingRef.current = recordingBlob;
  }, [recordingBlob]);

  const addNewVoiceNote = () => {
    stopRecording();
    setTimeout(async () => {
      const recordingBlob = recordingRef.current;
      if (!groups || !currentUserData || !id || !recordingBlob) return;
      const group = groups?.get(id);
      if (!group) return;

      const storage = getStorage();
      const fileFullPath = `${group.uuid}/audio/${getRandomId()}`;
      const storageRef = ref(storage, fileFullPath);
      await uploadBytes(storageRef, recordingBlob, { cacheControl: "public,max-age=3600" });

      const newMessages = [...group.messages];
      const newMessage: MessageType = {
        message: fileFullPath,
        sentBy: currentUserData.uuid,
        sentAt: Timestamp.fromDate(new Date()),
        type: "audio",
      };
      newMessages.push(newMessage);
      const newGroup: UpdateableGroup = {
        uuid: group.uuid,
        typing: group.typing.filter(e => e !== currentUserData.uuid),
        messages: newMessages,
        modifiedAt: Timestamp.fromDate(new Date()),
      };
      AddGroupToDb(newGroup);
    }, 100);
  };

  const addImageToChat = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!groups || !currentUserData || !id) return;
    const group = groups?.get(id);
    if (!group) return;

    const files = e.target.files;
    if (!files) return;

    const file = files[0];
    if (!file.type.includes("image")) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const storage = getStorage();
      const fileFullPath = `${group.uuid}/image/${getRandomId()}`;
      const storageRef = ref(storage, fileFullPath);
      await uploadBytes(storageRef, arrayBuffer, {
        contentType: file.type,
        cacheControl: "public,max-age=3600",
      });

      const newMessages = [...group.messages];
      const newMessage: MessageType = {
        message: fileFullPath,
        sentBy: currentUserData.uuid,
        sentAt: Timestamp.fromDate(new Date()),
        type: "image",
      };
      newMessages.push(newMessage);
      const newGroup: UpdateableGroup = {
        uuid: group.uuid,
        typing: group.typing.filter(e => e !== currentUserData.uuid),
        messages: newMessages,
        modifiedAt: Timestamp.fromDate(new Date()),
      };
      AddGroupToDb(newGroup);
    };
    reader.readAsArrayBuffer(file);
  };

  const addMsg = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputMsg || !id || !currentUserData) return;
    const group = groups?.get(id);
    if (!group) return;

    const newMessages = [...group.messages];
    const newMessage: MessageType = {
      message: inputMsg,
      sentBy: currentUserData.uuid,
      sentAt: Timestamp.fromDate(new Date()),
      type: "text",
    };
    newMessages.push(newMessage);
    const newGroup: UpdateableGroup = {
      uuid: group.uuid,
      typing: group.typing.filter(e => e !== currentUserData.uuid),
      messages: newMessages,
      modifiedAt: Timestamp.fromDate(new Date()),
    };
    setInputMsg("");
    AddGroupToDb(newGroup);
    if (typingTimeout !== null) {
      setTyping(false);
      clearTimeout(typingTimeout);
      setTypingTimeout(null);
    }
  };

  const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!currentUserData || !id) return;
    const group = groups?.get(id);
    if (!group) return;
    setInputMsg(e.target.value);
    setTyping(true);
    if (typingTimeout !== null) clearTimeout(typingTimeout);
    else updateTyping(group, currentUserData.uuid, true);

    const timeout = setTimeout(() => {
      updateTyping(group, currentUserData.uuid, false);
      setTypingTimeout(null);
      setTimeout(() => setTyping(false), 1000);
    }, 2000);
    setTypingTimeout(timeout);
  };

  const filterFunction = (group: GroupDocInterface) => {
    if (
      !searchMessage ||
      group.members.find(e => usersData?.get(e)?.username?.includes(searchMessage)) ||
      group.name.includes(searchMessage)
    )
      return true;
  };

  return (
    <>
      {openNewMsgModal && (
        <NewMsgModal setOpenNewMsgModal={setOpenNewMsgModal} groups={groups} setGroups={setGroups} />
      )}
      <div className={`${styles.messageWrap} wrapper`}>
        {roomToggle && <div className={styles.overlay} onClick={() => setRoomToggle(false)}></div>}
        <div className={`${styles.shadow1} shadow`}></div>
        <div className={`${styles.shadow2} shadow`}></div>
        <div className={styles.messageCont}>
          <div onClick={() => setRoomToggle(prev => !prev)} className={styles.mobToggle}>
            <RxHamburgerMenu />
          </div>
          <div className={`${styles.messageRooms} ${roomToggle ? styles.sideBarLeft : "-600px"}`}>
            <div className={styles.roomHeader}>
              <h2>Messages</h2>
              <button
                onClick={() => {
                  setOpenNewMsgModal(true);
                  setRoomToggle(false);
                }}
                className={styles.newChatBtn}
              >
                Start New Chat
              </button>
            </div>
            <div className={`${styles.paddingDiv}`}>
              <div className={styles.roomSearch}>
                <BiSearch />
                <input
                  type="text"
                  placeholder="Search direct message"
                  onChange={e => setSearchMessage(e.target.value)}
                />
              </div>
              <div className={`grScrollbar ${styles.scrollDiv}`}>
                {groups &&
                  Array.from(groups.values())
                    .filter(filterFunction)
                    .sort((a, b) => (a.modifiedAt <= b.modifiedAt ? 1 : -1))
                    .map(group => (
                      <ChatRoomSidebar key={group.uuid} setRoomToggle={setRoomToggle} group={group} />
                    ))}
              </div>
            </div>
          </div>
          {id && groups?.has(id) && (
            <div className={styles.roomChat}>
              <div style={{ height: showInput ? "calc(100% - 40px)" : "100%" }} className={styles.chatPart}>
                {!loading && id && groups?.has(id) && (
                  <ChatRoom
                    group={groups?.get(id)!}
                    groups={groups}
                    setGroups={setGroups}
                    setShowInput={setShowInput}
                    isRecording={isRecording || typing}
                  />
                )}
              </div>
              {showInput && (
                <form onSubmit={e => addMsg(e)} className={styles.inputDiv}>
                  <input
                    value={inputMsg}
                    onChange={e => handleTyping(e)}
                    type="text"
                    placeholder="Write message..."
                  />
                  <div className={styles.rightInput}>
                    <div className={styles.imgInput}>
                      <input
                        onChange={addImageToChat}
                        onClick={e => (e.currentTarget.value = "")}
                        style={{ display: "none" }}
                        type="file"
                        id="uploadImg"
                      />
                      <label htmlFor="uploadImg">
                        <img src={imageInput} alt="" />
                      </label>
                    </div>
                    {isRecording ? (
                      <p
                        style={{
                          color: "white",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          addNewVoiceNote();
                        }}
                      >
                        Stop
                      </p>
                    ) : (
                      <img
                        onClick={() => {
                          startRecording();
                        }}
                        src={micImage}
                        alt=""
                      />
                    )}
                  </div>
                  <button type="submit"></button>
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Message;
