import axios from "axios";

import { GetPostsByUser } from "@/Database/Posts";
import { imagePlaceholder } from "@/assets/images/image";
import { MetadataType, NFTType } from "@/types";
import { convertAssetName, convertString, getStakeAddress, resolveIpfsLink } from "@/utils";
import { CARDANO_SKIP_PROPERTIES } from "@/utils/cardano-auth";

const BASE_URL = process.env.REACT_APP_BLOCKFROST_URL;
const PROJECT_ID = process.env.REACT_APP_BLOCKFROST_PROJECT_ID;

export const getBalance = async (stake_address: string) => {
  try {
    const url = `${BASE_URL}/accounts/${stake_address}`;
    const { data, status } = await axios(url, {
      method: "get",
      headers: {
        accept: "application/json",
        project_id: PROJECT_ID,
      },
    });
    if (status !== 200) {
      return 0;
    }
    return Number(data.controlled_amount);
  } catch (err) {
    return 0;
  }
};

export const getNftsBlockfrost = async (
  address: string,
  userId: string,
  policyId?: string,
  tokenId?: string,
  skipOrders = false,
  skipPosts = false,
  page = 1,
  size = 100
) => {
  let parsedNfts: NFTType[] = [];
  try {
    const stake_address = await getStakeAddress(address);
    if (policyId && tokenId) {
      const unit = policyId + tokenId;
      const nft = await getNftDetails(unit, true);
      const metadata = nft?.metadata || null;
      const image = metadata?.image;
      const quantity = nft?.quantity;
      return {
        nfts: [
          buildNft(nft?.collection_name, tokenId, policyId, image, quantity, stake_address, metadata, []),
        ],
      };
    }
    parsedNfts = await getNftList(userId, stake_address, skipPosts, page, Math.min(size, 100));
    return { nfts: parsedNfts, cursor: parsedNfts.length === 0 ? "" : `${page}` };
  } catch (err) {
    console.log("NFTS error", err);
    return { nfts: parsedNfts };
  }
};

interface NFT {
  policy_id: string;
  collection_name: string;
  asset_name: string;
  fingerprint: string;
  owner: string;
  quantity: number;
  initial_mint_tx_hash: string;
  metadata: MetadataType;
}

function buildNft(
  collectionName: string | undefined,
  assetName: any,
  policyId: any,
  image: string | undefined,
  quantity: any,
  stake_address: string,
  metadata: MetadataType | null,
  posts: any[],
  order?: any
): NFTType {
  return {
    token_id: assetName,
    token_address: policyId,
    image: image ? resolveIpfsLink(image) : imagePlaceholder,
    amount: quantity,
    owner_of: stake_address,
    name: collectionName || "",
    symbol: "",
    token_hash: "",
    block_number_minted: "0",
    block_number: "0",
    contract_type: metadata?.standard || "",
    token_uri: "",
    last_token_uri_sync: "",
    last_metadata_sync: "",
    minter_address: stake_address,
    possible_spam: false,
    metadata,
    order: order,
    post: posts.find(e => policyId === e.tokenAddress && assetName === e.tokenId),
  };
}

async function getNftList(
  userId: string,
  stake_address: string,
  skipPosts: boolean,
  page: number,
  size: number
): Promise<NFTType[]> {
  let parsedNfts: NFTType[] = [];
  let url = `${BASE_URL}/accounts/${stake_address}/addresses/assets?page=${page}&count=${size}`;
  const { data, status } = await axios(url, {
    method: "get",
    headers: {
      accept: "application/json",
      project_id: PROJECT_ID,
    },
  });
  if (status !== 200) {
    return parsedNfts;
  }
  const nfts = await Promise.allSettled(
    data
      .filter((d: any) => Number(d.quantity) === 1)
      .map(({ unit, quantity }: { unit: string; quantity: string }) =>
        getNftDetails(unit).then(nft => {
          const [policyId, assetName] = [unit.slice(0, 56), unit.slice(56)];
          return { policyId, assetName, quantity, nft };
        })
      )
  );
  for (const result of nfts) {
    if (result.status === "rejected") {
      continue;
    }
    const { policyId, assetName, quantity, nft } = result.value;
    const metadata = nft?.metadata || null;
    const image = metadata?.image;
    // const order = address && !skipOrders ? await getOrdersByNFT(address, stake_address, unit) : undefined
    const posts = userId && !skipPosts ? await GetPostsByUser(userId) : [];
    parsedNfts.push(
      buildNft(nft?.collection_name, assetName, policyId, image, quantity, stake_address, metadata, posts)
    );
  }
  return parsedNfts;
}

async function getNftDetails(unit: string, includeOwner = false): Promise<NFT | null> {
  try {
    const url = `${BASE_URL}/assets/${unit}`;
    const { data, status } = await axios(url, {
      method: "get",
      headers: {
        accept: "application/json",
        project_id: PROJECT_ID,
      },
    });
    if (status !== 200) {
      return null;
    }
    const { metadata, onchain_metadata, ...other } = data;
    let collection = metadata?.collection || onchain_metadata?.collection;
    const attributes = getMetadataAttributes({ ...metadata, ...onchain_metadata });
    const index = attributes.findIndex((attr: any) => attr.key.toLowerCase() === "collection");
    if (index >= 0) {
      if (!collection) {
        collection = attributes[index].value;
      }
      attributes.splice(index, 1);
    }
    const finalMetadata: MetadataType = {
      name: convertAssetName(onchain_metadata?.name || metadata?.name || other.asset_name),
      description: onchain_metadata?.description || metadata?.description,
      standard: other.onchain_metadata_standard || "CIP25v1",
      image: onchain_metadata?.image,
      attributes,
    };

    let owner;
    if (includeOwner) {
      owner = await getNftOwner(unit);
    }
    return {
      ...other,
      collection_name: convertString(collection),
      owner,
      quantity: Number(other.quantity),
      metadata: finalMetadata,
    };
  } catch (err) {
    console.log("NFT Details error:", err);
    return null;
  }
}

function getMetadataAttributes(metadata: any) {
  return Object.entries<any>(metadata).flatMap(([key, value]) => {
    try {
      if (CARDANO_SKIP_PROPERTIES.includes(key.toLowerCase())) {
        return [];
      }
      const result = [];
      if (Array.isArray(value)) {
        for (const v of value) {
          const keys = Object.keys(v);
          const [key, innerValue] = keys.length > 1 ? [v.name, v.value] : [keys[0], v[keys[0]]];
          if (!CARDANO_SKIP_PROPERTIES.includes(key.toLowerCase())) {
            result.push({ key, value: convertString(innerValue) });
          }
        }
      } else if (typeof value === "object") {
        for (const [key, v] of Object.entries<any>(value)) {
          if (!CARDANO_SKIP_PROPERTIES.includes(key.toLowerCase())) {
            result.push({ key, value: convertString(v) });
          }
        }
      } else {
        result.push({ key, value: convertString(value) });
      }
      return result;
    } catch (error) {
      return [];
    }
  });
}

async function getNftOwner(unit: string): Promise<string | null> {
  try {
    const url = `${BASE_URL}/assets/${unit}/addresses`;
    const { data, status } = await axios(url, {
      method: "get",
      headers: {
        accept: "application/json",
        project_id: PROJECT_ID,
      },
    });
    if (status !== 200) {
      return null;
    }

    const [{ address }] = data;
    return address;
  } catch (err) {
    return null;
  }
}
