import { eternl, nami } from "@/assets/images/image";
import { Wallet } from "@/types";

export const CARDANO_SUPPORTED_WALLETS: Wallet[] = [
  { name: "Eternl", icon: eternl },
  { name: "Nami", icon: nami },
];

export const CARDANO_SKIP_PROPERTIES = [
  "name",
  "description",
  "image",
  "mediatype",
  "files",
  "twitter",
  "website",
  "youtube",
  "publisher",
  "copyright",
];
