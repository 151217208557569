import { Link } from "react-router-dom";

import styles from "./styles.module.css";

export const BuildYourFeed = () => {
  const ExploreElement = () => {
    return (
      <Link to="/explore">
        <p className={styles.button}>Explore</p>
      </Link>
    );
  };

  return (
    <>
      <div className={styles.heading}>Build out your feed</div>
      <div className={styles.text}>
        Start building your personal Feed by connecting with your friends and posting your NFTs on Continent
      </div>
      <ExploreElement />
    </>
  );
};
