import styles from "./styles.module.css";

type SetValueProps = {
  value: string;
  priceNotValid: boolean;
  setPriceNotValid: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const SetValue = ({ value, priceNotValid, setPriceNotValid, setValue }: SetValueProps) => {
  const allowedKeys = [
    "Home",
    "End",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
  ];
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === "." && value.indexOf(".") !== -1) || !allowedKeys.includes(e.key)) e.preventDefault();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceNotValid(false);
    setValue(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>Set a value:</p>
      <div className={styles.inputContainerAndUsdValue}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            pattern="[0-9]+(\.[0-9]+)?"
            className={styles.input}
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
          />
          <div className={styles.valueTypeContainer}>
            <p className={styles.valueText}>Eth</p>
          </div>
        </div>
        {priceNotValid && <p className={styles.priceNotValid}>Must Set a Sell Price</p>}
        {/* <p className={styles.usdValue}>${(2338.6 * value).toFixed(2)} USD</p> */}
      </div>
    </div>
  );
};

export default SetValue;
