import { useEffect, useState } from "react";
import { BsFillCaretLeftSquareFill, BsFillCaretRightSquareFill } from "react-icons/bs";

import { GetLatestPosts, GetPosts } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { useGeneralContext } from "@/components/Context";
import Loader from "@/components/Loader/Loader";
import UploadingNft from "@/components/pages/CreateNewPost/UploadingNft/UploadingNft";
import WhoToFollow from "@/components/pages/Home/FindFriends/FindFriends";
import { useAuth } from "@/context/AuthContext";

import { BuildYourFeed } from "./BuildFeed/BuildYourFeed";
import SinglePost from "./SinglePost/SinglePost";

import styles from "./styles.module.css";

const Posts = () => {
  const [showWhotoFollow, setShowWhotoFollow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState<PostDocInterface[]>();
  const { cancelUploading } = useGeneralContext();
  const { currentUserData } = useAuth();

  useEffect(() => {
    setLoading(true);
    if (currentUserData && (currentUserData.following?.length ?? 0) > 0) {
      GetPosts(currentUserData?.uuid!, undefined, 50)
        .then(postsData => setPostData(postsData))
        .catch(console.log)
        .finally(() => setLoading(false));
    } else {
      GetLatestPosts(50)
        .then(postsData => setPostData(postsData))
        .catch(console.log)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className={`marginTop ${styles.postContainer}`}>
        <Loader />
      </div>
    );

  return (
    <div className={`marginTop ${styles.postContainer}`}>
      <div className={`${styles.postsWrapper} `}>
        {!cancelUploading && <UploadingNft />}
        <div className={`${styles.posts} `}>
          {!loading && (!postData || postData.length <= 0) && <BuildYourFeed />}
          {postData &&
            postData.map((el: PostDocInterface, i: number) => (
              <SinglePost postId={el.id!} i={el.id!} key={i} />
            ))}
        </div>

        {showWhotoFollow ? (
          <BsFillCaretLeftSquareFill
            className={styles.arrow}
            onClick={() => setShowWhotoFollow(prev => !prev)}
          />
        ) : (
          <BsFillCaretRightSquareFill
            className={styles.arrow}
            onClick={() => setShowWhotoFollow(prev => !prev)}
          />
        )}

        <WhoToFollow showWhotoFollow={showWhotoFollow} />
      </div>{" "}
    </div>
  );
};

export default Posts;
