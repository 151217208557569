import {
  REQUEST_USER_SIGNOUT,
  SET_USER_IMAGE_PROFILE,
  SET_USER_NAME_PROFILE,
  SetImageUserPayload,
  SetNameUserPayload,
} from "@/redux/types/userSetup";

export const setImageUserProfile = ({ userImage }: SetImageUserPayload) => ({
  type: SET_USER_IMAGE_PROFILE,
  payload: () => {
    return userImage;
  },
});

export const setNameUserProfile = ({ userName }: SetNameUserPayload) => ({
  type: SET_USER_NAME_PROFILE,
  payload: () => {
    return userName;
  },
});

export const requestUserSignout = () => ({
  type: REQUEST_USER_SIGNOUT,
  payload: () => "",
});
