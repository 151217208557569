import { ethers } from "ethers";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";

import { AddNotificationToDb } from "@/Database/Notification";
import { NotificationDocInterface } from "@/Database/Notification/Notification.definition";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { PostType } from "@/types";
import { formatShortAddress } from "@/utils";

import styles from "./styles.module.css";

type BuyNowProps = {
  post: PostType | undefined | null;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const BuyNow = ({ post, setModal }: BuyNowProps) => {
  const [buying, setBuying] = useState(false);
  const { buyNFT } = use0xProtocol();
  const { getNfts } = useNFT();
  const { currentUserData } = useAuth();
  const network = currentUserData?.address.startsWith("0x") ? "EVM" : "SOLANA";

  if (!post?.nft) return <></>;

  const handleBuy = async () => {
    if (
      !post?.nft ||
      !post?.nft.order?.nonce ||
      !currentUserData ||
      !currentUserData.address.startsWith("0x")
    )
      return;
    setBuying(true);
    try {
      await buyNFT(post?.nft.order.nonce);
      const newNotification: NotificationDocInterface = {
        uuid: "",
        userId: post.post.userId,
        friendId: currentUserData?.uuid,
        postId: post.post.id,
        type: "nft",
        createdAt: Timestamp.fromDate(new Date()),
        read: false,
      };
      await AddNotificationToDb(newNotification);
      getNfts(currentUserData, network);
    } finally {
      setBuying(false);
      setModal(false);
    }
  };

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalWrapper}>
          <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
          <img src={post?.nft.image} alt="#" className={styles.image} />
          <div className={styles.details}>
            <h4 className={styles.nameId}>{post.nft?.metadata?.name ?? post?.nft.name ?? "No Name"}</h4>
            <p className={styles.name}>{post?.nft.name}</p>
            <div className={styles.keyAndValueContainer}>
              {/* <div className={styles.keyAndValue}>
                <p className={styles.key}>By</p>
                <p className={styles.value}>{ethers.utils.formatEther(nft.order?.price ?? '')}</p>
              </div> */}
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Owner</p>
                <p className={styles.value}>{formatShortAddress(post?.nft.owner_of)}</p>
              </div>
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Purchase price </p>
                <p className={styles.key}>
                  <span className={styles.ethValue}>
                    {ethers.utils.formatEther(post?.nft.order?.price ?? "")}
                    {" ETH"}
                  </span>{" "}
                  {/* <span>${purchasePrice.usd}</span> */}
                </p>
              </div>
            </div>
            <button className={styles.button} onClick={handleBuy}>
              <div className={styles.buttonLoader}>
                {buying && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span style={{ paddingLeft: buying ? "10px" : "0px" }}>Buy Now</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setModal(false)}></div>
    </>
  );
};

export default BuyNow;
