import { Web3Provider } from "@ethersproject/providers";
import { getMoralisAuth } from "@moralisweb3/client-firebase-auth-utils";
import {
  SignInWithMoralisResponse,
  signInWithMoralis as signInWithMoralisByEvm,
} from "@moralisweb3/client-firebase-evm-auth";
import {
  SignInWithMoralisResponse as SignInWithMoralisResponsebySolana,
  signInWithMoralis as signInWithMoralisBySolana,
} from "@moralisweb3/client-firebase-sol-auth";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { User, browserLocalPersistence } from "firebase/auth";
import { onSnapshot, query, where } from "firebase/firestore";
import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetCommentsFromPost, PostCollection } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { UserCollection } from "@/Database/User";
import { UserDocInterface } from "@/Database/User/User.definition";
import Loader from "@/components/Loader/Loader";
import SetUpYourProfile from "@/components/pages/SetUpYourProfile/SetUpYourProfile";
import { config } from "@/config";
import app, { auth } from "@/firebase";
import { useNFT, useUserSetup } from "@/hooks";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getCollections } from "@/services/moralis";
import { getNftsMoralis } from "@/services/moralis";
import { MutualCollectionType, NFTType, PostType } from "@/types";
import { getBlockchain } from "@/utils";
import {
  SignInWithCardanoResponse,
  getTangocryptoAuth,
  signInWithCardano,
} from "@/utils/FirebaseCardanoAuth";
import { CARDANO_SUPPORTED_WALLETS } from "@/utils/cardano-auth";

import styles from "./styles.module.css";

type AuthContextProps = {
  children?: ReactNode;
};

type AuthContextDefault = {
  currentUser: User | undefined | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined | null>>;
  currentUserData: UserDocInterface | undefined | null;
  setCurrentUserData: React.Dispatch<React.SetStateAction<UserDocInterface | undefined | null>>;
  usersData: Map<string, UserDocInterface> | undefined | null;
  mapUsernameToUuid: Map<string, string> | undefined | null;
  myCollections: MutualCollectionType[] | undefined | null;
  myPosts: PostType[] | undefined | null;
  Login: (
    wallet: string
  ) => Promise<SignInWithMoralisResponse | SignInWithMoralisResponsebySolana | SignInWithCardanoResponse>;
  Logout: () => Promise<void>;
  provider: Web3Provider | undefined | null;
};

const AuthContext = createContext<AuthContextDefault>({
  currentUser: null,
  setCurrentUser: () => {},
  currentUserData: null,
  setCurrentUserData: () => {},
  usersData: null,
  mapUsernameToUuid: null,
  myCollections: null,
  myPosts: null,
  Login: () => Promise.resolve({} as SignInWithMoralisResponse),
  Logout: () => Promise.resolve(),
  provider: null,
});

export const AuthContextProvider: FC<AuthContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<User | undefined | null>(null);
  const [currentUserData, setCurrentUserData] = useState<UserDocInterface | undefined | null>(null);
  const [usersData, setUsersData] = useState<Map<string, UserDocInterface> | undefined | null>(null);
  const [mapUsernameToUuid, setMapUsernameToUuid] = useState<Map<string, string> | undefined | null>(null);
  const [myCollections, setMyCollections] = useState<MutualCollectionType[] | undefined | null>(null);
  const [myPosts, setPosts] = useState<PostType[] | undefined | null>(null);
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [loadingCurrentUserData, setLoadingCurrentUserData] = useState(true);
  const [provider, setProvider] = useState<Web3Provider | undefined | null>(null);

  const moralisApp = getMoralisAuth(app);
  const tangocryptoApp = getTangocryptoAuth(app);

  moralisApp.auth.setPersistence(browserLocalPersistence);

  const { getNfts, nfts } = useNFT();
  const { userSignout } = useUserSetup();

  const Login = async (
    wallet: string
  ): Promise<SignInWithMoralisResponse | SignInWithMoralisResponsebySolana | SignInWithCardanoResponse> => {
    if (wallet === "Phantom") {
      return await signInWithMoralisBySolana(moralisApp);
    }
    if (wallet === "WalletConnect") {
      localStorage.removeItem("walletconnect");
      const provider = new WalletConnectProvider({
        rpc: {
          1: process.env.REACT_APP_WALLETCONNECT_RPC_ETHEREUM ?? "",
          5: process.env.REACT_APP_WALLETCONNECT_RPC_GOERLI ?? "",
        },
      });
      await provider.enable();
      const web3Provider = new Web3Provider(provider);
      setProvider(web3Provider);
      return await signInWithMoralisByEvm(moralisApp, { provider: web3Provider });
    }

    let provider;
    if (wallet === "Coinbase") {
      try {
        provider = window.ethereum.providerMap.get("CoinbaseWallet");
      } catch {
        if (!window.ethereum || !window.ethereum.isCoinbaseWallet)
          throw new Error("Continent Error: Coinbase Wallet not detected. Please install it.");
        provider = window.ethereum;
      }
      await provider.enable();
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: config.chainId }],
      });
      const web3Provider = new Web3Provider(provider);
      await checkNetwork(web3Provider);
      setProvider(web3Provider);
      return await signInWithMoralisByEvm(moralisApp, { provider: web3Provider });
    }

    const supportedWallet = CARDANO_SUPPORTED_WALLETS.find(({ name }) => name === wallet);

    if (supportedWallet) {
      return await signInWithCardano(tangocryptoApp, { wallet: supportedWallet.name.toLocaleLowerCase() });
    }

    // Metamask as default
    try {
      provider = window.ethereum.providerMap.get("MetaMask");
    } catch {
      if (!window.ethereum || !window.ethereum.isMetaMask)
        throw new Error("Continent Error: Metamask not detected. Please install it.");
      provider = window.ethereum;
    }
    await provider.enable();
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: config.hexChainId }],
    });
    const web3Provider = new Web3Provider(provider);
    await checkNetwork(web3Provider);
    setProvider(web3Provider);
    return await signInWithMoralisByEvm(moralisApp, { provider: web3Provider });
  };

  const checkNetwork = async (provider: Web3Provider) => {
    const currentNetwork = process.env.REACT_APP_NETWORK ?? "ETHEREUM";
    const network = await provider.getNetwork();
    if (currentNetwork === "GOERLI" && network.chainId !== 5)
      throw new Error("Continent Error: Incorrect Network. Please choose Goerli Network");
    if (currentNetwork !== "GOERLI" && network.chainId !== 1)
      throw new Error("Continent Error: Incorrect Network. Please choose Ethereum Network");
  };

  const Logout = async (): Promise<void> => {
    userSignout();
    setCurrentUser(null);
    setCurrentUserData(null);
    navigate("/");
    return await auth.signOut();
  };

  useEffect(() => {
    setLoadingCurrentUser(true);
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoadingCurrentUser(false);
      setLoadingCurrentUserData(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const check = async () => {
      const unsuscribe = onSnapshot(query(UserCollection), querySnapshot => {
        let newUsersData: Map<string, UserDocInterface> = new Map<string, UserDocInterface>();
        let newMapUsernameToUuid: Map<string, string> = new Map<string, string>();
        querySnapshot.forEach(doc => {
          newUsersData.set(doc.id, doc.data());
          newMapUsernameToUuid.set(doc.data().username!, doc.id);
        });
        setUsersData(newUsersData);
        setMapUsernameToUuid(newMapUsernameToUuid);
      });

      return () => unsuscribe;
    };
    check().catch(console.error);
  }, []);

  useEffect(() => {
    const check = async () => {
      if (currentUser) {
        const blockchain = getBlockchain(currentUser.displayName);
        setLoadingCurrentUserData(true);
        const unsuscribe = onSnapshot(
          query(UserCollection, where("uuid", "==", currentUser.uid)),
          querySnapshot => {
            setLoadingCurrentUserData(true);

            let newCurrentUserData: UserDocInterface | null = null;
            querySnapshot.forEach(doc => {
              newCurrentUserData = doc.data();
            });
            setCurrentUserData(newCurrentUserData);
            setLoadingCurrentUserData(false);
            getNfts(newCurrentUserData!, blockchain);
          }
        );

        return () => unsuscribe;
      }
    };

    check().catch(console.error);
  }, [currentUser, getNfts]);

  useEffect(() => {
    const check = async () => {
      if (currentUserData) {
        const blockchain = getBlockchain(currentUserData.address);
        if (currentUserData.address) {
          if (blockchain === "CARDANO") {
            // same as Solana?? (no collections)
            setMyCollections([]);
          } else {
            getCollections(currentUserData.address, blockchain).then(currentMyCollections =>
              setMyCollections(currentMyCollections)
            );
          }
        }

        const q = query(
          PostCollection,
          where("userId", "==", currentUserData.uuid),
          where("deleted", "==", false)
        );
        const unsuscribe = onSnapshot(q, async querySnapshot => {
          let myPostsFromDB: Array<PostDocInterface> = [];
          querySnapshot.forEach(async doc => {
            const postFromDB = doc.data();
            myPostsFromDB.push({ ...postFromDB, id: doc.id });
          });

          let newMyPosts: Array<PostType> = [];
          for (let myPostFromDB of myPostsFromDB) {
            const comments = await GetCommentsFromPost(myPostFromDB.id!);
            const post = { ...myPostFromDB, comments };
            let nft: NFTType | undefined;
            if (nfts) {
              nft = nfts.find(e => e.token_address === post.tokenAddress && e.token_id === post.tokenId);
            }
            if (!nft) {
              if (blockchain === "CARDANO") {
                const { nfts: nftInfo } = await getNftsBlockfrost(
                  currentUserData.address,
                  "",
                  post.tokenAddress,
                  post.tokenId,
                  false,
                  false
                );
                nft = nftInfo[0];
              } else {
                const { nfts: nftInfo } = await getNftsMoralis(
                  currentUserData.address,
                  "",
                  post.tokenAddress,
                  post.tokenId,
                  false,
                  false,
                  blockchain
                );
                nft = nftInfo[0];
              }
            }
            newMyPosts.push({ post, nft });
          }
          setPosts(newMyPosts);
        });
        return () => unsuscribe;
      }
    };

    check().catch(console.error);
  }, [currentUserData, nfts]);

  const Logic = () => {
    if (loadingCurrentUser || loadingCurrentUserData)
      return (
        <div className={styles.container}>
          <Loader />
        </div>
      );

    if (currentUser && currentUserData == null) return <SetUpYourProfile />;

    return children;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentUserData,
        setCurrentUserData,
        usersData,
        mapUsernameToUuid,
        myCollections,
        myPosts,
        Login,
        Logout,
        provider,
      }}
    >
      {Logic()}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
