import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserDocInterface } from "@/Database/User/User.definition";
import { getNFTs, setNFTDetail } from "@/redux/actions";
import { RootState } from "@/redux/store";

export const useNFT = () => {
  const dispatch = useDispatch();
  const { didInvalidate, isFetching, isFetchingMore, noMoreNfts, nfts, cursor, nftSelected } = useSelector(
    (state: RootState) => state.NFT
  );

  const getNfts = useCallback(
    async (
      currentUserData: UserDocInterface | undefined | null,
      network: string,
      page: number = 1,
      size: number = 500
    ) => {
      dispatch(getNFTs(currentUserData, network, page, cursor, size));
    },
    [dispatch]
  );

  const setNFT = useCallback(
    async ({ nftDetail }: any) => {
      dispatch(setNFTDetail({ nftDetail }));
    },
    [dispatch]
  );

  return { getNfts, nfts, isFetching, isFetchingMore, noMoreNfts, didInvalidate, setNFT, nftSelected };
};
