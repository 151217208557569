import { useState } from "react";
import { RxArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import { CreatePost, iCreatePost } from "@/Database/Posts";
import { edit, imagePlaceholder, walletMoney } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { ContractType, NFTType } from "@/types";
import { getBlockchain } from "@/utils";

import CheckBox from "./Checkbox/CheckBox";
import SetValue from "./SetValue/SetValue";

import styles from "./styles.module.css";

type AddPostProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDiscardPost: React.Dispatch<React.SetStateAction<boolean>>;
  nft: NFTType;
};

const AddPost = ({ setModal, setDiscardPost, nft }: AddPostProps) => {
  const { currentUserData } = useAuth();
  const network = getBlockchain(currentUserData?.address);

  const [addCaption, setAddCaption] = useState("");
  const [notForSale, setNotForSale] = useState(true);
  const [listForSale, setListForSale] = useState(false);
  const [priceNotValid, setPriceNotValid] = useState(false);
  const [value, setValue] = useState("0.00");
  const [creatingOrder, setCreatingOrder] = useState(false);

  const { sellNFT } = use0xProtocol();
  const { getNfts } = useNFT();

  const navigate = useNavigate();
  const { /*setCancelUploading, */ setUploadingProgress } = useGeneralContext();

  const saleAvailable = nft.token_address.startsWith("0x");

  const handleNotForSale = () => {
    setNotForSale(true);
    setListForSale(false);
    setPriceNotValid(false);
  };

  const handleListForSale = () => {
    setNotForSale(false);
    setListForSale(true);
    setPriceNotValid(false);
  };

  const handleSave = async () => {
    if (!currentUserData) return;
    const priceNotValid = value.length === 0 || isNaN(parseFloat(value)) || parseFloat(value) === 0;
    if (listForSale && priceNotValid) {
      setPriceNotValid(true);
      return;
    }
    setCreatingOrder(true);
    const post: iCreatePost = {
      userId: currentUserData?.uuid,
      tokenAddress: nft.token_address,
      tokenId: nft.token_id,
      text: addCaption,
      imageUrl: nft.image,
      metadata: nft.metadata,
    };
    await CreatePost(post);
    if (listForSale) {
      await sellNFT(nft.token_address, nft.token_id, nft.contract_type as ContractType, value);
    }
    getNfts(currentUserData, network);
    navigate("/");
    setCreatingOrder(false);
    setModal(false);
    // setCancelUploading(false);
    setUploadingProgress(100);
  };

  return (
    <>
      <div className={styles.addPostContainer}>
        <div className={styles.addPost}>
          <div className={`${styles.wrapper} grScrollbar`}>
            {" "}
            <div className={styles.backButton} onClick={() => setModal(false)}>
              <RxArrowLeft className={styles.back} />
              <span className={styles.backText}>Back</span>
            </div>
            <img
              src={nft.image}
              onError={e => {
                e.currentTarget.src = imagePlaceholder;
              }}
              alt="#"
              className={styles.image}
            />
            <div className={styles.details}>
              <div className={styles.userContainer}>
                <div className={styles.profile}>
                  <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
                </div>{" "}
                <p className={styles.text}>{currentUserData?.username}</p>
              </div>
              <div className={styles.edits}>
                <img src={edit} alt="#" className={styles.edit} />
                <textarea
                  name="addCaption"
                  value={addCaption}
                  placeholder="Add caption..."
                  onChange={e => setAddCaption(e.target.value)}
                  className={`${styles.text} ${styles.textArea} ${styles.editText}`}
                ></textarea>
              </div>
              {saleAvailable && (
                <div className={styles.listForSaleContainer}>
                  <div className={styles.listForSale}>
                    <img src={walletMoney} alt="#" className={styles.edit} />
                    <p className={`${styles.text}`}>List for sale?</p>
                  </div>
                  <div className={styles.checkboxContainer}>
                    <CheckBox label="No" onChange={handleNotForSale} checked={notForSale} />
                    <CheckBox label="Yes" onChange={handleListForSale} checked={listForSale} />
                  </div>

                  {listForSale && (
                    <SetValue
                      value={value}
                      priceNotValid={priceNotValid}
                      setPriceNotValid={setPriceNotValid}
                      setValue={setValue}
                    />
                  )}
                </div>
              )}
              <div className={styles.btnDiv}>
                <button
                  className={`primaryButton ${styles.invertBtn}`}
                  onClick={() => {
                    setDiscardPost(true);
                    setUploadingProgress(40);
                  }}
                >
                  Cancel
                </button>
                <button className={`primaryButton ${styles.button}`} onClick={handleSave}>
                  <div className={styles.buttonLoader}>
                    {creatingOrder && <Loader size={20} padding={"0px"} width={"20px"} />}
                    <span style={{ paddingLeft: creatingOrder ? "10px" : "0px" }}>Share</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default AddPost;
