import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MarkNotificationReadToDb } from "@/Database/Notification";
import { NotificationDocInterface } from "@/Database/Notification/Notification.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { formatTime } from "@/utils";

import styles from "./styles.module.css";

type NotificationProps = {
  data: NotificationDocInterface[];
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
};

const Notification = ({ data, setShowNotification }: NotificationProps) => {
  const { usersData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) return;
    data.forEach(e => {
      if (!e.read) MarkNotificationReadToDb(e.uuid);
    });
  }, [data]);

  const handleClickUser = (username: string | undefined) => {
    if (!username) return;
    navigate(`/userProfile/${username}`);
    setShowNotification(false);
  };

  const sortingCriteria = (a: NotificationDocInterface, b: NotificationDocInterface) => {
    if (a.createdAt < b.createdAt) return 1;
    return -1;
  };

  if (!usersData) {
    return <></>;
  }

  return (
    <div className={`${styles.notificationContainer} marginTop `}>
      <h3 className={styles.heading}>Notifications</h3>

      <div className={`${styles.notifications} grScrollbar`}>
        {" "}
        {data.sort(sortingCriteria).map((el: NotificationDocInterface, i: number) => (
          <div className={styles.userWrapper} key={i}>
            <div className={styles.userAndnotification}>
              {" "}
              <div
                className={styles.userImage}
                onClick={() => handleClickUser(usersData?.get(el.friendId)?.username)}
              >
                <HexagonImage src={usersData?.get(el.friendId)?.avatarPhoto} background={""} />
              </div>
              <p className={styles.userInfo}>
                <span
                  className={styles.userName}
                  onClick={() => handleClickUser(usersData?.get(el.friendId)?.username)}
                >
                  {`${usersData?.get(el.friendId)?.username} `}
                </span>
                {el.type === "friend" && <span className={styles.text}>sent you a friend request.</span>}
                {el.type === "follow" && <span className={styles.text}> followed you.</span>}
                {el.type === "nft" && <span className={styles.text}> purchased your NFT.</span>}
                {el.type === "like" && <span className={styles.text}> liked your post.</span>}
                {el.type === "comment" && (
                  <span className={styles.text}> commented : {el.commentId}.</span>
                )}{" "}
                <span className={styles.time}>{formatTime(el.createdAt)}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notification;
