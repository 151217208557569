import { formatEther } from "ethers/lib/utils";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";

import { AddNotificationToDb } from "@/Database/Notification";
import { NotificationDocInterface } from "@/Database/Notification/Notification.definition";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";
import { use0xProtocol } from "@/hooks/0xprotocol";
import { NFTType } from "@/types";
import { formatShortAddress } from "@/utils";

import styles from "./styles.module.css";

type PostBuyProps = {
  nft: NFTType;
  post: PostDocInterface;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const PostBuy = ({ post, nft, setModal }: PostBuyProps) => {
  const [buying, setBuying] = useState(false);
  const { buyNFT } = use0xProtocol();
  const { getNfts } = useNFT();
  const { currentUserData } = useAuth();
  const network = currentUserData?.address.startsWith("0x") ? "EVM" : "SOLANA";

  const handleBuy = async () => {
    if (!nft || !nft.order?.nonce || !currentUserData || network !== "EVM") return;
    setBuying(true);
    try {
      await buyNFT(nft.order.nonce);
      const newNotification: NotificationDocInterface = {
        uuid: "",
        userId: post.userId,
        friendId: currentUserData?.uuid,
        postId: post.id,
        type: "nft",
        createdAt: Timestamp.fromDate(new Date()),
        read: false,
      };
      await AddNotificationToDb(newNotification);
      getNfts(currentUserData, network);
    } finally {
      setBuying(false);
      setModal(false);
    }
  };

  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalWrapper}>
          <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
          <img src={nft.image} alt="#" className={styles.image} />
          <div className={styles.details}>
            <h4 className={styles.nameId}>{nft.metadata?.name ?? nft.name ?? "No Name"}</h4>
            <p className={styles.name}>{nft.name}</p>
            <div className={styles.keyAndValueContainer}>
              {/* <div className={styles.keyAndValue}>
                <p className={styles.key}>By</p>
                <p className={styles.value}>{address}</p>
              </div> */}
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Owner</p>
                <p className={styles.value}>{formatShortAddress(nft.owner_of)}</p>
              </div>
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Purchase price </p>
                <p className={styles.key}>
                  <span className={styles.ethValue}>
                    {formatEther(nft.order?.price ?? "0")}
                    {" ETH"}
                  </span>{" "}
                  {/* <span>$250.76</span> */}
                </p>
              </div>
            </div>
            <button className={styles.button} onClick={handleBuy}>
              <div className={styles.buttonLoader}>
                {buying && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span style={{ paddingLeft: buying ? "10px" : "0px" }}>Buy Now</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostBuy;
