import { useEffect, useState } from "react";

import { UserDocInterface } from "@/Database/User/User.definition";
import { useAuth } from "@/context/AuthContext";

import User from "./User";

import styles from "./styles.module.css";

type FindFriendsProps = {
  showWhotoFollow: boolean;
};

const MIN_USERS_NUMBER = 3;
const MAX_USERS_NUMBER = 10;

const FindFrinds = ({ showWhotoFollow }: FindFriendsProps) => {
  const [userNumber, setUserNumber] = useState(MIN_USERS_NUMBER);
  const [showMore, setShowMore] = useState(true);

  const [latestUsers, setLatestUsers] = useState<UserDocInterface[]>();
  const { usersData, currentUserData } = useAuth();

  useEffect(() => {
    let users: UserDocInterface[] = [];

    usersData?.forEach(user => {
      if (user.uuid !== currentUserData?.uuid && !currentUserData?.following?.includes(user.uuid)) {
        users.push(user);
      }
    });
    setLatestUsers(users);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!latestUsers) return <></>;

  return (
    <div className={`${styles.whotoFollow} ${showWhotoFollow && styles.showWhotoFollow} marginTop `}>
      <h4 className={styles.title}>{currentUserData ? "Who To Follow" : "Top Users"}</h4>

      <div className={`${styles.userWrapper} grScrollbar`}>
        {latestUsers.slice(0, userNumber).map((el, i) => (
          <User key={i} user={el} />
        ))}
      </div>
      <p
        className={styles.showMore}
        onClick={() => {
          setShowMore(prev => !prev);
          if (showMore) {
            setUserNumber(MAX_USERS_NUMBER);
          } else setUserNumber(MIN_USERS_NUMBER);
        }}
      >
        {showMore ? "Show more" : "  Show less"}
      </p>
    </div>
  );
};

export default FindFrinds;
