import { EvmChain } from "@moralisweb3/common-evm-utils";
import { SolNetwork } from "@moralisweb3/common-sol-utils";

const getMoralisNetwork = (network: string | undefined) => {
  if (network === "ETHEREUM") return EvmChain.ETHEREUM;
  if (network === "GOERLI") return EvmChain.GOERLI;
  return "";
};
const getChainId = (network: string | undefined) => {
  if (network === "ETHEREUM") return 1;
  if (network === "GOERLI") return 5;
  return "";
};

const getHexChainId = (network: string | undefined) => {
  if (network === "ETHEREUM") return "0x1";
  if (network === "GOERLI") return "0x5";
  return "";
};

export const config = {
  moralisNetwork: getMoralisNetwork(process.env.REACT_APP_NETWORK),
  chainId: getChainId(process.env.REACT_APP_NETWORK),
  hexChainId: getHexChainId(process.env.REACT_APP_NETWORK),
  moralisSolanaNetwork: SolNetwork.MAINNET,
};
