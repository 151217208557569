import { Link } from "react-router-dom";

import { PostDocInterface } from "@/Database/Posts/Post.definition";

import styles from "./SingleNft.module.css";

type SingleNftProps = {
  postData: PostDocInterface;
};

const SingleNft = ({ postData }: SingleNftProps) => {
  return (
    <Link to={"/detail/" + postData.id}>
      <div className={styles.nftContainer}>
        <img src={postData.imageUrl} alt="#" className={styles.nftImage} />
      </div>
    </Link>
  );
};

export default SingleNft;
