import {
  SET_USER_IMAGE_PROFILE,
  SET_USER_NAME_PROFILE,
  UserSetupAction,
  UserSetupState,
} from "@/redux/types/userSetup";

const INITIAL_STATE: UserSetupState = {
  name: "",
  image: "",
};

export const UserSetupReducer = (
  state: UserSetupState = INITIAL_STATE,
  { type, payload }: UserSetupAction
) => {
  switch (type) {
    case SET_USER_IMAGE_PROFILE:
      return {
        ...state,
        image: payload,
      };
    case SET_USER_NAME_PROFILE:
      return {
        ...state,
        name: payload,
      };
    default:
      return state;
  }
};
