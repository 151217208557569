import { formatEther } from "ethers/lib/utils";
import { useState } from "react";
import { Link } from "react-router-dom";

import { UserDocInterface } from "@/Database/User/User.definition";
import { eth, imagePlaceholder } from "@/assets/images/image";
import Modal from "@/components/Modal/Modal";
// import { useAuth } from "@/context/AuthContext";
import { useNFT } from "@/hooks";

import BuyNow from "./BuyNow/BuyNow";

import styles from "./styles.module.css";

type DetailsProps = {
  currentUserData: UserDocInterface | null | undefined;
  blockchain: string;
};
const Nfts = ({ currentUserData, blockchain }: DetailsProps) => {
  const [activeDetails, setActiveDetails] = useState(-1);
  const [showActiveDetails, setShowActiveDetails] = useState(false);
  // const [nftNumber, setNftNumber] = useState(12);
  // const [showMore, setShowMore] = useState(true);
  // const [isBottom, setIsBottom] = useState(false);
  // const currentPage = useRef(1)

  const { nfts, isFetchingMore } = useNFT();

  // TODO: add pagination
  // Handle user scrolling the page
  // function handleUserScroll() {
  //   // get scroll top value
  //   const scrollTop = document.documentElement.scrollTop;

  //   // get the entire height, including padding
  //   const scrollHeight = document.documentElement.scrollHeight;

  //   // check if user is near the bottom of the body
  //   if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
  //     setIsBottom(true);
  //   }
  // }

  // set scroll listener on mount
  // useEffect(() => {
  //   window.addEventListener("scroll", handleUserScroll);
  //   return () => window.removeEventListener("scroll", handleUserScroll);
  // }, []);

  // handle re-rendering when users get to the bottom of the page
  // useEffect(() => {
  //   if (isBottom) {
  //     if (!isFetchingMore && !noMoreNfts) {
  //       // render the next batch of pre-fetched users
  //       currentPage.current +=1;
  //       getNfts(currentUserData, blockchain, currentPage.current);
  //     }
  //     setIsBottom(false);
  //   }
  // }, [isBottom, isFetchingMore, noMoreNfts, setIsBottom, getNfts, currentUserData, blockchain]);

  return (
    <>
      <div className={styles.posts}>
        {nfts &&
          nfts.map((el, i) => (
            <div className={styles.post} key={i}>
              <img
                src={el.image ?? imagePlaceholder}
                alt="#"
                className={styles.image}
                onError={e => {
                  e.currentTarget.src = imagePlaceholder;
                }}
              />{" "}
              <div className={styles.detailsContainer}>
                <p className={styles.name}>{el.name}</p>

                <Link
                  className={styles.token_link}
                  to={`/nfts/${el.token_address}/${
                    el.token_id ? el.token_id : "_"
                  }/${currentUserData?.address}`}
                >
                  <p className={styles.id}>{el.metadata?.name ?? el.name ?? "No Name"}</p>
                </Link>
                {el.order?.price && (
                  <div
                    className={styles.valueContainer}
                    onClick={() => {
                      setActiveDetails(i + 1);
                      setShowActiveDetails(true);
                    }}
                  >
                    <span className={styles.value}>{formatEther(el.order?.price ?? "0")}</span>
                    <img src={eth} alt="#" className={styles.ethIcon} />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>{" "}
      {/* <button
        className={styles.button}
        onClick={() => {
          setShowMore((prev) => !prev);
          if (showMore) {
            setNftNumber(posts.length);
          } else {
            setNftNumber(12);
          }
        }}
      >
        {!showMore ? "Less NFTs" : "More NFTs"}
      </button> */}
      {isFetchingMore && (
        <p style={{ textAlign: "center", color: "#ffffff", margin: "10px" }}> Loading... </p>
      )}
      {showActiveDetails && (
        <Modal setModal={setShowActiveDetails} background="rgba(9, 13, 36, 0.67)">
          <BuyNow nft={nfts[activeDetails - 1]} setModal={setShowActiveDetails} />
        </Modal>
      )}
    </>
  );
};

export default Nfts;
