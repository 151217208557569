import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestUserSignout, setImageUserProfile, setNameUserProfile } from "@/redux/actions";

export const useUserSetup = () => {
  const dispatch = useDispatch();
  const { name, image } = useSelector(state => state.UserSetup);

  const setUserName = useCallback(
    async ({ userName }) => {
      dispatch(setNameUserProfile({ userName }));
    },
    [dispatch]
  );

  const setUserImage = useCallback(
    async ({ userImage }) => {
      dispatch(setImageUserProfile({ userImage }));
    },
    [dispatch]
  );

  const userSignout = useCallback(async () => {
    dispatch(requestUserSignout());
  }, [dispatch]);

  return { name, image, setUserName, setUserImage, userSignout };
};
