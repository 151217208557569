import { CgArrowLeft } from "react-icons/cg";

import { NFTType } from "@/types";

import Description from "./DetailsDescriptionAndProperties/Description";
import Detail from "./DetailsDescriptionAndProperties/Details";
import Properties from "./DetailsDescriptionAndProperties/Properties";

import styles from "./styles.module.css";

type DetailsProps = {
  setShowDetails: (details: boolean) => void;
  showDetails: boolean;
  nft: NFTType;
};

const Details = ({ setShowDetails, showDetails, nft }: DetailsProps) => {
  return (
    <div className={`${styles.descriptionContainer} ${showDetails && styles.descriptionWrapper} grScrollbar`}>
      <div className={styles.nameId}>
        <p className={styles.name}>{`${nft.metadata?.name ?? nft.name ?? "No Name"}`}</p>
        <span className={styles.nameHighlight}>{nft.metadata?.name ?? nft.name ?? "No Name"}</span>
      </div>
      <CgArrowLeft className={styles.close} onClick={() => setShowDetails(false)} />
      <Detail nft={nft} />
      <Description nft={nft} />
      <Properties nft={nft} />
    </div>
  );
};

export default Details;
