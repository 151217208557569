import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { GetPostById, LikePost, UnlikePost } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { GetUserFromDb } from "@/Database/User";
import { UserDocInterface } from "@/Database/User/User.definition";
import {
  comment,
  ethIcon,
  imagePlaceholder, // user,
  love,
  lovefill,
  send,
} from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Modal from "@/components/Modal/Modal";
import CommentsAndDetails from "@/components/pages/Home/Posts/CommentsAndDetails/CommentsAndDetails";
import NftMore from "@/components/pages/Home/Posts/NftMore/NftMore";
import PostBuy from "@/components/pages/Home/Posts/PostBuy/PostBuy";
import ShareWithModal from "@/components/pages/Home/Posts/ShareModal/ShareWithModal";
import { useAuth } from "@/context/AuthContext";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import { NFTType } from "@/types";
import { getBlockchain, numberFormating } from "@/utils";
import { formatDate } from "@/utils";

import styles from "./styles.module.css";

type SinglePostProps = {
  postId?: string;
  i: string;
};

const SinglePost = ({ postId, i }: SinglePostProps) => {
  const { currentUserData, usersData } = useAuth();
  const { setShowConnectWalletModal } = useGeneralContext();

  const [isInteracting, setIsInteracting] = useState(false);
  const [like, setLike] = useState(false);

  const [nftMore, setNftMore] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);

  const [postData, setPostData] = useState<PostDocInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userData, setUserData] = useState<UserDocInterface>();
  const [nft, setNft] = useState<NFTType>();

  let { id } = useParams();

  useEffect(() => {
    GetPostById(id ? id : postId!)
      .then(post => {
        if (post != null) {
          setPostData(post);
          setLike(post.likes.includes(currentUserData?.uuid ?? ""));
          const address = usersData?.get(post.userId)?.address ?? "";
          const blockchain = getBlockchain(address);
          if (blockchain === "CARDANO") {
            getNftsBlockfrost(address, post.userId, post.tokenAddress, post.tokenId, false, false).then(
              ({ nfts }) => setNft(nfts[0])
            );
          } else {
            getNftsMoralis(
              address,
              post.userId,
              post.tokenAddress,
              post.tokenId,
              false,
              false,
              blockchain
            ).then(({ nfts }) => setNft(nfts[0]));
          }
          return GetUserFromDb(post.userId);
        }
      })
      .then(u => {
        setUserData(u!);
        setIsLoading(false);
      });
  }, [currentUserData, id, postId, usersData]);

  const handleLike = async () => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (isInteracting) return;
    setIsInteracting(true);
    let hasToLike = !like;
    setLike(prev => !prev);

    if (hasToLike) await LikePost(postData?.id!, currentUserData?.uuid!);
    else await UnlikePost(postData?.id!, currentUserData?.uuid!);

    setPostData((await GetPostById(postData?.id!)) as PostDocInterface);
    setIsInteracting(false);
  };

  if (isLoading) {
    return <></>;
  } else {
    return (
      <>
        <div className={styles.post}>
          <div className={styles.header}>
            <div className={styles.userContainer}>
              <div className={styles.hexagon}>
                <HexagonImage src={userData?.avatarPhoto} background={""} />{" "}
              </div>
              <Link to={`/userProfile/${userData?.username}`} className={styles.button}>
                <p className={`${styles.text} ${styles.userName}`}>{userData?.username}</p>
              </Link>
            </div>
            <BiDotsVerticalRounded className={styles.more} onClick={() => setNftMore(prev => !prev)} />
            {nftMore && (
              <NftMore
                author={currentUserData?.uuid === postData?.userId}
                setNftMore={setNftMore}
                postId={postData?.id}
                nft={nft}
              />
            )}
          </div>
          <div className={styles.imageContainer}>
            <img
              src={postData?.imageUrl}
              alt="#"
              className={styles.nftImage}
              onError={e => {
                e.currentTarget.src = imagePlaceholder;
              }}
            />
          </div>
          <div className={styles.informationContainer}>
            <div className={styles.information}>
              <div className={styles.likeCommentSend}>
                <div className={styles.like}>
                  <img src={like ? lovefill : love} alt="#" className={styles.icon} onClick={handleLike} />

                  <span className={styles.number}>{numberFormating(postData!.likes.length)}</span>
                </div>
                <div className={styles.comment} onClick={() => setShowCommentsModal(prev => !prev)}>
                  <img src={comment} alt="#" className={styles.icon} />
                  <span className={styles.number}>{numberFormating(postData!.commentsCount)}</span>
                </div>
                {currentUserData && (
                  <img
                    src={send}
                    alt="#"
                    className={styles.icon}
                    onClick={() => setShareModal(prev => !prev)}
                  />
                )}
              </div>
              <div className={styles.idAndbutton}>
                <p className={styles.text}>{postData?.mtd?.name!}</p>
                <Link to={`/post/${i}`} className={styles.button}>
                  Detail
                </Link>
              </div>
              <div className={styles.aboutPost}>
                <p className={`${styles.text} ${styles.userName}`}>{userData?.username} </p>
                <p className={`${styles.text} ${styles.subText}`}>
                  {postData?.text} &nbsp;
                  <span className={styles.time}> {formatDate(new Date(postData!.timestamp))}</span>
                </p>
              </div>
            </div>
            {nft?.order?.price && (
              <div className={styles.ethContainer} onClick={() => setShowBuyModal(true)}>
                <span className={styles.eth}>{formatEther(nft.order?.price ?? "0")}</span>
                <img src={ethIcon} alt="#" className={styles.ethIcon} />
              </div>
            )}
          </div>
        </div>

        {currentUserData && shareModal && (
          <ShareWithModal setModal={setShareModal} nft={nft!} postId={postData?.id!} />
        )}
        {showCommentsModal && (
          <CommentsAndDetails data={postData!} userData={userData!} setModal={setShowCommentsModal} />
        )}
        {showBuyModal && (
          <Modal setModal={setShowBuyModal} background={""}>
            <PostBuy setModal={setShowBuyModal} nft={nft!} post={postData!} />
          </Modal>
        )}
      </>
    );
  }
};

export default SinglePost;
