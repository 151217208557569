import { NFTType } from "@/types";

export const GET_NFTs = "GET_NFTs";
export const GET_MORE_NFTs = "GET_MORE_NFTs";
export const SELECT_NFT_TO_DETAIL = "SELECT_NFT_TO_DETAIL";

export type NFTState = {
  didInvalidate: boolean;
  isFetching: boolean;
  isFetchingMore: boolean;
  nfts: NFTType[];
  cursor: string;
  noMoreNfts: boolean;
  nftSelected: number;
};

type GetNFTsFulfilledAction = {
  type: "GET_NFTs_FULFILLED";
  payload: { nfts: NFTType[]; cursor: string };
};
type GetMoreNFTsFulfilledAction = {
  type: "GET_MORE_NFTs_FULFILLED";
  payload: { nfts: NFTType[]; cursor: string };
};

type SelectNFTDetailAction = {
  type: "SELECT_NFT_TO_DETAIL";
  payload: number;
};

export type NFTAction = GetNFTsFulfilledAction | GetMoreNFTsFulfilledAction | SelectNFTDetailAction;
