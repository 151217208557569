import { ReactNode, createContext, useContext, useState } from "react";
import { useEffect } from "react";

import { postImage } from "@/assets/images/image";

type ContextProviderProps = {
  children: ReactNode;
};

type ContextDefault = {
  selectedProfilePicture: string;
  setSelectedProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  existingUserName: string[];
  setExistingUserName: React.Dispatch<React.SetStateAction<string[]>>;
  posts: any[];
  cancelUploading: boolean;
  setCancelUploading: React.Dispatch<React.SetStateAction<boolean>>;
  uploadingProgress: number;
  setUploadingProgress: React.Dispatch<React.SetStateAction<number>>;
  showConnectWalletModal: boolean;
  setShowConnectWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
  referrer: string;
};

const StateData = createContext<ContextDefault>({
  selectedProfilePicture: "",
  setSelectedProfilePicture: () => {},
  existingUserName: [],
  setExistingUserName: () => {},
  posts: [],
  cancelUploading: true,
  setCancelUploading: () => {},
  uploadingProgress: 40,
  setUploadingProgress: () => {},
  showConnectWalletModal: false,
  setShowConnectWalletModal: () => {},
  referrer: "",
});

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const [selectedProfilePicture, setSelectedProfilePicture] = useState("");
  const [existingUserName, setExistingUserName] = useState<string[]>(["juanbautista.eth", "edumannarino"]);
  const [cancelUploading, setCancelUploading] = useState(true);
  const [uploadingProgress, setUploadingProgress] = useState(40);
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const [referrer, setReferrer] = useState<string>("");
  const posts = [
    {
      id: 7789,
      name: "Moonbird",
      userName: "juanbautista.eth",
      address: "0xb4...3bbb",
      postImage: postImage,
      likes: 3000,
      comments: 200,

      postText: "Look at my new NFT!",
      time: "4h",
      author: true,
    },
    {
      id: 7789,
      name: "Moonbird",
      userName: "juanbautista.eth",
      address: "0xb4...3bbb",
      postImage: postImage,
      likes: 3000,
      comments: 200,

      postText: "Look at my new NFT!",
      time: "4h",
      ethAmount: 0.038,
    },
  ];

  useEffect(() => {
    // if ref in url params then save it
    const check = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ref = urlParams.get("ref");
      if (ref) {
        setReferrer(ref);
        console.log(ref);
      }
    };
    check().catch(console.error);
  }, []);

  return (
    <StateData.Provider
      value={{
        selectedProfilePicture,
        setSelectedProfilePicture,
        existingUserName,
        setExistingUserName,
        posts,
        cancelUploading,
        setCancelUploading,
        uploadingProgress,
        setUploadingProgress,
        showConnectWalletModal,
        setShowConnectWalletModal,
        referrer,
      }}
    >
      {children}
    </StateData.Provider>
  );
};
export const useGeneralContext = () => useContext(StateData);
