import {
  DocumentSnapshot,
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  /*increment,*/
  updateDoc,
  where,
} from "firebase/firestore";

import { GetUserFromDb } from "@/Database/User";
import { createCollection } from "@/firebase";
import { MetadataType } from "@/types";

import { CommentDocInterface, PostDocInterface, UpdateablePost } from "./Post.definition";

const TABLE_NAME = "Post";
export const PostCollection = createCollection<PostDocInterface>(TABLE_NAME);

const COMMENTS_TABLE_NAME = "Comment";
export const CommentCollection = createCollection<CommentDocInterface>(COMMENTS_TABLE_NAME);

export interface iCreatePost {
  userId: string;
  tokenAddress: string;
  tokenId: string;
  text: string;
  imageUrl: string;
  metadata: MetadataType;
}

export const CreatePost = async (postData: iCreatePost): Promise<PostDocInterface> => {
  const newPostData: PostDocInterface = {
    userId: postData.userId,
    tokenAddress: postData.tokenAddress,
    tokenId: postData.tokenId,
    commentsCount: 0,
    text: postData.text,
    imageUrl: postData.imageUrl,
    timestamp: Date.now(),
    comments: [],
    likes: [],
    mtd: postData.metadata,
    deleted: false,
  };

  const newPostRef = await addDoc(PostCollection, newPostData);
  const newPostDoc = newPostRef.id;

  await updateDoc(newPostRef, { ...newPostData, id: newPostDoc });

  return {
    id: newPostDoc,
    userId: postData.userId,
    tokenAddress: postData.tokenAddress,
    tokenId: postData.tokenId,
    text: postData.text,
    imageUrl: postData.imageUrl,
    timestamp: Date.now(),
    comments: [],
    likes: [],
    commentsCount: 0,
    mtd: postData.metadata,
    deleted: false,
  };
};

export const UpdatePost = async (postData: UpdateablePost): Promise<void> => {
  if (!postData.id) return;
  const postRef = doc(PostCollection, postData.id);
  await setDoc<PostDocInterface>(postRef, { ...postData }, { merge: true });
};

export const GetPostById = async (postId: string): Promise<PostDocInterface | null> => {
  const postRef = doc(PostCollection, postId);
  let post = await getDoc(postRef);

  if (post.exists()) {
    let data = post.data();
    if (data.deleted === false) return post.data() as PostDocInterface;
    else return null;
  } else {
    return null;
  }
};

export const GetLatestPosts = async (l = 10): Promise<PostDocInterface[]> => {
  const q = query(PostCollection, where("deleted", "==", false), orderBy("timestamp", "desc"), limit(l));
  const querySnapshot = await getDocs(q);
  const postRefs: DocumentSnapshot<PostDocInterface>[] = [];

  querySnapshot.forEach(doc => postRefs.push(doc));

  return postRefs.map(doc => {
    const postData = doc.data();
    return {
      id: doc.id,
      userId: postData?.userId || "",
      tokenAddress: postData?.tokenAddress || "",
      tokenId: postData?.tokenId || "",
      text: postData?.text || "",
      imageUrl: postData?.imageUrl || "",
      timestamp: postData?.timestamp || Date.now(),
      commentsCount: postData?.commentsCount || 0,
      likes: postData?.likes || [],
      comments: postData?.comments || [],
      mtd: postData!.mtd,
      deleted: postData!.deleted,
    };
  });
};

export const GetPosts = async (userId: string, lastPostId?: string, l = 10): Promise<PostDocInterface[]> => {
  const userDoc = await GetUserFromDb(userId);
  if (userDoc) {
    const following = userDoc.following || [];
    following.push(userId);
    const postRefs: DocumentSnapshot<PostDocInterface>[] = [];

    for (let i = 0; i < following.length; i += 10) {
      const batch = following.slice(i, i + 10);
      const q = query(
        PostCollection,
        where("userId", "in", batch),
        where("deleted", "==", false),
        orderBy("timestamp", "desc"),
        ...(lastPostId ? [startAfter(lastPostId)] : []),
        limit(l)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => postRefs.push(doc));
    }

    return postRefs.map(doc => {
      const postData = doc.data();
      return {
        id: doc.id,
        userId: postData?.userId || "",
        tokenAddress: postData?.tokenAddress || "",
        tokenId: postData?.tokenId || "",
        text: postData?.text || "",
        imageUrl: postData?.imageUrl || "",
        timestamp: postData?.timestamp || Date.now(),
        commentsCount: postData?.commentsCount || 0,
        likes: postData?.likes || [],
        comments: postData?.comments || [],
        mtd: postData!.mtd,
        deleted: postData!.deleted,
      };
    });
  }
  throw new Error("User Not found");
};

export const GetPostsByUser = async (userId: string) => {
  const r = await getDocs(
    query(PostCollection, where("userId", "==", userId), where("deleted", "==", false))
  );

  return r.docs.map(doc => {
    const postData = doc.data();
    return {
      id: doc.id,
      userId: postData?.userId || "",
      tokenAddress: postData?.tokenAddress || "",
      tokenId: postData?.tokenId || "",
      text: postData?.text || "",
      imageUrl: postData?.imageUrl || "",
      timestamp: postData?.timestamp || Date.now(),
      commentsCount: postData?.commentsCount || 0,
      likes: postData?.likes || [],
      comments: postData?.comments || [],
      mtd: postData!.mtd,
      deleted: postData!.deleted,
    };
  });
};

export const CreateComment = async (
  postId: string,
  userId: string,
  text: string
): Promise<CommentDocInterface> => {
  const commentRef = CommentCollection;

  const comment: CommentDocInterface = {
    postId,
    userId,
    text,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    likes: [],
  };

  let newComment = await addDoc(commentRef, comment);
  await updateDoc(newComment, { ...comment, id: newComment.id });

  //Update comment count for post
  const postRef = doc(PostCollection, postId);
  const postDoc = await getDoc(postRef);
  if (postDoc.exists()) {
    const post = postDoc.data();
    const newPost: PostDocInterface = {
      ...post,
      commentsCount: post.commentsCount + 1, // TODO, change it for INCREMENT!
    };
    //@ts-ignore
    await updateDoc(postRef, newPost);
  }

  //TODO CREATE NOTIFICATION!
  return comment;
};

export const GetCommentsFromPost = async (
  postId: string,
  cursor?: any,
  l = 10
): Promise<CommentDocInterface[]> => {
  const commentsRef = CommentCollection;
  const q = query(
    commentsRef,
    where("postId", "==", postId),
    orderBy("createdAt", "desc"),
    ...(cursor ? [startAfter(cursor)] : []),
    limit(l)
  );

  const querySnapshot = await getDocs(q);
  const comments = querySnapshot.docs.map(doc => {
    return doc.data();
  });

  return comments;
};

export const LikeComment = async (commentId: string, userId: string): Promise<void> => {
  const commentRef = doc(CommentCollection, commentId);
  const commentDoc = await getDoc(commentRef);

  if (commentDoc.exists()) {
    const comment = commentDoc.data();
    if (comment.likes.includes(userId)) {
      return;
    }
    const newComment: CommentDocInterface = {
      ...comment,
      likes: [...comment.likes, userId],
    };

    //@ts-ignore
    await updateDoc(commentRef, newComment);
  } else {
    throw new Error("Comment not found");
  }
};

export const LikePost = async (postId: string, userId: string): Promise<void> => {
  const postRef = doc(PostCollection, postId);
  const postDoc = await getDoc(postRef);
  if (postDoc.exists()) {
    const post = postDoc.data();
    if (post.likes.includes(userId)) {
      return;
    }
    const newPost: PostDocInterface = {
      ...post,
      likes: [...post.likes, userId],
    };
    //@ts-ignore
    await updateDoc(postRef, newPost);
  } else {
    throw new Error("Post not found");
  }
};

export const UnlikeComment = async (commentId: string, userId: string): Promise<void> => {
  const commentRef = doc(CommentCollection, commentId);
  const commentDoc = await getDoc(commentRef);

  if (commentDoc.exists()) {
    const comment = commentDoc.data();
    if (!comment.likes.includes(userId)) {
      return;
    }
    const updatedLikes = comment.likes.filter(f => f !== userId);
    const newComment: CommentDocInterface = {
      ...comment,
      likes: updatedLikes,
    };

    //@ts-ignore
    await updateDoc(commentRef, newComment);
  } else {
    throw new Error("Comment not found");
  }
};

export const UnlikePost = async (postId: string, userId: string): Promise<void> => {
  const postRef = doc(PostCollection, postId);
  const postDoc = await getDoc(postRef);
  if (postDoc.exists()) {
    const post = postDoc.data();

    if (!post.likes.includes(userId)) {
      return;
    }

    const updatedLikes = post.likes.filter(f => f !== userId);

    const newPost: PostDocInterface = {
      ...post,
      likes: updatedLikes,
    };
    //@ts-ignore
    await updateDoc(postRef, newPost);
  } else {
    throw new Error("Post not found");
  }
};
