import { useEffect, useState } from "react";

import { GetLatestPosts } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";

import SingleNft from "./SingleNft/SingleNft";

import styles from "./Nfts.module.css";

const NFTs = () => {
  const [latestPosts, setLatestPosts] = useState<PostDocInterface[]>();

  useEffect(() => {
    GetLatestPosts(25).then(p => {
      setLatestPosts(p);
    });
  }, []);

  return (
    <div className={`${styles.nfts} marginTop wrapper`}>
      {" "}
      <h3 className={styles.title}>Explore NFTs</h3>
      <div className={styles.wrapper}>
        {latestPosts && latestPosts.map(postData => <SingleNft postData={postData} key={postData.id} />)}
      </div>
    </div>
  );
};

export default NFTs;
