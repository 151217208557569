import { ethers } from "ethers";
import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";

import { eth } from "@/assets/images/image";
import Modal from "@/components/Modal/Modal";
import { PostType } from "@/types";

import DelistModal from "./CancelListingModal/CancelListingModal";
import EditInformation from "./EditInformation/EditInformation";

import styles from "./SinglePost.module.css";

type SinglePostProps = {
  post: PostType;
};

const SinglePost = ({ post }: SinglePostProps) => {
  const [moreButton, setMoreButton] = useState(false);
  const [cancelListingModal, setCancelListingModal] = useState(false);
  const [editInformationModal, setEditInformationModal] = useState(false);
  const handleDelist = () => {
    setCancelListingModal(true);
  };
  return (
    <div className={styles.post}>
      <img src={post.nft?.image} alt="#" className={styles.image} />{" "}
      <div className={styles.detailsContainer}>
        <div className={styles.header}>
          <Link to={`/post/${post.post.id}`} className={styles.id}>
            {" "}
            {post.nft?.metadata?.name ?? post.nft?.name ?? "No Name"}
          </Link>
          <BiDotsVerticalRounded className={styles.more} onClick={() => setMoreButton(prev => !prev)} />

          {/* moreButton */}
          {moreButton && (
            <div className={styles.moreButtonContainer}>
              <p
                className={styles.moreButton}
                onClick={() => {
                  setMoreButton(prev => !prev);
                  setEditInformationModal(prev => !prev);
                }}
              >
                Edit Price
              </p>{" "}
              <p
                className={styles.moreButton}
                onClick={() => {
                  setMoreButton(prev => !prev);
                  handleDelist();
                }}
              >
                Cancel Listing
              </p>
            </div>
          )}
        </div>
        <div className={styles.valueContainer}>
          <span className={styles.value}>{ethers.utils.formatEther(post.nft?.order?.price ?? "")}</span>
          <img src={eth} alt="#" className={styles.ethIcon} />
        </div>
        {/* <p className={styles.lastSale}>Las sale: {lastSale} ETH</p> */}
      </div>
      {cancelListingModal && <DelistModal nft={post.nft} setModal={setCancelListingModal} />}
      {editInformationModal && (
        <Modal setModal={setEditInformationModal} background={""}>
          <EditInformation nft={post.nft} setModal={setEditInformationModal} />
        </Modal>
      )}
    </div>
  );
};

export default SinglePost;
