import { ethers } from "ethers";
import { Timestamp } from "firebase/firestore";
import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { Link, useParams } from "react-router-dom";

import { CreateComment, LikePost, UnlikePost } from "@/Database/Posts";
import { CommentDocInterface } from "@/Database/Posts/Post.definition";
import { comment, ethIcon, love, lovefill, send } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";
import NftMore from "@/components/pages/Home/Posts/NftMore/NftMore";
import ShareWithModal from "@/components/pages/Home/Posts/ShareModal/ShareWithModal";
import { useAuth } from "@/context/AuthContext";
import { PostType } from "@/types";
import { formatTime, numberFormating } from "@/utils";

import Comment from "./Comment/Comment";

import styles from "./styles.module.css";

type CommentsAndDetailsProps = {
  post: PostType;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CommentsAndDetails = ({ post, setModal }: CommentsAndDetailsProps) => {
  const [postText, setPostText] = useState("");
  const [nftMore, setNftMore] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [posting, setPosting] = useState(false);
  const { currentUserData } = useAuth();
  const comments: CommentDocInterface[] = post.post.comments ?? [];
  const [like, setLike] = useState(post.post.likes.includes(currentUserData?.uuid ?? ""));
  const [likeCount, setLikeCount] = useState(post.post.likes.length ?? 0);

  const params = useParams();
  const username = params.username ?? "";
  const { usersData, mapUsernameToUuid } = useAuth();
  const { setShowConnectWalletModal } = useGeneralContext();

  const userId = mapUsernameToUuid?.get(username);
  const user = usersData?.get(userId!);

  const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!post.post?.id) return;
    setPosting(true);
    await CreateComment(post.post?.id, currentUserData?.uuid, postText);
    setPostText("");
    setPosting(false);
  };

  const handleLike = async () => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!post.post?.id) return;
    if (like) {
      setLike(false);
      setLikeCount(prev => prev - 1);
      await UnlikePost(post.post.id, currentUserData?.uuid);
    } else {
      setLike(true);
      setLikeCount(prev => prev + 1);
      await LikePost(post.post.id, currentUserData?.uuid);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <img src={post.nft?.image} alt="#" className={styles.postImage} />
        <div className={styles.postDetails}>
          <div className={styles.header}>
            <div className={styles.userContainer}>
              {" "}
              <div className={styles.userImg}>
                <HexagonImage src={user?.avatarPhoto} background={""} />
              </div>
              <p className={styles.text}>{username}</p>
            </div>
            <BiDotsVerticalRounded className={styles.more} onClick={() => setNftMore(prev => !prev)} />
            {nftMore && (
              <NftMore setNftMore={setNftMore} author={false} nft={post.nft} postId={post.post.id} />
            )}
          </div>
          <div className={styles.informationContainer}>
            <div className={styles.information}>
              <div className={styles.idAndbutton}>
                <p className={styles.text}>{`${post.nft?.metadata?.name ?? post.nft?.name ?? "No Name"} `}</p>
                <Link to={`/post/${post.post.id}`} className={styles.details}>
                  Detail
                </Link>
              </div>
              <div className={styles.aboutPost}>
                <p className={styles.text}>{username} </p>
                <p className={`${styles.text} ${styles.subText}`}>
                  {post.post.text}
                  <span className={styles.time}>
                    {formatTime(new Timestamp(post.post.timestamp! / 1000, 0))}
                  </span>
                </p>
              </div>
            </div>

            {post.nft?.order?.price && (
              <div className={styles.ethContainer}>
                <span className={styles.eth}>{ethers.utils.formatEther(post.nft?.order?.price ?? "0")}</span>
                <img src={ethIcon} alt="#" className={styles.ethIcon} />
              </div>
            )}
          </div>
          <div className={`${styles.commentsWrapper}  grScrollbar`}>
            {comments.map((el, i) => (
              <Comment comment={el} key={i} />
            ))}
          </div>{" "}
          <div className={styles.likeCommentSend}>
            <div className={styles.like}>
              <img src={like ? lovefill : love} alt="#" className={styles.icon} onClick={handleLike} />

              <span className={styles.number}>{numberFormating(likeCount)}</span>
            </div>
            <div className={styles.comment}>
              <img src={comment} alt="#" className={styles.icon} />
              <span className={styles.number}>{numberFormating(comments.length ?? 0)}</span>
            </div>
            {currentUserData && (
              <img src={send} alt="#" className={styles.icon} onClick={() => setShareModal(prev => !prev)} />
            )}
          </div>
          <form className={styles.postInputContainer}>
            <input
              type="text"
              placeholder="Add new comment..."
              className={styles.postInput}
              name="post"
              value={postText}
              onChange={e => setPostText(e.target.value)}
            />
            <button type="submit" className={styles.postButton} onClick={handlePost}>
              <div className={styles.buttonLoader}>
                {posting && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span style={{ paddingLeft: posting ? "10px" : "0px" }}>Post</span>
              </div>
            </button>
          </form>
        </div>
      </div>
      <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
      {shareModal && <ShareWithModal setModal={setShareModal} nft={post.nft!} postId={post.post.id!} />}
      <div className={styles.overlay} onClick={() => setModal(false)}></div>
    </>
  );
};

export default CommentsAndDetails;
