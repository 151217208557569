import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { BiLogOut, BiUser } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";

import { getExperienceFromUser } from "@/Database/User";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { getBalance as getCardanoBalance } from "@/services/blockfrost";
import { getBalance } from "@/services/moralis";
import { formatShortAddress, getBlockchain, getStakeAddress, getTokenSymbol, lovelaceToAda } from "@/utils";

import styles from "./styles.module.css";

type ProfileSettingProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileSetting = ({ setModal }: ProfileSettingProps) => {
  const { currentUserData, Logout } = useAuth();
  const [balance, setBalance] = useState("");
  const [xpBalance, setXpBalance] = useState(0);

  const blockchain = getBlockchain(currentUserData?.address);
  const tokenSymbol = getTokenSymbol(blockchain);

  useEffect(() => {
    if (!currentUserData || !currentUserData.address) return;

    getExperienceFromUser(currentUserData.uuid).then(setXpBalance);

    if (blockchain === "CARDANO") {
      getStakeAddress(currentUserData.address)
        .then(stakeAddress => getCardanoBalance(stakeAddress))
        .then(balance => {
          const adaBalance = lovelaceToAda(balance).toFixed(4);
          setBalance(adaBalance);
        });
    } else {
      getBalance(currentUserData.address, blockchain).then(currentBalance => {
        const balanceInEthers = ethers.utils.formatEther(currentBalance);
        const balanceInEthersWithDecimals = (+balanceInEthers).toFixed(4);
        setBalance(balanceInEthersWithDecimals);
      });
    }
  }, [currentUserData, blockchain]);

  const handleLogOut = () => {
    Logout();
    setModal(false);
  };

  return (
    <div className={`${styles.profileSettings} marginTop`}>
      <div className={styles.header}>
        <div className={styles.userImage}>
          <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
        </div>
        <p className={styles.userName}>{currentUserData?.username}</p>
      </div>
      <div className={styles.balanceAndAddress}>
        <div className={styles.balanceContainer}>
          <p className={styles.balanceText}>Wallet Balance</p>
          <p className={styles.balance}>{balance.length > 0 ? `${balance} ${tokenSymbol}` : "..."}</p>
          <p className={`${styles.balance} ${styles.xpBalance}`}>{xpBalance} XP</p>
        </div>
        <p className={`${styles.address} ${styles.text}`}>{formatShortAddress(currentUserData?.address)}</p>
      </div>
      <div className={styles.itemContainer}>
        <Link to="/profile" className={styles.iconAndItem} onClick={() => setModal(false)}>
          <BiUser className={styles.icon} />
          <p className={styles.text}>Profile</p>
        </Link>
        <Link to="/editProfile" className={styles.iconAndItem} onClick={() => setModal(false)}>
          <FiSettings className={styles.icon} />
          <p className={styles.text}>Settings</p>
        </Link>
        <div className={styles.iconAndItem} onClick={handleLogOut}>
          <BiLogOut className={styles.icon} />
          <p className={styles.text}>Log out</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
