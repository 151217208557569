import { imagePlaceholder } from "@/assets/images/image";
import { MutualCollectionType } from "@/types";

import styles from "./MutualCollection.module.css";

type MutualCollectionModalProps = {
  mutualCollections: MutualCollectionType[] | undefined | null;
  setMutualCollectionModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const MutualCollectionModal = ({
  mutualCollections,
  setMutualCollectionModal,
}: MutualCollectionModalProps) => {
  return (
    <>
      <div onClick={() => setMutualCollectionModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>Mutual collections</h3>
          <svg
            onClick={() => setMutualCollectionModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>

        <div className={`${styles.suggestionDiv} grScrollbar`}>
          {mutualCollections &&
            mutualCollections.map((elem, idx) => {
              return (
                <div key={idx + new Date().getTime() + "suggest"} className={styles.suggestedPerson}>
                  <div className={styles.leftSuggest}>
                    <div className={styles.groupImages}>
                      <div className={styles.groupImage}>
                        <img
                          src={elem.image}
                          alt="#"
                          onError={e => {
                            e.currentTarget.src = imagePlaceholder;
                          }}
                          className={styles.userImage}
                        />
                      </div>
                    </div>

                    <p>{elem.name ?? "(No Name)"}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default MutualCollectionModal;
