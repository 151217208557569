import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";

import { NotificationCollection } from "@/Database/Notification";
import { NotificationDocInterface } from "@/Database/Notification/Notification.definition";
import { UserDocInterface } from "@/Database/User/User.definition";
import { logo, messenger, notification } from "@/assets/images/image";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Sidebar from "@/components/Sidebar/Sidebar";
import ConnectWalletButton from "@/components/WhenNotLogin/ConnectWalletButton/ConnectWalletButton";
import { useAuth } from "@/context/AuthContext";

import Notifications from "./Notification/Notification";
import ProfileSetting from "./ProfileSettings/ProfileSetting";

import styles from "./Header.module.css";
import searchStyles from "./search.module.css";

const Header = () => {
  const { currentUserData, usersData } = useAuth();
  const navigate = useNavigate();

  const [showSidebar, setShowSidebar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredCollection, setFilteredCollections] = useState<CollectionType[]>([]);
  const [filteredAccount, setFilteredAccounts] = useState<UserDocInterface[]>([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfileSetting, setShowProfileSetting] = useState(false);
  const [notifications, setNotifications] = useState<NotificationDocInterface[]>([]);

  useEffect(() => {
    const getNotifications = async () => {
      if (!currentUserData) return;

      if (!currentUserData.notifications || currentUserData.notifications.length === 0) return;

      const q = query(NotificationCollection, where(documentId(), "in", currentUserData.notifications));
      const unsuscribe = onSnapshot(q, querySnapshot => {
        let newNotifications: NotificationDocInterface[] = [];
        querySnapshot.forEach(doc => {
          newNotifications.push(doc.data());
        });
        setNotifications(newNotifications);
      });
      return () => unsuscribe;
    };

    getNotifications();
  }, [currentUserData]);

  // const collections: CollectionType[] = [
  //   { img: moonbirds, name: "Moonbirds", items: 10000, price: 7.62 },
  //   { img: moonbirds, name: "Boonbirds", items: 10000, price: 7.62 },
  //   { img: moonbirds, name: "Moonbirds", items: 10000, price: 7.62 },
  //   { img: moonbirds, name: "Boonbirds", items: 10000, price: 7.62 },
  //   { img: moonbirds, name: "Foonbirds", items: 10000, price: 7.62 },
  // ];

  const filterData = (query: string) => {
    // const filteredcollections = collections.filter((item: CollectionType) => {
    //   return item.name.toLowerCase().includes(query.toLowerCase());
    // });
    // setFilteredCollections(filteredcollections);

    if (usersData) {
      const usersArray = Array.from(usersData, entry => entry[1]);
      const filteredAccounts = usersArray.filter(e => {
        return e.username?.includes(query.toLowerCase());
      });
      setFilteredAccounts(filteredAccounts);
    }
  };

  return (
    <>
      <section className={`${styles.header} container`}>
        <a className={styles.logoContainer} href="/">
          <img src={logo} alt="#" className={styles.logo} />
          <div className={styles.betaBadge}>BETA</div>
        </a>
        <div className={styles.searchContainer}>
          <div className={styles.inputContainer}>
            <FiSearch className={styles.search} />
            <input
              type="text"
              key={"searchInput"}
              // placeholder="Search items, collections, and accounts"
              placeholder="Search accounts"
              className={styles.input}
              value={searchValue}
              onChange={e => {
                setSearchQuery(e.target.value);
                setSearchValue(e.target.value);
                filterData(e.target.value);
              }}
            />
            {searchQuery && (
              <div className={searchStyles.searchingContainer}>
                {" "}
                {filteredAccount && <h2 className={searchStyles.searchTitle}>Accounts</h2>}
                {filteredAccount.map((item, i) => (
                  <div
                    className={searchStyles.details}
                    key={i}
                    onClick={() => {
                      setSearchValue("");
                      setSearchQuery("");
                      navigate(`/userProfile/${item.username}`);
                    }}
                  >
                    <div className={`${searchStyles.imgAndName} `} key={i}>
                      <div className={searchStyles.moonBirds}>
                        <HexagonImage src={item.avatarPhoto} background={""} />
                      </div>

                      <p className={searchStyles.name}>{item.username}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {currentUserData ? (
          <div className={styles.userProfile}>
            <p className={`${notifications.some(e => !e.read) && styles.notification}`}>
              {" "}
              <img
                src={notification}
                alt=""
                className={`${styles.icon} `}
                onClick={() => {
                  setShowNotification(prev => !prev);
                  setShowProfileSetting(false);
                }}
              />
            </p>
            <Link
              to={"/messages"}
              className={styles.icon}
              onClick={() => {
                setShowNotification(false);
                setShowProfileSetting(false);
              }}
            >
              <img src={messenger} alt="" />
            </Link>
            <div className={styles.userContainer}>
              <div className={styles.userContainer}>
                <div className={styles.userImage}>
                  <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
                </div>

                {showProfileSetting ? (
                  <BsChevronUp
                    className={styles.arrow}
                    onClick={() => {
                      setShowProfileSetting(prev => !prev);
                      setShowNotification(false);
                    }}
                  />
                ) : (
                  <BsChevronDown
                    className={styles.arrow}
                    onClick={() => setShowProfileSetting(prev => !prev)}
                  />
                )}
              </div>
            </div>
            {showSidebar ? (
              <RiCloseLine
                color="#fafafa"
                className={styles.close}
                onClick={() => setShowSidebar(prev => !prev)}
              />
            ) : (
              <RxHamburgerMenu className={styles.hamburger} onClick={() => setShowSidebar(prev => !prev)} />
            )}
          </div>
        ) : (
          <ConnectWalletButton />
        )}
      </section>
      {showNotification && <Notifications data={notifications} setShowNotification={setShowNotification} />}{" "}
      {showProfileSetting && <ProfileSetting setModal={setShowProfileSetting} />}
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </>
  );
};

export default Header;
