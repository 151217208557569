import { userIcon } from "@/assets/images/image";

import styles from "./styles.module.css";

type HexagonImageProps = {
  src: string | undefined;
  background: string | undefined;
};

const HexagonImage = ({ src, background }: HexagonImageProps) => {
  return (
    <img
      src={src}
      alt=""
      className={styles.hexagon}
      onError={e => {
        e.currentTarget.src = userIcon;
      }}
      style={{ backgroundColor: background }}
    />
  );
};

export default HexagonImage;
