import {
  /*useEffect, */
  useState,
} from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import styles from "./styles.module.css";

const Properties = ({ data }: any) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.heading}>Properties </p>
        {showDetails ? (
          <BsChevronUp className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        ) : (
          <BsChevronDown className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        )}
      </div>
      {showDetails && (
        <div className={styles.propertyContainer}>
          {data.map((elm: any, i: number) => {
            var keys = Object.keys(elm);
            var keyA = keys[0] || null;
            var title = keyA != null ? elm[keyA] : "";

            var keyB = keys[1] || null;
            var desc = keyB != null ? elm[keyB] : "";

            return (
              <div className={styles.propertyBox} key={i}>
                <p className={styles.address}>{title}</p> <p className={styles.text}>{desc as string}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Properties;
