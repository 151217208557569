import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getLeaderboard } from "@/Database/User";
import { UserDocInterface } from "@/Database/User/User.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";

import styles from "./Leaderboard.module.css";

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<UserDocInterface[]>([]);
  const [page] = useState(0);

  useEffect(() => {
    const usersPerPage = 50;

    getLeaderboard(usersPerPage, page).then(setLeaderboard);
  }, []);

  return (
    <div className="wrapper marginTop">
      <h1 className={styles.title}>Leaderboard</h1>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.rank}>Rank</div>
          <div className={styles.user}>User</div>
          <div className={styles.points}>XP Points</div>
        </div>
        <div className="tableItems">
          {!leaderboard.length ? (
            <Loader padding="70px 0" />
          ) : (
            leaderboard.map((user: UserDocInterface, userRank: number) => (
              <div className={styles.tableItem} key={user.uuid}>
                <div className={styles.rank}>{++userRank}</div>
                <div className={styles.user}>
                  <Link to={`/userProfile/${user.username}`} className={styles.userpic}>
                    <HexagonImage src={user.avatarPhoto} background={""} />{" "}
                  </Link>
                  <Link to={`/userProfile/${user.username}`}>{user.username}</Link>
                </div>
                <div className={styles.points}>{user.experience}</div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
