import styles from "./CheckBox.module.css";

type CheckBoxProps = {
  label: string;
  checked: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const CheckBox = ({ label, checked, onChange }: CheckBoxProps) => {
  return (
    <label className={styles.container}>
      <input type="checkbox" checked={checked} onChange={onChange} className={styles.myCheckBox} />
      <p className={styles.checkmark}></p>
      <p className={styles.text}>{label}</p>
    </label>
  );
};

export default CheckBox;
