import { Timestamp } from "firebase/firestore";

import { AddNotificationToDb } from "@/Database/Notification";
import { NotificationDocInterface } from "@/Database/Notification/Notification.definition";
import { AddUserToDb, GetUserFromDb } from "@/Database/User";
import { useAuth } from "@/context/AuthContext";

export const useUser = () => {
  const { currentUserData } = useAuth();

  const follow = async (uuid: string) => {
    const currentUserDataFromDB = await GetUserFromDb(currentUserData?.uuid!);
    const newFollowing = currentUserDataFromDB?.following ? currentUserDataFromDB?.following : [];
    if (!newFollowing.includes(uuid)) {
      newFollowing.push(uuid);
      const newCurrentUserData = {
        ...currentUserDataFromDB!,
        following: newFollowing,
      };
      await AddUserToDb(newCurrentUserData);

      const newNotification: NotificationDocInterface = {
        uuid: "",
        userId: uuid,
        friendId: currentUserData?.uuid!,
        type: "follow",
        createdAt: Timestamp.fromDate(new Date()),
        read: false,
      };
      await AddNotificationToDb(newNotification);
    }

    const userData = await GetUserFromDb(uuid);
    const newFollowers = userData?.followers ? userData?.followers : [];
    if (!newFollowers.includes(currentUserData?.uuid!)) {
      newFollowers.push(currentUserData?.uuid!);
      const newUserData = {
        ...userData!,
        followers: newFollowers,
      };
      await AddUserToDb(newUserData);
    }
  };

  const unfollow = async (uuid: string) => {
    const currentUserDataFromDB = await GetUserFromDb(currentUserData?.uuid!);
    const currentFollowing = currentUserDataFromDB?.following ? currentUserDataFromDB?.following : [];
    if (currentFollowing.includes(uuid)) {
      const newFollowing = currentFollowing.filter(id => id !== uuid);
      const newCurrentUserData = {
        ...currentUserDataFromDB!,
        following: newFollowing,
      };
      await AddUserToDb(newCurrentUserData);
    }

    const userData = await GetUserFromDb(uuid);
    const currentFollowers = userData?.followers ? userData?.followers : [];
    if (currentFollowers.includes(currentUserData?.uuid!)) {
      const newFollowers = currentFollowers.filter(id => id !== currentUserData?.uuid);
      const newUserData = {
        ...userData!,
        followers: newFollowers,
      };
      await AddUserToDb(newUserData);
    }
  };

  return {
    follow,
    unfollow,
  };
};
