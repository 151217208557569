import { Timestamp } from "firebase/firestore";

import { CommentDocInterface } from "@/Database/Posts/Post.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { formatTime } from "@/utils";

import styles from "./styles.module.css";

type CommentProps = {
  comment: CommentDocInterface;
};

const Comment = ({ comment }: CommentProps) => {
  // const [like, setLike] = useState(false);
  const { usersData } = useAuth();

  return (
    <div className={styles.commentContainer}>
      <div className={styles.aboutPost}>
        <div className={styles.userImage}>
          <HexagonImage src={usersData?.get(comment.userId)?.avatarPhoto} background={""} />
        </div>

        <div>
          <div className={`${styles.commentAndUserName} `}>
            <p className={styles.text}>{usersData?.get(comment.userId)?.username} </p>
            <p className={`${styles.text} ${styles.comment}`}>{comment.text} &nbsp;</p>
          </div>
          <div className={styles.timeAndReply}>
            <p className={styles.time}>{formatTime(new Timestamp(comment.createdAt / 1000, 0))}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
