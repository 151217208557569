import { useEffect, useState } from "react";

import { UserDocInterface } from "@/Database/User/User.definition";
import { useAuth } from "@/context/AuthContext";

import SingleUser from "./SingleUser/SingleUser";

import styles from "./FindFriends.module.css";

const FindFriends = () => {
  const [latestUsers, setLatestUsers] = useState<UserDocInterface[]>();
  const { usersData, currentUserData } = useAuth();

  useEffect(() => {
    let users: UserDocInterface[] = [];

    usersData?.forEach(user => {
      if (user.uuid !== currentUserData?.uuid && !currentUserData?.following?.includes(user.uuid)) {
        users.push(user);
      }
    });

    setLatestUsers(users);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.findFriendsContainer}>
      <div className={`marginTop wrapper`}>
        <h3 className={styles.title}>Find Friends</h3>
        <div className={`${styles.wrapper} `}>
          {latestUsers &&
            latestUsers.map((el, i) =>
              i <= 25 ? <SingleUser userData={el} key={i} /> : <div key={i}> </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FindFriends;
