import { ReactNode } from "react";

import styles from "./styles.module.css";

type ModalProps = {
  children: ReactNode;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  background: string;
};

const Modal = ({ children, setModal, background }: ModalProps) => {
  return (
    <>
      <div className={styles.modal}>{children}</div>
      <div
        className={styles.overlay}
        style={{ background: background }}
        onClick={() => setModal((prev: boolean) => !prev)}
      ></div>
    </>
  );
};

export default Modal;
