import { useAuth } from "@/context/AuthContext";
import { PostType } from "@/types";

import SinglePost from "./SinglePost/SinglePost";

import styles from "./styles.module.css";

const NftPosts = () => {
  const { myPosts } = useAuth();

  return (
    <div className={styles.posts}>
      {!myPosts?.length
        ? "No Posts Yet"
        : myPosts.map((el: PostType, i: number) => <SinglePost post={el} key={i} />)}
    </div>
  );
};

export default NftPosts;
