import { useState } from "react";

import { UserDocInterface } from "@/Database/User/User.definition";
import { bannerDefault } from "@/assets/images/image";
import MutualFriendModal from "@/components/pages/UserProfile/MutualFriendModal/MutualFriendModal";

import styles from "./styles.module.css";

type BannerProps = {
  user: UserDocInterface;
  nftCount?: number;
};

const Banner = ({ user, nftCount }: BannerProps) => {
  const [followingModal, setFollowingModal] = useState(false);
  const [followersModal, setFollowersModal] = useState(false);
  const bannerPhoto = user?.bannerPhoto;

  return (
    <div className={styles.banner}>
      <img
        src={bannerPhoto ? bannerPhoto : bannerDefault}
        alt="#"
        className={styles.image}
        onError={e => {
          e.currentTarget.src = bannerDefault;
        }}
      />
      <div className={styles.nftAndFriends}>
        <p className={styles.infoBox}>
          {" "}
          <strong>{nftCount ?? "..."}</strong>
          <span className={styles.text}>NFTs</span>
        </p>{" "}
        <div className={`${styles.infoBox} ${styles.cursor}`} onClick={() => setFollowingModal(true)}>
          <strong>{user.following?.length ?? 0}</strong>

          <span className={styles.text}>Following</span>
        </div>{" "}
        <div className={`${styles.infoBox} ${styles.cursor}`} onClick={() => setFollowersModal(true)}>
          <strong>{user.followers?.length ?? 0}</strong>

          <span className={styles.text}>Followers</span>
        </div>{" "}
      </div>
      {followingModal && (
        <MutualFriendModal
          mutualFriends={user.following}
          setMutualFriendModal={setFollowingModal}
          title="Following"
        />
      )}
      {followersModal && (
        <MutualFriendModal
          mutualFriends={user.followers}
          setMutualFriendModal={setFollowersModal}
          title="Followers"
        />
      )}
    </div>
  );
};

export default Banner;
