import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { DisplayAddr } from "@/utils";

import styles from "./styles.module.css";

type DescriptionProps = {
  text: string;
  owner: string;
};

const Description = ({ text, owner }: DescriptionProps) => {
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={{ border: !showDetails ? "none" : "" }}>
        <p className={styles.heading}>Description </p>
        {showDetails ? (
          <BsChevronUp className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        ) : (
          <BsChevronDown className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        )}
      </div>

      {showDetails && (
        <div className={styles.infoContainer}>
          <p className={styles.text}>
            {" "}
            <span className={styles.title}>By</span>{" "}
            <span className={styles.address}>{DisplayAddr(owner)}</span>
          </p>
          <p className={styles.text}>{text}</p>
        </div>
      )}
    </div>
  );
};

export default Description;
