import { ethers } from "ethers";
import { useState } from "react";
import { Link } from "react-router-dom";

import { eth } from "@/assets/images/image";
import BuyNow from "@/components/pages/UserProfile/Posts/ForSale/BuyNow/BuyNow";
import { PostType } from "@/types";

import styles from "./SinglePost.module.css";

type SinglePostProps = {
  post: PostType;
};

const SinglePost = ({ post }: SinglePostProps) => {
  const [buyNowModal, setBuyNowModal] = useState(false);

  return (
    <div className={styles.post}>
      <img src={post.nft?.image} alt="#" className={styles.image} />{" "}
      <div className={styles.detailsContainer}>
        <div className={styles.header}>
          <Link to={`/post/${post.post.id}`} className={styles.id}>
            {" "}
            {post.nft?.name}
          </Link>
        </div>
        <div className={styles.valueContainer} onClick={() => setBuyNowModal(true)}>
          <span className={styles.value}>{ethers.utils.formatEther(post.nft?.order?.price ?? "")}</span>
          <img src={eth} alt="#" className={styles.ethIcon} />
        </div>
        {/* <p className={styles.lastSale}>Las sale: {lastSale} ETH</p> */}
      </div>
      {buyNowModal && <BuyNow post={post} setModal={setBuyNowModal} />}
    </div>
  );
};

export default SinglePost;
