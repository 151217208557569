import { ethers } from "ethers";

export const getOrdersByNFT = async (owner: string, tokenAddress: string, tokenId: string) => {
  try {
    if (!ethers.utils.isAddress(tokenAddress)) throw new Error("Invalid Address");
    const url = process.env.REACT_APP_ORDERBOOK_URL;

    const response = await fetch(`${url}?maker=${owner}&nftToken=${tokenAddress}&nftTokenId=${tokenId}`);
    const { orders } = await response.json();
    if (orders.length > 0) return orders[0];
    return null;
  } catch (error: any) {
    console.log("Error on: getOrdersByNFT");
    throw error;
  }
};
