import axios from "axios";
// import { AllowedWalletList } from '../Database/AllowedWallets';
import { ethers } from "ethers";
import Moralis from "moralis";

import { GetPostsByUser } from "@/Database/Posts";
import { imagePlaceholder } from "@/assets/images/image";
import { config } from "@/config";
import { MetadataType, MutualCollectionType, NFTType } from "@/types";
import { resolveIpfsLink } from "@/utils";

import { getOrdersByNFT } from "./0xprotocol";

export const getBalance = async (address: string, chain: string) => {
  if (process.env.REACT_APP_QUERY_MORALIS === "FALSE") return "0";

  if (!Moralis.Core.isStarted)
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_KEY,
    });

  if (chain === "EVM") {
    const response = await Moralis.EvmApi.balance.getNativeBalance({
      address,
      chain: config.moralisNetwork,
    });
    return response.toJSON().balance;
  }
  if (chain === "SOLANA") {
    const response = await Moralis.SolApi.account.getBalance({
      address,
      network: config.moralisSolanaNetwork,
    });

    return ethers.utils.parseEther(response.toJSON().solana);
  }
  return 0;
};

export const getGatingAvailability = async (address: string, token: string): Promise<boolean> => {
  return true; // Removing TOKEN GATING FOR RELEASE!

  // const exclusionList = AllowedWalletList;

  // if(exclusionList.indexOf(address.toLowerCase()) > -1)
  //   return true;

  // //if (process.env.REACT_APP_QUERY_MORALIS === 'FALSE')
  // //  return false;

  // if(!Moralis.Core.isStarted)
  //   await Moralis.start({ apiKey: process.env.REACT_APP_MORALIS_KEY});

  // try{

  //   const response = await Moralis.EvmApi.nft.getWalletNFTs({
  //     chain: config.moralisNetwork,
  //     address: address,
  //     tokenAddresses: [ token ]
  //   });

  //   return response?.raw?.total! > 0 || false;
  // }catch(e){
  //   console.error(e);
  //   return false;
  // }
};

export const getCollections = async (address: string, chain?: string) => {
  if (chain === "SOLANA") return [] as Array<MutualCollectionType>;

  if (process.env.REACT_APP_QUERY_MORALIS === "FALSE") return [] as Array<MutualCollectionType>;

  const delay = parseInt(process.env.REACT_APP_MORALIS_DELAY ?? "0");
  if (!Moralis.Core.isStarted)
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_KEY,
    });

  let hasNextPage = true;
  let collections: Array<MutualCollectionType> = [];
  let cursor: string = "";
  while (hasNextPage) {
    const response = await Moralis.EvmApi.nft.getWalletNFTCollections({
      address,
      chain: config.moralisNetwork,
      cursor,
    });
    cursor = response.toJSON().cursor ?? "";
    hasNextPage = response.toJSON().cursor !== null;
    for (let e of response.result) {
      // This delay is for development only, when using a free plan of moralis.
      if (delay > 0) await new Promise(resolve => setTimeout(resolve, delay));

      const collection = e.toJSON();
      let image = "";
      const metadataResponse = await Moralis.EvmApi.nft.getContractNFTs({
        address: collection.tokenAddress,
        chain: config.moralisNetwork,
        limit: 1,
        disableTotal: true,
        normalizeMetadata: true,
        mediaItems: true,
      });
      const result = metadataResponse.toJSON().result;
      if (result && result.length > 0) {
        const metadata = result[0];
        if (metadata.normalized_metadata?.image) image = metadata.normalized_metadata?.image;
        else {
          if (metadata.metadata) {
            const parsedMetadata = JSON.parse(metadata.metadata);
            image = parsedMetadata.image;
          }
        }
      }
      image = image.replace("ipfs://", "https://dweb.link/ipfs/");
      collections.push({
        address: collection.tokenAddress,
        name: collection.name,
        image,
      });
    }
  }

  return collections;
};

export const getNftsMoralis = async (
  address: string,
  userId: string,
  tokenAddress?: string,
  tokenId?: string,
  skipOrders = false,
  skipPosts = false,
  currentChain?: string,
  cursor = "",
  limit = 500
) => {
  if (currentChain === "SOLANA") return await getNFTsSolana(address, userId, tokenAddress, cursor, limit);

  return await getNftsEVM(address, userId, tokenAddress, tokenId, skipOrders, skipPosts, cursor, limit);
};

export const getNftsEVM = async (
  address: string,
  userId: string,
  tokenAddress?: string,
  tokenId?: string,
  skipOrders?: boolean,
  skipPosts?: boolean,
  cursor?: string,
  limit?: number
) => {
  const delay = parseInt(process.env.REACT_APP_MORALIS_DELAY ?? "0");
  // This delay is for development only, when using a free plan of moralis.
  if (delay > 0) {
    await new Promise(resolve => setTimeout(resolve, delay));
  }

  const chain = process.env.REACT_APP_NETWORK === "GOERLI" ? "goerli" : "eth";
  let url = `https://deep-index.moralis.io/api/v2/${address}/nft?chain=${chain}`;
  // if (limit) {
  //   url += `&limit=${limit}`
  // }
  // if (cursor) {
  //   url += `&cursor=${cursor}`
  // }
  let payload = {};
  let method = "GET";

  if (tokenAddress && tokenId) {
    url = `https://deep-index.moralis.io/api/v2/nft/getMultipleNFTs?chain=${chain}`;
    payload = { tokens: [{ token_address: tokenAddress, token_id: tokenId }] };
    method = "POST";
  }

  let parsedNfts: NFTType[] = [];
  let NFTs;
  let nextCursor;
  try {
    const { data, status } = await axios(url, {
      headers: {
        accept: "application/json",
        "X-API-Key": process.env.REACT_APP_MORALIS_KEY,
      },
      data: payload,
      method,
    });
    if (status !== 200) return { nfts: parsedNfts };

    if (tokenAddress && tokenId) NFTs = data;
    else {
      NFTs = data.result;
      // nextCursor = data.cursor;
    }

    if (!NFTs || NFTs.length === 0 || !NFTs[0]) return { nfts: parsedNfts };
  } catch (error) {
    return { nfts: parsedNfts };
  }

  for (let element of NFTs) {
    let metadata: MetadataType;
    try {
      metadata = JSON.parse(element?.metadata ?? "");
    } catch {
      metadata = null;
    }
    const image = metadata?.image;
    const order =
      address && !skipOrders
        ? await getOrdersByNFT(address, element.token_address, element.token_id)
        : undefined;
    const posts = userId && !skipPosts ? await GetPostsByUser(userId) : [];
    parsedNfts.push({
      ...element,
      image: image ? resolveIpfsLink(image) : imagePlaceholder,
      metadata,
      owner_of: address ? address : element.owner_of,
      order: { nonce: order?.order.nonce, price: order?.order.erc20TokenAmount },
      post: posts.find(e => element.token_address === e.tokenAddress && element.token_id === e.tokenId),
    });
  }
  return { nfts: parsedNfts, cursor: nextCursor };
};

const getNFTsSolana = async (
  address: string,
  userId: string,
  tokenAddress?: string,
  cursor?: string,
  limit?: number
) => {
  if (!Moralis.Core.isStarted)
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_KEY,
    });

  const response = await Moralis.SolApi.account.getNFTs({
    network: "mainnet",
    address,
  });
  let nextCursor;
  let parsedNfts: NFTType[] = [];
  for (let item of response.raw) {
    if (tokenAddress && tokenAddress !== item.mint) continue;
    const response = await Moralis.SolApi.nft.getNFTMetadata({
      network: "mainnet",
      address: item.mint,
    });

    const metadataInfo = await axios.get(response.raw.metaplex.metadataUri);

    const metadata = {
      name: metadataInfo.data?.name,
      description: metadataInfo.data?.description,
      image: metadataInfo.data?.image,
      attributes: metadataInfo.data?.attributes,
    };

    const newItem = {
      token_address: item.mint,
      token_id: "",
      amount: "",
      owner_of: address ? address : "",
      token_hash: "",
      block_number_minted: "",
      block_number: "",
      contract_type: response.raw.standard,
      name: response.raw.name,
      symbol: response.raw.symbol,
      token_uri: response.raw.metaplex.metadataUri,
      metadata,
      last_token_uri_sync: null,
      last_metadata_sync: null,
      minter_address: "",
      possible_spam: false,
      image: metadataInfo.data?.image,
      order: null,
      post: null,
    };
    parsedNfts.push(newItem);
  }
  return { nfts: parsedNfts, cursor: nextCursor };
};
