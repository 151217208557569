import { formatEther } from "ethers/lib/utils";
import { MdOutlineClose } from "react-icons/md";

import { NFTType } from "@/types";
import { formatShortAddress } from "@/utils";

import styles from "./styles.module.css";

type DetailsProps = {
  nft: NFTType;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Details = ({ nft, setModal }: DetailsProps) => {
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalWrapper}>
          <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
          <img src={nft.image} alt="#" className={styles.image} />
          <div className={styles.details}>
            <h4 className={styles.nameId}>{nft.metadata?.name ?? nft.name ?? "No Name"}</h4>
            <p className={styles.name}>{nft.name}</p>
            <div className={styles.keyAndValueContainer}>
              {/* <div className={styles.keyAndValue}>
                <p className={styles.key}>By</p>
                <p className={styles.value}>{value}</p>
              </div> */}
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Owner</p>
                <p className={styles.value}>{formatShortAddress(nft.owner_of)}</p>
              </div>
              <div className={styles.keyAndValue}>
                <p className={styles.key}>Purchase price </p>
                <p className={styles.key}>
                  <span className={styles.ethValue}>
                    {formatEther(nft.order?.price ?? "0")}
                    {" ETH"}
                  </span>{" "}
                  {/* <span>${purchasePrice.usd}</span> */}
                </p>
              </div>
            </div>
            <button className={styles.button}>Buy Now</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
