import { getBlob, getStorage, ref } from "firebase/storage";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AddGroupToDb, markMessagesRead } from "@/Database/Message";
import { GroupDocInterface, UpdateableGroup } from "@/Database/Message/Group.definition";
import { removeGroupFromUser } from "@/Database/User";
import { imagePlaceholder } from "@/assets/images/image";
import HexagonImage from "@/components/Hexagon/Hexagon";
import AddMemberModal from "@/components/pages/Messages/AddMemberModal/AddMemberModal";
import GroupPhoto from "@/components/pages/Messages/GroupPhoto/GroupPhoto";
import Waveform from "@/components/pages/Messages/Waveform/Waveform";
import { useAuth } from "@/context/AuthContext";
import { MemberType, MessageType } from "@/types";

import styles from "./ChatRoom.module.css";

type ChatRoomProps = {
  isRecording: boolean;
  group: GroupDocInterface;
  groups: Map<string, GroupDocInterface> | null | undefined;
  setGroups: React.Dispatch<React.SetStateAction<Map<string, GroupDocInterface> | null | undefined>>;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatRoom = ({ isRecording, group, groups, setGroups, setShowInput }: ChatRoomProps) => {
  const { currentUserData, usersData } = useAuth();
  const isAdmin = currentUserData?.uuid === group?.admin;
  const isGroup = group?.members?.length > 2;
  const messageTo = !isGroup ? group?.members.find(e => e !== currentUserData?.uuid) : null;
  const messages = group.messages;
  const members: MemberType[] = useMemo(
    () =>
      group?.members
        .map(e => {
          const userData = usersData?.get(e);
          return {
            uuid: e,
            img: userData?.avatarPhoto,
            name: userData?.username! ?? "No Name",
            isAdmin: group.admin === e ?? false,
          };
        })
        .sort((a, b) => (a.isAdmin ? -1 : 1)) ?? [],
    [group, usersData]
  );

  const [groupSettingsToggle, setGroupSettingsToggle] = useState(false);
  const [groupName, setGroupName] = useState(group.name); //the displayed name
  const [toggleGroupName, setToggleGroupName] = useState(false); //toggles editable name
  const [editableName, setEditableName] = useState(group.name); //name in the textbox

  const [groupPhotoModalToggle, setGroupPhotoModalToggle] = useState(false);
  const [selectedGroupPhoto, setSelectedGroupPhoto] = useState(group.avatarPhoto);
  const [toggleAddMemberModal, setToggleAddMemberModal] = useState(false);
  const [groupDesc, setGroupDesc] = useState(group.description); //the displayed desc
  const [toggleGroupDesc, setToggleGroupDesc] = useState(false); //toggles editable desc
  const [editableDesc, setEditableDesc] = useState(group.description); //desc in the textbox
  const [enableExitGroupModal, setEnableExitGroupModal] = useState(false);
  const [enableDeleteGroupModal, setEnableDeleteGroupModal] = useState(false);
  const [enableRemoveMemberModal, setEnableRemoveMemberModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<MemberType | undefined | null>(null);
  const params = useParams();
  const id = params.id;

  const navigate = useNavigate();
  // scrolling to the bottom of the messages everytime a new message is typed
  useEffect(() => {
    const setting = document.querySelector(".settings");
    if (setting && groupSettingsToggle) {
      setting.scrollTo(0, 0);
    }
    setShowInput(!groupSettingsToggle);
  }, [groupSettingsToggle, setShowInput]);

  useEffect(() => {
    if (members && members.length === 2) setGroupSettingsToggle(false);
    if (members && members.length > 2) {
      setGroupName(group.name);
      setSelectedGroupPhoto(group.avatarPhoto);
      setGroupDesc(group.description);
    }

    if (currentUserData?.uuid) {
      if (group.messages?.length > 0) {
        const lastMessageDatetime = group.messages[group.messages.length - 1].sentAt;
        const lastMessageRead = group.readers?.find(e => e.uuid === currentUserData.uuid);
        if (!lastMessageRead || lastMessageRead.readUntil < lastMessageDatetime)
          markMessagesRead(group, currentUserData.uuid);
      }
    }
  }, [group, members, currentUserData]);

  const handleSave = () => {
    if (group.admin !== currentUserData?.uuid) return;
    const newGroup: UpdateableGroup = {
      uuid: group.uuid,
      name: groupName,
      description: groupDesc,
      avatarPhoto: selectedGroupPhoto,
    };
    AddGroupToDb(newGroup);
  };

  const removeMember = (uuid: string) => {
    if (group.admin !== currentUserData?.uuid || !group.members || !group.members.includes(uuid)) return;
    const newMembers = group.members.filter(e => e !== uuid);
    const newGroup: UpdateableGroup = {
      uuid: group.uuid,
      members: newMembers,
      admin: currentUserData?.uuid === uuid ? newMembers[0] : group.admin,
    };
    AddGroupToDb(newGroup);
    removeGroupFromUser(uuid, group.uuid);
  };

  const exitGroup = () => {
    if (!currentUserData || !group.members || group.members.length < 4) return;
    removeMember(currentUserData.uuid);
    navigate("/messages");
  };

  const deleteGroup = () => {
    if (group.admin !== currentUserData?.uuid) return;
    const newGroup: UpdateableGroup = {
      uuid: group.uuid,
      deleted: true,
    };
    AddGroupToDb(newGroup);
  };

  if (!groups || !group) return <></>;

  return (
    <>
      {enableExitGroupModal && (
        <ExitGroupModal group={group} exitGroup={exitGroup} setModal={setEnableExitGroupModal} />
      )}
      {enableDeleteGroupModal && (
        <DeleteGroupModal group={group} deleteGroup={deleteGroup} setModal={setEnableDeleteGroupModal} />
      )}
      {enableRemoveMemberModal && (
        <RemoveMemberModal
          memberToRemove={memberToRemove}
          removeMember={removeMember}
          setModal={setEnableRemoveMemberModal}
        />
      )}
      {groupPhotoModalToggle && (
        <GroupPhoto setModal={setGroupPhotoModalToggle} setSelectedGroupPhoto={setSelectedGroupPhoto} />
      )}
      {toggleAddMemberModal && (
        <AddMemberModal
          setModal={setToggleAddMemberModal}
          group={group}
          groups={groups}
          setGroups={setGroups}
        />
      )}

      <div className={styles.chatRoom}>
        {/* //chat header --------------------- */}
        <div
          onClick={() => {
            if (isGroup) setGroupSettingsToggle(true);
            else navigate(`/userProfile/${usersData?.get(messageTo!)?.username}`);
          }}
          className={styles.chatHeader}
        >
          {isGroup ? (
            group.avatarPhoto ? (
              <HexagonImage src={group.avatarPhoto} background={""} />
            ) : (
              <div className={styles.placeHolderImg}></div>
            )
          ) : (
            <HexagonImage src={usersData?.get(messageTo!)?.avatarPhoto} background={""} />
          )}
          <div className={styles.rightHeader}>
            <h3>{group.name}</h3>
            {/* <p>Online</p> */}
          </div>
        </div>

        {/* //chat header end --------------------- */}
        {groupSettingsToggle ? (
          //settings part ---------------
          <GroupSettings
            setGroupSettingsToggle={setGroupSettingsToggle}
            handleSave={handleSave}
            group={group}
            selectedGroupPhoto={selectedGroupPhoto}
            setSelectedGroupPhoto={setSelectedGroupPhoto}
            isAdmin={isAdmin}
            groupName={groupName}
            setGroupName={setGroupName}
            toggleGroupName={toggleGroupName}
            setToggleGroupName={setToggleGroupName}
            setEditableName={setEditableName}
            editableName={editableName}
            setGroupPhotoModalToggle={setGroupPhotoModalToggle}
            groupDesc={groupDesc}
            toggleGroupDesc={toggleGroupDesc}
            setEditableDesc={setEditableDesc}
            editableDesc={editableDesc}
            setToggleGroupDesc={setToggleGroupDesc}
            setGroupDesc={setGroupDesc}
            setToggleAddMemberModal={setToggleAddMemberModal}
            members={members}
            setEnableDeleteGroupModal={setEnableDeleteGroupModal}
            setEnableExitGroupModal={setEnableExitGroupModal}
            setMemberToRemove={setMemberToRemove}
            setEnableRemoveMemberModal={setEnableRemoveMemberModal}
          />
        ) : (
          //settings end --------------------
          <div className={`${styles.chatMsgs} chatMsgs grScrollbar`}>
            {id &&
              messages.map((elem: MessageType, idx: number, messages: MessageType[]) => {
                const messageDate = new Date(messages[idx].sentAt.seconds * 1000).toDateString();
                const lastMessageDate =
                  idx > 0 ? new Date(messages[idx - 1].sentAt.seconds * 1000).toDateString() : null;
                const showDate = idx === 0 ? true : messageDate !== lastMessageDate;
                const showName = idx === 0 ? true : messages[idx].sentBy !== messages[idx - 1].sentBy;
                return (
                  <ChatMsg
                    key={idx + new Date().getTime()}
                    message={elem}
                    showDate={showDate}
                    isGroup={isGroup}
                    showName={showName}
                  />
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ChatRoom, (prevProps, nextProps) => {
  return nextProps.isRecording;
});

type ChatMsgProps = {
  message: MessageType;
  showDate: boolean;
  isGroup: boolean;
  showName: boolean;
};

const ChatMsg = ({ message, showDate, isGroup, showName }: ChatMsgProps) => {
  const { currentUserData, usersData } = useAuth();
  const [fileBlob, setFileBlob] = useState<Blob | undefined | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFile = async () => {
      if (message.type === "audio") {
        setLoading(true);
        const storage = getStorage();
        const file = await getBlob(ref(storage, message.message));
        setFileBlob(file);
        setLoading(false);
      }
      if (message.type === "image") {
        setLoading(true);
        let file: string;
        try {
          const storage = getStorage();
          const fileBlob = await getBlob(ref(storage, message.message));
          file = URL.createObjectURL(fileBlob);
        } catch {
          file = message.message;
        }
        setFileUrl(file);
        setLoading(false);
      }

      // Must use a Timeout to wait the audio/media is loaded.
      // Then we the control is fully rendered we can scroll to bottom.
      const timeout = setTimeout(() => {
        const chatRoom = document.querySelector(".chatMsgs");
        if (chatRoom) {
          if (chatRoom.scrollTop + chatRoom.clientHeight < chatRoom.scrollHeight)
            chatRoom.scrollTop = chatRoom.scrollHeight;
        }
      }, 100);
      return () => clearTimeout(timeout);
    };
    getFile();
  }, [message]);

  if (!message) return <></>;

  const myMessage = currentUserData?.uuid === message.sentBy;
  const date = new Date(message.sentAt.seconds * 1000).toLocaleDateString();
  const time = new Date(message.sentAt.seconds * 1000).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
  const sender = showName && isGroup && !myMessage ? usersData?.get(message.sentBy)?.username : "";

  return (
    <>
      {showDate && (
        <div className={styles.msgDate}>
          <div>{date}</div>
        </div>
      )}

      <div className={`${myMessage ? styles.sentMsg : styles.recievedMsg}`}>
        {loading && (
          <div className={`${styles.chatMsgDiv} ${styles.loadingAudio}`}>
            <div>Loading media....</div>
          </div>
        )}
        {(!message?.type || message.type === "text") && (
          <div className={styles.chatMsgDiv}>
            {sender && <div className={styles.sender}>{sender}</div>}
            <div className={styles.message}>
              {message.message}
              <span>{time}</span>
            </div>
          </div>
        )}

        {message.type === "image" && fileUrl && (
          <div className={styles.chatImgDiv}>
            {message.link ? (
              <Link to={message.link}>
                <img src={fileUrl} className={styles.chatImg} alt="" />
              </Link>
            ) : (
              <img src={fileUrl} className={styles.chatImg} alt="" />
            )}
            <span>{time}</span>
          </div>
        )}

        {message.type === "audio" && fileBlob && (
          <div className={styles.chatMsgDiv}>
            <Waveform audio={fileBlob} />
            <span>{time}</span>
          </div>
        )}
      </div>
    </>
  );
};

type MemberProps = {
  group: GroupDocInterface;
  user: MemberType;
  isAdmin: boolean;
  setMemberToRemove: React.Dispatch<React.SetStateAction<MemberType | null | undefined>>;
  setEnableRemoveMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Member = ({ group, user, isAdmin, setMemberToRemove, setEnableRemoveMemberModal }: MemberProps) => {
  const navigate = useNavigate();
  const handleRemove = () => {
    setMemberToRemove(user);
    setEnableRemoveMemberModal(true);
  };
  return (
    <div className={styles.partcipant}>
      <div style={{ cursor: "pointer" }} onClick={() => navigate(`/userProfile/${user.name}`)}>
        <HexagonImage src={user.img} background={""} />
        <p>
          {user.name} {user.isAdmin && "(admin)"}
        </p>
      </div>
      {isAdmin && !user.isAdmin && group.members.length > 3 && <button onClick={handleRemove}>Remove</button>}
    </div>
  );
};

type ExitGroupModalProps = {
  group: GroupDocInterface;
  exitGroup: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExitGroupModal = ({ group, exitGroup, setModal }: ExitGroupModalProps) => {
  const handleExit = () => {
    exitGroup();
    setModal(false);
  };
  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>
          Are you sure you want to leave <br /> {group.name}?
        </p>
        <div className={styles.btnDiv}>
          <button className={styles.invertBtn} onClick={handleExit}>
            Yes
          </button>
          <button className={styles.button} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};

type DeleteGroupModalProps = {
  group: GroupDocInterface;
  deleteGroup: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteGroupModal = ({ group, deleteGroup, setModal }: DeleteGroupModalProps) => {
  const handleDelete = () => {
    deleteGroup();
    setModal(false);
  };
  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>
          Are you sure you want to delete <br /> {group.name}?
        </p>
        <div className={styles.btnDiv}>
          <button className={styles.invertBtn} onClick={handleDelete}>
            Yes
          </button>
          <button className={styles.button} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};

type RemoveMemberModalProps = {
  memberToRemove: MemberType | null | undefined;
  removeMember: (uuid: string) => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveMemberModal = ({ memberToRemove, removeMember, setModal }: RemoveMemberModalProps) => {
  if (!memberToRemove) return <></>;
  const handleRemove = () => {
    removeMember(memberToRemove.uuid);
    setModal(false);
  };
  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.smallModal}>
        <p>
          Are you sure you want to remove <br /> {memberToRemove.name}?
        </p>
        <div className={styles.btnDiv}>
          <button className={styles.invertBtn} onClick={handleRemove}>
            Yes
          </button>
          <button className={styles.button} onClick={() => setModal(false)}>
            No
          </button>
        </div>
      </div>
    </>
  );
};

type GroupSettingsProps = {
  setGroupSettingsToggle: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: () => void;
  group: GroupDocInterface;
  isAdmin: boolean;

  groupName: string;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  toggleGroupName: boolean;
  setToggleGroupName: React.Dispatch<React.SetStateAction<boolean>>;
  editableName: string;
  setEditableName: React.Dispatch<React.SetStateAction<string>>;

  selectedGroupPhoto: string;
  setSelectedGroupPhoto: React.Dispatch<React.SetStateAction<string>>;
  setGroupPhotoModalToggle: React.Dispatch<React.SetStateAction<boolean>>;

  groupDesc: string;
  setGroupDesc: React.Dispatch<React.SetStateAction<string>>;
  toggleGroupDesc: boolean;
  setToggleGroupDesc: React.Dispatch<React.SetStateAction<boolean>>;
  editableDesc: string;
  setEditableDesc: React.Dispatch<React.SetStateAction<string>>;

  setToggleAddMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
  members: MemberType[];
  setEnableDeleteGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEnableExitGroupModal: React.Dispatch<React.SetStateAction<boolean>>;

  setMemberToRemove: React.Dispatch<React.SetStateAction<MemberType | null | undefined>>;
  setEnableRemoveMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const GroupSettings = ({
  setGroupSettingsToggle,
  handleSave,
  group,
  isAdmin,

  groupName,
  setGroupName,
  toggleGroupName,
  setToggleGroupName,
  setEditableName,
  editableName,

  selectedGroupPhoto,
  setSelectedGroupPhoto,
  setGroupPhotoModalToggle,

  groupDesc,
  toggleGroupDesc,
  setEditableDesc,
  editableDesc,
  setToggleGroupDesc,
  setGroupDesc,
  setToggleAddMemberModal,
  members,
  setEnableDeleteGroupModal,
  setEnableExitGroupModal,
  setMemberToRemove,
  setEnableRemoveMemberModal,
}: GroupSettingsProps) => {
  const handleBack = () => {
    if (isAdmin) {
      setGroupName(group.name);
      setToggleGroupName(false);
      setEditableName(group.name);
      setSelectedGroupPhoto(group.avatarPhoto);
      setGroupDesc(group.description);
      setToggleGroupDesc(false);
      setEditableDesc(group.description);
    }
    setGroupSettingsToggle(false);
  };

  return (
    <div className={`${styles.settingDiv} grScrollbar settings`}>
      <div className={styles.backRow}>
        <svg
          onClick={handleBack}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
        </svg>
        {isAdmin && <button onClick={handleSave}>Save</button>}
      </div>
      <div className={styles.centerDetails}>
        <div className={styles.nameDisplayed}>
          {toggleGroupName ? (
            <input onChange={e => setEditableName(e.target.value)} value={editableName}></input>
          ) : (
            <p>{groupName}</p>
          )}
          {isAdmin ? (
            <button
              onClick={() => {
                if (toggleGroupName) {
                  setToggleGroupName(false);
                  setGroupName(editableName);
                  setEditableName("");
                } else {
                  setEditableName(groupName);
                  setToggleGroupName(true);
                }
              }}
            >
              {toggleGroupName ? "Update" : "Edit"} Group Name
            </button>
          ) : (
            ""
          )}
        </div>
        <div className={styles.groupPhotoDiv}>
          {selectedGroupPhoto ? (
            <img
              className={styles.groupPhoto}
              src={selectedGroupPhoto}
              onError={e => {
                e.currentTarget.src = imagePlaceholder;
              }}
              alt="group"
            />
          ) : (
            <div className={styles.groupPhoto}></div>
          )}
          {isAdmin ? (
            <button onClick={() => setGroupPhotoModalToggle(true)}>
              {selectedGroupPhoto ? "Change Group Photo" : "Add Group Photo"}
            </button>
          ) : (
            ""
          )}
        </div>

        {groupDesc ? (
          <div className={styles.descDisplayed}>
            {toggleGroupDesc ? (
              <textarea onChange={e => setEditableDesc(e.target.value)} value={editableDesc}></textarea>
            ) : (
              <p>{groupDesc}</p>
            )}
            {isAdmin ? (
              <button
                onClick={() => {
                  if (toggleGroupDesc) {
                    setToggleGroupDesc(false);
                    setGroupDesc(editableDesc);
                    setEditableDesc("");
                  } else {
                    setEditableDesc(groupDesc);
                    setToggleGroupDesc(true);
                  }
                }}
              >
                {toggleGroupDesc ? "Update" : "Edit"} Group Description
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            <button className={styles.desc} onClick={() => setToggleGroupDesc(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
              Group Description
            </button>
            {toggleGroupDesc && (
              <div className={styles.changeDesc}>
                <textarea onChange={e => setEditableDesc(e.target.value)} value={editableDesc}></textarea>
                <button
                  onClick={() => {
                    setGroupDesc(editableDesc);
                    setToggleGroupDesc(false);
                    setEditableDesc("");
                  }}
                >
                  Confirm
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.participantsDiv}>
        <div className={styles.partcipantsHead}>
          <p>Participants</p>
          {isAdmin && <button onClick={() => setToggleAddMemberModal(true)}>Add Member</button>}
        </div>
        {members.map((elem: MemberType, idx: number) => {
          return (
            <Member
              group={group}
              user={elem}
              isAdmin={isAdmin}
              key={idx + new Date().getTime() + "mem"}
              setMemberToRemove={setMemberToRemove}
              setEnableRemoveMemberModal={setEnableRemoveMemberModal}
            />
          );
        })}
      </div>

      <div className={styles.deleteBtn}>
        {isAdmin && <button onClick={() => setEnableDeleteGroupModal(true)}>Delete Group</button>}
      </div>
      <div className={styles.deleteBtn}>
        {group.members.length > 3 && (
          <button onClick={() => setEnableExitGroupModal(true)}>Exit Group</button>
        )}
      </div>
    </div>
  );
};
