import styles from "./Input.module.css";

type InputProps = {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  className?: string;
};

const Input = ({ label, type, name, placeholder, onChange, value, className = "" }: InputProps) => {
  return (
    <div className={styles.inputContainer}>
      <label htmlFor={name} className={`${styles.label} ${styles.text}`}>
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        className={`${styles.input} ${styles.text} ${className}`}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
