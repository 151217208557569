import { GET_MORE_NFTs, GET_NFTs, NFTAction, NFTState, SELECT_NFT_TO_DETAIL } from "@/redux/types/NFT";

const INITIAL_STATE: NFTState = {
  didInvalidate: false,
  isFetching: false,
  isFetchingMore: false,
  nfts: [],
  cursor: "",
  noMoreNfts: false,
  nftSelected: -1,
};

export const NFTReducer = (state = INITIAL_STATE, { type, payload }: NFTAction) => {
  switch (type) {
    case `${GET_NFTs}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
      };
    case `${GET_NFTs}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: true,
      };
    case `GET_NFTs_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        nfts: payload.nfts,
        noMoreNfts: !payload.cursor,
        cursor: payload.cursor,
      };
    case `${GET_MORE_NFTs}_REJECTED`:
      return {
        ...state,
        didInvalidate: true,
        isFetchingMore: false,
      };
    case `${GET_MORE_NFTs}_PENDING`:
      return {
        ...state,
        didInvalidate: false,
        isFetchingMore: true,
      };
    case `GET_MORE_NFTs_FULFILLED`:
      return {
        ...state,
        didInvalidate: false,
        isFetchingMore: false,
        nfts: [...state.nfts, ...payload.nfts],
        noMoreNfts: !payload.cursor,
        cursor: payload.cursor,
      };
    case SELECT_NFT_TO_DETAIL:
      return {
        ...state,
        nftSelected: payload,
      };
    default:
      return state;
  }
};
