import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddGroupToDb } from "@/Database/Message";
import { GroupDocInterface } from "@/Database/Message/Group.definition";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";
import { PeopleType, TagType } from "@/types";

import styles from "./NewMsgModal.module.css";

type NewMsgModalProps = {
  setOpenNewMsgModal: React.Dispatch<React.SetStateAction<boolean>>;
  groups: Map<string, GroupDocInterface> | null | undefined;
  setGroups: React.Dispatch<React.SetStateAction<Map<string, GroupDocInterface> | null | undefined>>;
};

const NewMsgModal = ({ setOpenNewMsgModal, groups, setGroups }: NewMsgModalProps) => {
  const { currentUserData, usersData } = useAuth();
  const [people, setPeople] = useState<TagType[]>([]);
  const [searchedPerson, setSearchedPerson] = useState("");
  const [tags, setTags] = useState<PeopleType[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setTags(people.filter(e => e.selected));
  }, [people]);

  useEffect(() => {
    if (currentUserData == null) return;
    if (!currentUserData.followers && !currentUserData.followers) return;

    const followers = currentUserData?.followers ? currentUserData.followers : [];
    const following = currentUserData?.following ? currentUserData.following : [];
    const followersfollowing = followers.concat(following).filter((e, i, a) => a.indexOf(e) === i);

    let available = Array<TagType>();

    for (let followerfollowing of followersfollowing) {
      if (usersData?.has(followerfollowing)) {
        let user = usersData.get(followerfollowing);
        available.push({ name: user!.username!, img: user!.avatarPhoto!, selected: false, uuid: user!.uuid });
      }
    }
    available.sort((a, b) => (a.name > b.name ? 1 : -1));

    setPeople(available);
  }, [currentUserData, usersData]);

  const addingPeople = (uuid: string) => {
    setPeople(prev => {
      const copy = [...prev];
      const idx = prev.findIndex(e => e.uuid === uuid);
      copy[idx].selected = !copy[idx].selected;
      return copy;
    });
  };
  const removeTag = (uuid: string) => {
    setPeople(prev => {
      const copy = [...prev];
      const idx = prev.findIndex(e => e.uuid === uuid);
      copy[idx].selected = false;
      return copy;
    });
  };

  const handleNewChat = async () => {
    if (tags.length < 1) return;

    if (tags.length === 1) {
      let existingGroup = false;
      groups?.forEach((value, key) => {
        if (
          !existingGroup &&
          value.members.length === 2 &&
          ((value.members[0] === currentUserData?.uuid && value.members[1] === tags[0].uuid) ||
            (value.members[0] === tags[0].uuid && value.members[1] === currentUserData?.uuid))
        ) {
          navigate(`/messages/${value.uuid}`);
          setOpenNewMsgModal(false);
          existingGroup = true;
        }
      });
      if (existingGroup) return;
    }

    const newGroup: GroupDocInterface = {
      uuid: "",
      name: tags.length > 1 ? "new Group" : tags[0].name!,
      description: "",
      createdAt: Timestamp.fromDate(new Date()),
      createdBy: currentUserData!.uuid,
      admin: currentUserData!.uuid,
      modifiedAt: Timestamp.fromDate(new Date()),
      deleted: false,
      members: [currentUserData!.uuid, ...tags.map(e => e.uuid)],
      avatarPhoto: "",
      typing: [],
      latestMsg: "",
      time: "",
      messages: [],
      readers: [],
    };
    const groupId = await AddGroupToDb(newGroup);
    setOpenNewMsgModal(false);
    navigate(`/messages/${groupId}`);
  };

  return (
    <>
      <div onClick={() => setOpenNewMsgModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>New Message</h3>
          <svg
            onClick={() => setOpenNewMsgModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className={styles.addGrid}>
          <p>To:</p>
          <div className={styles.addedPeople}>
            {tags.map((elem, idx) => {
              return (
                <TaggedPerson
                  key={idx + "tagged"}
                  name={elem.name!}
                  changeHandler={removeTag}
                  uuid={elem.uuid}
                />
              );
            })}

            <input
              value={searchedPerson}
              onChange={e => setSearchedPerson(e.target.value)}
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
        <div className={`${styles.suggestionDiv} grScrollbar`}>
          <h3>Suggestions</h3>
          {people
            .filter(elem => elem.name?.includes(searchedPerson))
            .map((elem, idx) => {
              return (
                <div
                  key={idx + new Date().getTime() + "suggest"}
                  className={styles.suggestedPerson}
                  onClick={() => {
                    addingPeople(elem.uuid);
                  }}
                >
                  <div className={styles.leftSuggest}>
                    <HexagonImage src={elem.img} background={""} />
                    <p>{elem.name}</p>
                  </div>
                  <button
                    style={{
                      backgroundColor: elem.selected ? "#41c6da" : "transparent",
                    }}
                    className={styles.checkBox}
                  >
                    {elem.selected && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="black"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    )}
                  </button>
                </div>
              );
            })}
        </div>
        <button className={styles.button} onClick={handleNewChat}>
          {tags.length > 1 ? "Create Group" : "Next"}
        </button>
      </div>
    </>
  );
};

export default NewMsgModal;

type TaggedPersonProps = {
  name: string;
  uuid: string;
  changeHandler: (uuid: string) => void;
};

const TaggedPerson = ({ name, uuid, changeHandler }: TaggedPersonProps) => {
  return (
    <div className={styles.taggedPerson}>
      <p>{name}</p>{" "}
      <svg
        onClick={() => changeHandler(uuid)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  );
};
