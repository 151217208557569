import { useState } from "react";

import { imagePlaceholder } from "@/assets/images/image";
// import PostDetails from "@/components/pages/UserProfile/Posts/PostDetails/PostDetails";
import { PostType } from "@/types";

import CommentsAndDetails from "./CommentsAndDetails/CommentsAndDetails";

import styles from "./SinglePost.module.css";

type SinglePostProps = {
  post: PostType;
};

const SinglePost = ({ post }: SinglePostProps) => {
  const [commentSectionModal, setCommentSectionModal] = useState(false);
  return (
    <>
      <div>
        {" "}
        <img
          src={post.nft?.image ?? imagePlaceholder}
          alt="#"
          className={styles.image}
          onError={e => {
            e.currentTarget.src = imagePlaceholder;
          }}
          onClick={() => setCommentSectionModal(prev => !prev)}
        />
        {commentSectionModal && <CommentsAndDetails post={post} setModal={setCommentSectionModal} />}
      </div>
    </>
  );
};

export default SinglePost;
