import { Timestamp, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddGroupToDb, getCurrentChat } from "@/Database/Message";
import { GroupDocInterface } from "@/Database/Message/Group.definition";
import { GetCommentsFromPost, PostCollection } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { UserDocInterface } from "@/Database/User/User.definition";
import { copy, imagePlaceholder } from "@/assets/images/image";
import Banner from "@/components/Banner/Banner";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { useUser } from "@/hooks";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import { getCollections } from "@/services/moralis";
import { MutualCollectionType, NFTType, PostType } from "@/types";
import { formatShortAddress, getBlockchain, resolveLink } from "@/utils";

import MutualCollectionModal from "./MutualCollectionModal/MutualCollectionModal";
import MutualFriendModal from "./MutualFriendModal/MutualFriendModal";
import ForSale from "./Posts/ForSale/ForSale";
import NftPosts from "./Posts/NftPost/NftPosts";

import styles from "./UserProfile.module.css";

const UserProfile = () => {
  const { currentUserData, usersData, mapUsernameToUuid, myCollections } = useAuth();
  const { setShowConnectWalletModal } = useGeneralContext();
  const { follow, unfollow } = useUser();

  const params = useParams();
  const username = params.username ?? "";

  const [userData, setUserData] = useState<UserDocInterface | undefined | null>(null);
  const [nftPosts, setNftPosts] = useState(true);
  const [followStatus, setFollowStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [mutualFollowing, setMutualFollowing] = useState<string[] | undefined | null>(null);
  const [mutualFriendModal, setMutualFriendModal] = useState(false);
  const [mutualCollections, setMutualCollections] = useState<MutualCollectionType[] | undefined | null>(null);
  const [mutualCollectionModal, setMutualCollectionModal] = useState(false);
  const [loadingMutualCollections, setLoadingMutualCollections] = useState(true);
  const [posts, setPosts] = useState<PostType[] | undefined | null>(null);
  const [nfts, setNfts] = useState<NFTType[] | undefined | null>(null);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingNfts, setLoadingNfts] = useState(true);
  const [postsCount, setPostsCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const userId = mapUsernameToUuid?.get(username);
    if (!userId) return;
    const q = query(PostCollection, where("userId", "==", userId), where("deleted", "==", false));
    getDocs(q).then(r => setPostsCount(r.docs.length));
  }, [mapUsernameToUuid, username]);

  useEffect(() => {
    const getUserNFTs = async () => {
      setLoadingNfts(true);
      const userId = mapUsernameToUuid?.get(username);
      const address = usersData?.get(mapUsernameToUuid?.get(username)!)?.address;
      if (!address || !userId) return;
      const blockchain = getBlockchain(address);
      if (blockchain === "CARDANO") {
        const { nfts } = await getNftsBlockfrost(address, userId, undefined, undefined, false, false);
        setNfts(nfts);
      } else {
        const { nfts } = await getNftsMoralis(
          address,
          userId,
          undefined,
          undefined,
          false,
          false,
          blockchain
        );
        setNfts(nfts);
      }
    };

    if (nfts === null || username !== userData?.username) {
      getUserNFTs().finally(() => setLoadingNfts(false));
    }
  }, [mapUsernameToUuid, username, usersData, nfts, userData]);

  useEffect(() => {
    const getUser = () => {
      if (!username || !mapUsernameToUuid) {
        return;
      }

      if (currentUserData?.username === username) {
        navigate("/profile");
        return;
      }

      const newUserData = usersData?.get(mapUsernameToUuid?.get(username)!);
      setUserData(newUserData);
      setLoading(false);

      if (!newUserData) return;

      setFollowStatus(currentUserData?.following?.includes(newUserData.uuid) ? "Unfollow" : "Follow");

      const newMutualFollowing = currentUserData
        ? currentUserData?.following?.filter(e => newUserData?.followers?.includes(e))
        : [];
      setMutualFollowing(newMutualFollowing);
    };

    const getUserCollections = async () => {
      const userAddress = usersData?.get(mapUsernameToUuid?.get(username)!)?.address;
      if (!userAddress || !currentUserData || !myCollections) return;
      const blockchain = getBlockchain(userAddress);
      let newMutualCollections: MutualCollectionType[];
      if (blockchain == "CARDANO") {
        // same as Solana? (no collections)
        newMutualCollections = [];
      } else {
        const userCollections: MutualCollectionType[] = await getCollections(userAddress, blockchain);
        newMutualCollections = currentUserData
          ? myCollections.filter(e => userCollections.find(e2 => e2.address === e.address))
          : [];
      }
      setMutualCollections(newMutualCollections);
    };

    const getPosts = async () => {
      if (loadingNfts) return;
      if (!mapUsernameToUuid || !username) return;
      const userId = mapUsernameToUuid?.get(username);
      if (!userId) return;
      const address = usersData?.get(userId)?.address;
      if (!address) return;

      const q = query(PostCollection, where("userId", "==", userId), where("deleted", "==", false));

      const unsuscribe = onSnapshot(q, async querySnapshot => {
        let userPosts: Array<PostDocInterface> = [];
        querySnapshot.forEach(async doc => {
          const postFromDB = doc.data();
          userPosts.push({ ...postFromDB, id: doc.id });
        });
        let newMyPosts: Array<PostType> = [];
        for (let userPost of userPosts) {
          const comments = await GetCommentsFromPost(userPost.id!);
          const post = { ...userPost, comments };
          let nft: NFTType | undefined;
          if (nfts) {
            nft = nfts.find(
              e => e.token_address === userPost.tokenAddress && e.token_id === userPost.tokenId
            );
          }
          if (!nft) {
            const blockchain = getBlockchain(address);
            if (blockchain === "CARDANO") {
              const { nfts: nftInfo } = await getNftsBlockfrost(
                address,
                "",
                post.tokenAddress,
                post.tokenId,
                false,
                false
              );
              nft = nftInfo[0];
            } else {
              const { nfts: nftInfo } = await getNftsMoralis(
                address,
                "",
                post.tokenAddress,
                post.tokenId,
                false,
                false,
                blockchain
              );
              nft = nftInfo[0];
            }
          }
          newMyPosts.push({ post, nft });
        }
        setPosts(newMyPosts);
        setLoadingPosts(false);
      });
      return () => unsuscribe;
    };
    if (mutualCollections === null || username !== userData?.username) {
      setLoadingMutualCollections(true);
      getUserCollections().finally(() => setLoadingMutualCollections(false));
    }

    if (posts === null || username !== userData?.username) {
      setLoadingPosts(true);
      getPosts();
    }
    getUser();
  }, [
    currentUserData,
    userData,
    navigate,
    username,
    usersData,
    mapUsernameToUuid,
    myCollections,
    nfts,
    loadingNfts,
    posts,
    mutualCollections,
  ]);

  if (loading) return <Loader />;

  if (!userData) {
    return (
      <div className={`${styles.actionContainer} marginTop wrapper`}>
        <div className={styles.profileHeader}>
          <h1 style={{ color: "#fafafa" }}>User not Found</h1>
        </div>
      </div>
    );
  }

  const handleFollowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!userData) return;
    setLoadingFollow(true);
    if (followStatus === "Follow") {
      follow(userData.uuid);
    }
    if (followStatus === "Unfollow") {
      unfollow(userData.uuid);
    }
    setLoadingFollow(false);
  };

  const handleMessageClick = async () => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    // At first an user can send a message to everyone
    if (!userData.username) return;

    setLoadingMessage(true);
    const group = await getCurrentChat(currentUserData.uuid, userData.uuid);
    if (group) {
      setLoadingMessage(false);
      navigate(`/messages/${group.uuid}`);
    } else {
      const newGroup: GroupDocInterface = {
        uuid: "",
        name: userData.username,
        description: "",
        createdAt: Timestamp.fromDate(new Date()),
        createdBy: currentUserData!.uuid,
        admin: currentUserData!.uuid,
        modifiedAt: Timestamp.fromDate(new Date()),
        deleted: false,
        members: [currentUserData!.uuid, userData.uuid],
        avatarPhoto: "",
        typing: [],
        latestMsg: "",
        time: "",
        messages: [],
        readers: [],
      };
      const groupId = await AddGroupToDb(newGroup);
      setLoadingMessage(false);
      navigate(`/messages/${groupId}`);
    }
  };

  return (
    <>
      <Banner user={userData} nftCount={postsCount} />
      <div className={`${styles.pageContainer} wrapper`}>
        <div className={styles.profileHeader}>
          <div className={styles.userInfo}>
            <div className={styles.user}>
              <HexagonImage src={userData?.avatarPhoto} background={""} />
            </div>
            <p className={styles.name}>{userData?.username}</p>
            <div className={styles.addressContainer}>
              <span className={`${styles.text} ${styles.address}`}>
                {formatShortAddress(userData?.address)}
              </span>
              <img
                src={copy}
                alt="#"
                className={styles.copyIcon}
                onClick={() => navigator.clipboard.writeText(userData?.address)}
              />
            </div>
            <p className={styles.text}>{userData?.bio}</p>
            <a
              href={resolveLink(userData?.link)}
              target="_blank"
              rel="noreferrer"
              className={`${styles.url} ${styles.text}`}
            >
              {userData?.link}
            </a>
          </div>
          <div className={styles.actionContainer}>
            {" "}
            <button className={`${styles.button} ${styles.activeButton}`} onClick={handleFollowClick}>
              <div className={styles.buttonLoader}>
                {loadingFollow && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span>{followStatus}</span>
              </div>
            </button>{" "}
            <button className={`${styles.button}`} onClick={handleMessageClick}>
              <div className={styles.buttonLoader}>
                {loadingMessage && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span>Message</span>
              </div>
            </button>
            <div className={styles.infoContainer}>
              <div className={styles.info} onClick={() => setMutualFriendModal(prev => !prev)}>
                <div className={styles.mutualImages}>
                  {mutualFollowing &&
                    mutualFollowing.slice(0, 3).map((e, i) => (
                      <div className={styles.mutualImage} key={i}>
                        <HexagonImage src={usersData?.get(e)?.avatarPhoto} background={""} />
                      </div>
                    ))}
                </div>
                <p className={styles.infoText}>{`${mutualFollowing?.length ?? 0} Followed By`}</p>
              </div>
              <div className={styles.info} onClick={() => setMutualCollectionModal(prev => !prev)}>
                {loadingMutualCollections ? (
                  <>
                    <Loader size={20} padding={"0px"} />{" "}
                    <span className={styles.infoText}>mutual collections</span>
                  </>
                ) : (
                  <>
                    <div className={[styles.mutualImages, styles.collectionImgages].join(" ")}>
                      {mutualCollections &&
                        mutualCollections?.slice(0, 3).map((e, i) => (
                          <img
                            key={i}
                            src={e.image}
                            alt="#"
                            onError={e => {
                              e.currentTarget.src = imagePlaceholder;
                            }}
                            className={[styles.mutualImage, styles.mutualCollection].join(" ")}
                          />
                        ))}
                    </div>
                    <span className={styles.infoText}>{`${
                      mutualCollections?.length ?? 0
                    } mutual collections`}</span>
                  </>
                )}
              </div>
            </div>
            {mutualFriendModal && (
              <MutualFriendModal
                mutualFriends={mutualFollowing}
                setMutualFriendModal={setMutualFriendModal}
                title={"Followed By"}
              />
            )}
            {mutualCollectionModal && (
              <MutualCollectionModal
                mutualCollections={mutualCollections}
                setMutualCollectionModal={setMutualCollectionModal}
              />
            )}
          </div>
        </div>
        <div className={styles.postTypesContainer}>
          <div className={styles.postTypes}>
            <div
              className={`${styles.postType} ${nftPosts && styles.activePostType}`}
              onClick={() => setNftPosts(true)}
            >
              <span className={styles.postTypeText}>NFT posts</span>
            </div>{" "}
            <div
              className={`${styles.postType} ${!nftPosts && styles.activePostType}`}
              onClick={() => setNftPosts(false)}
            >
              <span className={styles.postTypeText}>For sale</span>
            </div>
          </div>
          {/* {!nftPosts && (
            <button className={styles.button}>Visit marketplace</button>
          )} */}
        </div>{" "}
        {loadingPosts ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader />
          </div>
        ) : nftPosts ? (
          <NftPosts posts={posts} />
        ) : (
          <ForSale posts={posts?.filter(e => e.nft?.order?.price)} />
        )}
      </div>
    </>
  );
};

export default UserProfile;
