import { getAnalytics } from "@firebase/analytics";
import { connectAuthEmulator, getAuth, setPersistence } from "@firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions";
import { initializeApp } from "firebase/app";
import { browserSessionPersistence, inMemoryPersistence } from "firebase/auth";
import { CollectionReference, DocumentData, collection } from "firebase/firestore";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyACaZ1UTyIT5q-f3_dYHWHhbZ6q3nz5jLc",
  authDomain: "continent-firebase.firebaseapp.com",
  projectId: "continent-firebase",
  storageBucket: "continent-firebase.appspot.com",
  messagingSenderId: "759100266828",
  appId: "1:759100266828:web:5f9abbd91367bcd08cd7e9",
  measurementId: "G-H525LWY8MB",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const authPersistence =
  process.env.REACT_APP_AUTH_PERSISTENCE === "NONE" ? inMemoryPersistence : browserSessionPersistence;
setPersistence(auth, authPersistence);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>;
};

if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export default app;
