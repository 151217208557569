import { useState } from "react";

import { imagePlaceholder } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import { NFTType } from "@/types";

import Details from "./Details/Details";

import styles from "./styles.module.css";

type AllNftProps = {
  data: NFTType[];
  selectedNft: number;
  setSelectedNft: React.Dispatch<React.SetStateAction<number>>;
};

const AllNft = ({ data, selectedNft, setSelectedNft }: AllNftProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const { setUploadingProgress } = useGeneralContext();

  if (!data) return null;

  return (
    <>
      <div className={styles.allNft}>
        {data.map((el: NFTType, index: number) => (
          <div
            className={`${styles.imageContainer} ${selectedNft === index + 1 && styles.active}`}
            key={index}
            onClick={() => {
              setSelectedNft(index + 1);
              setShowDetails(true);
              setUploadingProgress(55);
            }}
          >
            <img
              src={el.image}
              onError={e => {
                e.currentTarget.src = imagePlaceholder;
              }}
              alt="#"
              className={styles.image}
            />
          </div>
        ))}
      </div>

      {selectedNft >= 0 && (
        <Details setShowDetails={setShowDetails} showDetails={showDetails} nft={data[selectedNft - 1]} />
      )}
    </>
  );
};

export default AllNft;
