export const SET_USER_IMAGE_PROFILE = "SELECT_USER_IMAGE_PROFILE";
export const SET_USER_NAME_PROFILE = "SET_USER_NAME_PROFILE";
export const REQUEST_USER_SIGNOUT = "REQUEST_USER_SIGNOUT";

export type UserSetupState = {
  name: string;
  image: string;
};

type SetImageUserAction = {
  type: "SELECT_USER_IMAGE_PROFILE";
  payload: string;
};

type SetNameUserAction = {
  type: "SET_USER_NAME_PROFILE";
  payload: string;
};

type RequestUserSignOut = {
  type: "REQUEST_USER_SIGNOUT";
  payload: string;
};

export type UserSetupAction = SetImageUserAction | SetNameUserAction | RequestUserSignOut;

export type SetImageUserPayload = {
  userImage: string;
};

export type SetNameUserPayload = {
  userName: string;
};
