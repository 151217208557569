import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";

import Header from "./components/Header/Header";
import { ProtectedRoute } from "./components/RouteHelper";
import CreateNewPost from "./components/pages/CreateNewPost/CreateNewPost";
import EditProfile from "./components/pages/EditProfile/EditProfile";
import Explore from "./components/pages/Explore/Explore";
import FindFriends from "./components/pages/Explore/FindFriends/FindFriend";
import NFTs from "./components/pages/Explore/Nfts/Nfts";
import PostDetails from "./components/pages/Home/Posts/PostDetails/PostDetails";
import Posts from "./components/pages/Home/Posts/Posts";
import SinglePostDetail from "./components/pages/Home/Posts/SinglePostDetail";
import MarketPlace from "./components/pages/MarketPlace/MarketPlace";
import Message from "./components/pages/Messages/Message";
import MyProfile from "./components/pages/MyProfile/MyProfile";
import SetUpYourProfile from "./components/pages/SetUpYourProfile/SetUpYourProfile";
import UserProfile from "./components/pages/UserProfile/UserProfile";
import NftDetails from "./components/pages/Wallet/Posts/NftDetails/NftDetails";
import Wallet from "./components/pages/Wallet/Wallet";
import { AuthContextProvider } from "./context/AuthContext";
import { store } from "./redux/store";
import Leaderboard from "@/components/pages/Leaderboard/Leaderboard";

function App() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <div className="background"></div>
        <div className="container">
          <Header />
          <div className="rightSide">
            <Routes>
              <Route path="/" element={<Posts />} />
              <Route path="/feed" element={<Posts />} />
              <Route path="/detail/:id" element={<SinglePostDetail />} />
              <Route path="/createNewPost" element={<ProtectedRoute><CreateNewPost /></ProtectedRoute>} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="nfts/:tokenAddress/:tokenId/:owner?" element={<NftDetails />} />
              <Route path="/profile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
              <Route path="/editprofile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
              <Route path="/setupProfile" element={<ProtectedRoute><SetUpYourProfile /></ProtectedRoute>} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/explore/nfts" element={<NFTs />} />
              <Route path="/explore/findfriends" element={<FindFriends />} />
              <Route path="/marketplace" element={<MarketPlace />} />
              <Route path="/userProfile/:username" element={<UserProfile />} />
              <Route path="/messages/:id" element={<ProtectedRoute><Message /></ProtectedRoute>} />
              <Route path="/messages/" element={<ProtectedRoute><Message /></ProtectedRoute>} />
              <Route path="/post/:id" element={<PostDetails />} />
              <Route path="/leaderboard" element={<ProtectedRoute><Leaderboard /></ProtectedRoute>} />
            </Routes>
          </div>
        </div>{" "}
      </AuthContextProvider>
    </Provider>
  );
}

export default App;
