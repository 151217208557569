import { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";

import { AddUserToDb } from "@/Database/User";
import { UserDocInterface } from "@/Database/User/User.definition";
import { bannerDefault, imagePlaceholder, nft1 } from "@/assets/images/image";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Input from "@/components/Input/Input";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";

import SelectBanner from "./SelectBanner/SelectBanner";
import SelectProfilePicture from "./SelectProfilePicture/SelectProfilePicture";

import styles from "./styles.module.css";

const EditProfile = () => {
  const { currentUser, currentUserData, mapUsernameToUuid } = useAuth();
  const navigate = useNavigate();

  const [selectedProfilePic, setSelectedProfilePic] = useState<string>(currentUserData?.avatarPhoto ?? nft1);
  const [showProfilePhotosModal, setShowProfilePhotosModal] = useState(false);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState({
    username: currentUserData?.username!,
    defaultAddress: currentUserData?.defaultAddress ?? currentUser?.displayName ?? "",
    bio: currentUserData?.bio ?? "",
    email: currentUserData?.email ?? "",
    link: currentUserData?.link ?? "",
  });
  const [bannerImage, setBannerImage] = useState(currentUserData?.bannerPhoto);

  const address = [currentUser?.displayName ?? ""];
  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | undefined
  ) => {
    if (e) setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSaving(true);
    const userToSetup: UserDocInterface = {
      uuid: currentUserData?.uuid!,
      address: currentUser?.displayName!,
      username: values.username.toLowerCase(),
      avatarPhoto: selectedProfilePic,
      bannerPhoto: bannerImage ?? bannerDefault,
      defaultAddress: values.defaultAddress,
      bio: values.bio,
      email: values.email,
      link: values.link,
    };
    try {
      await AddUserToDb(userToSetup);
      navigate("/profile");
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const isDuplicate = (username: string) => {
    const existingUsername = mapUsernameToUuid?.get(username);
    return existingUsername && existingUsername !== currentUserData?.uuid;
  };

  const tabs = [
    { navItem: "Edit profile", to: "/editProfile" },
    // { navItem: "Notifications", to: "/notifications" },
    // { navItem: "Account Support", to: "/accountsupport" },
  ];
  return (
    <>
      <div className={`${styles.profileWrapper} `}>
        {" "}
        <div className={styles.tabsContainer}>
          {tabs.map((el, i) => (
            <NavLink to={el.to} key={i} className={styles.text}>
              {el.navItem}
            </NavLink>
          ))}
        </div>
        <form className={`${styles.profile} `}>
          <div>
            <p className={styles.text}>Profile NFT</p>
            <div className={styles.user} onClick={() => setShowProfilePhotosModal(prev => !prev)}>
              {" "}
              <HexagonImage
                src={selectedProfilePic ? selectedProfilePic : imagePlaceholder}
                background={""}
              />
            </div>
          </div>
          <div className={styles.bannerContainer}>
            <p className={styles.text}>Banner photo</p>
            <div className={styles.bannerImageContainer}>
              <label
                htmlFor="banner"
                className={styles.editWrapper}
                onClick={() => setShowBannerModal(prev => !prev)}
              >
                <BiEditAlt className={styles.editIcon} />
              </label>
              <img src={bannerImage ? bannerImage : bannerDefault} alt="#" className={styles.banner} />
            </div>
          </div>

          <Input
            className={`${isDuplicate(values["username"].toLocaleLowerCase()) && styles.warningBorder}`}
            type="text"
            name="username"
            label="Username"
            placeholder="username"
            value={values["username"]}
            onChange={e => handleOnChange(e)}
          />
          {isDuplicate(values["username"].toLocaleLowerCase()) && (
            <div className={`${styles.text} ${styles.duplicateUsername}`}>Username is already taken</div>
          )}
          <div className={styles.defaultAddressContainer}>
            <label htmlFor="address" className={`${styles.label} ${styles.text}`}>
              Default Address{" "}
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                id="address"
                name="address"
                value={values["defaultAddress"]}
                className={`${styles.input} ${styles.text} ${styles.addressInput}`}
                onChange={e => handleOnChange(e)}
              />
              <BsChevronDown className={styles.arrow} />

              <div className={styles.addressContainer}>
                {address.map((el, i) => (
                  <p
                    className={`${styles.text} ${styles.address}`}
                    key={i}
                    onClick={() => setValues({ ...values, defaultAddress: address[i] })}
                  >
                    {el}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <Input
            type="email"
            name="email"
            label="Email address"
            placeholder="email"
            value={values["email"]}
            onChange={e => handleOnChange(e)}
          />
          <div className={styles.textAreaContainer}>
            <label htmlFor="address" className={`${styles.label} ${styles.text}`}>
              Bio
            </label>
            <textarea
              className={`${styles.textArea}`}
              name="bio"
              id="bio"
              cols={30}
              rows={3}
              placeholder="Write your bio..."
              value={values["bio"]}
              onChange={e => handleOnChange(e)}
            ></textarea>
          </div>
          <Input
            type="text"
            name="link"
            label="Link"
            placeholder="link"
            value={values["link"]}
            onChange={e => handleOnChange(e)}
          />
          <button type="submit" className={`primaryButton ${styles.button}`} onClick={handleSave}>
            {saving ? <Loader size={18.67} padding={"0px"} /> : <span>Save changes</span>}
          </button>
        </form>
      </div>
      {showProfilePhotosModal && (
        <SelectProfilePicture
          setSelectedProfilePic={setSelectedProfilePic}
          setModal={setShowProfilePhotosModal}
        />
      )}
      {showBannerModal && <SelectBanner setSelectedBanner={setBannerImage} setModal={setShowBannerModal} />}
    </>
  );
};

export default EditProfile;
