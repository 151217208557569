// import { nft1, nft2, nft3, nft4, nft5, nft6, nft7, nft8, nft9, nft10 } from "@/assets/images/image";
import { useState } from "react";
import { useSelector } from "react-redux";

import { imagePlaceholder } from "@/assets/images/image";
import { RootState } from "@/redux/store";
import { NFTType, SelectedPhotoType } from "@/types";

import styles from "./SelectProfilePicture.module.css";

type SelectProfilePictureProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProfilePic: React.Dispatch<React.SetStateAction<string>>;
};

const SelectProfilePicture = ({ setModal, setSelectedProfilePic }: SelectProfilePictureProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState<SelectedPhotoType>({ id: -1, img: "" });
  const { nfts } = useSelector((state: RootState) => state.NFT);
  const pictures = nfts.map((e: NFTType) => e.image);

  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>Select your profile picture</h3>

          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className={`grScrollbar ${styles.photosDiv}`}>
          {pictures.map((elem, idx) => {
            return (
              <button onClick={() => setSelectedPhoto({ img: elem, id: idx })} key={idx + "photo"}>
                <img
                  style={{
                    borderColor: selectedPhoto.id === idx ? "#42CCD7" : "transparent",
                  }}
                  src={elem}
                  onError={e => {
                    e.currentTarget.src = imagePlaceholder;
                  }}
                  alt=""
                />
              </button>
            );
          })}
        </div>
        <button
          style={{ opacity: selectedPhoto ? "1" : "0.5" }}
          className={styles.button}
          onClick={() => {
            setSelectedProfilePic(selectedPhoto.img);
            setModal(false);
          }}
        >
          Select
        </button>
      </div>
    </>
  );
};

export default SelectProfilePicture;
