import { PostType } from "@/types";

import SinglePost from "./SinglePost/SinglePost";

import styles from "./NftPosts.module.css";

type NftPostsProps = {
  posts: PostType[] | undefined | null;
};

const NftPosts = ({ posts }: NftPostsProps) => {
  return (
    <div className={styles.posts}>
      {!posts?.length
        ? "No Posts Yet"
        : posts?.map(postData => <SinglePost post={postData} key={postData.post.id} />)}
    </div>
  );
};

export default NftPosts;
