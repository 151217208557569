import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

import { privacyPolicy, userAgreement } from "@/assets/docs/docs";
import {
  cardano,
  coinbase,
  connectWalletModalLogo,
  ethereum,
  metamask,
  phantom,
  solana,
  walletConnect,
} from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { Blockchains, Wallets } from "@/types";
import { CARDANO_SUPPORTED_WALLETS } from "@/utils/cardano-auth";

import styles from "./ConnectWalletModal.module.css";

const blockchains: Blockchains = [
  {
    name: "ethereum",
    icon: ethereum,
  },
  {
    name: "solana",
    icon: solana,
  },
  {
    name: "cardano",
    icon: cardano,
  },
];

const wallets: Wallets = {
  ethereum: [
    {
      name: "Metamask",
      icon: metamask,
    },
    {
      name: "Coinbase",
      icon: coinbase,
    },
    {
      name: "WalletConnect",
      icon: walletConnect,
    },
  ],
  solana: [
    {
      name: "Phantom",
      icon: phantom,
    },
  ],
  cardano: CARDANO_SUPPORTED_WALLETS,
};

const ConnectWalletModal = () => {
  const { setShowConnectWalletModal } = useGeneralContext();
  const [activeTab, setActiveTab] = useState("ethereum");
  const [connectErrorMessage, setConnectErrorMessage] = useState("");
  const [connectingWallet, setConnectingWallet] = useState<string | boolean>(false);

  const { Login, setCurrentUser } = useAuth();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const activeWalletOptions = wallets[activeTab as keyof Wallets];

  async function connectWallet(wallet: string) {
    setConnectingWallet(wallet);

    try {
      const { credentials } = await Login(wallet);

      if (credentials) {
        const { user } = credentials;
        setCurrentUser(user);
        setShowConnectWalletModal(false);
      }
    } catch (error: any) {
      setConnectErrorMessage("Unable to connect your wallet");
    }

    setConnectingWallet(false);
  }

  const changeTab = (tab: string) => {
    setConnectErrorMessage("");
    setActiveTab(tab.toLocaleLowerCase());
  };

  return (
    <>
      {" "}
      <div className={[styles.modal, styles.wrapper, "grScrollbar"].join(" ")}>
        <div className={styles.header}>
          <h2 className={styles.title}>Login to Continent</h2>
        </div>
        <div className={styles.logoContainer}>
          <img src={connectWalletModalLogo} alt="#" className={styles.logo} />
        </div>

        <div className={styles.walletContainer}>
          <p className={styles.tagline}>Choose a wallet</p>
          <div className={styles.tabContainer}>
            {blockchains.map(blockchain => (
              <button
                className={[styles.tab, activeTab === blockchain.name && styles.activeTab].join(" ")}
                onClick={() => changeTab(blockchain.name)}
                key={blockchain.name}
              >
                <img src={blockchain.icon} alt="#" className={styles.currencyIcon} />
              </button>
            ))}
          </div>
          {activeWalletOptions.map(wallet => (
            <div className={styles.wallet} key={wallet.name} onClick={() => connectWallet(wallet.name)}>
              {connectingWallet === wallet.name ? (
                <Loader />
              ) : (
                <>
                  <img src={wallet.icon} alt="#" className={styles.icon} />
                  <p className={styles.name}>{wallet.name}</p>
                  <p className={styles.text}>Connect to your {wallet.name} Wallet</p>
                </>
              )}
            </div>
          ))}
        </div>
        {connectErrorMessage && <div className={`${styles.text} ${styles.error}`}>{connectErrorMessage}</div>}
        <p className={styles.info}>
          By using Continent, you agree to our
          <br />
          <a href={userAgreement} target="_blank" rel="noreferrer" className={styles.highlight}>
            Terms of Service
          </a>{" "}
          and our
          <a href={privacyPolicy} target="_blank" rel="noreferrer" className={styles.highlight}>
            {" "}
            Privacy Policy.
          </a>
        </p>
        <MdClose className={styles.close} onClick={() => setShowConnectWalletModal(prev => !prev)} />
      </div>{" "}
      <div className={styles.overlay} onClick={() => setShowConnectWalletModal(prev => !prev)}></div>
    </>
  );
};

export default ConnectWalletModal;
