import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";

import {
  // share,
  copy,
  deleteIcon,
  edit,
} from "@/assets/images/image";
import Modal from "@/components/Modal/Modal";
import ShareModal from "@/components/pages/MyProfile/ShareModal/ShareModal";
import { useAuth } from "@/context/AuthContext";
import { NFTType } from "@/types";

import DeletePost from "./Modals/DeletePost/Delete";
import EditInformation from "./Modals/EditInformation/EditInformation";
import ReportModal from "./Modals/Report/ReportModal";
import OtherReasonModal from "./Modals/Report/ReportOtherModal/OtherReason";
import ThanksForReport from "./Modals/Report/ReportOtherModal/ThanksForReport";

import styles from "./styles.module.css";

type NftMoreProps = {
  setNftMore: React.Dispatch<React.SetStateAction<boolean>>;
  author: boolean;
  // remove optional after work on all CommentAndDetails component that use this component
  nft?: NFTType;
  postId?: string;
  setCommentAndDetailsModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NftMore = ({ setNftMore, author, nft, postId, setCommentAndDetailsModal }: NftMoreProps) => {
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [deletePostModal, setDeletePostModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [otherReason, setOtherReason] = useState(false);
  const [thansksForReport, setThanksforReport] = useState(false);

  const { currentUserData } = useAuth();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/post/${postId ?? ""}`);
    setNftMore(false);
  };

  return (
    <>
      <div className={`${styles.wrapper} ${styles.nftMore}`}>
        {author &&
          nft?.owner_of.toLocaleLowerCase() === currentUserData?.address.toLocaleLowerCase() &&
          nft?.token_address.startsWith("0x") && (
            <div className={styles.socialContainer} onClick={() => setEditProfileModal(prev => !prev)}>
              <div className={styles.socials}>
                <p className={styles.nameAndIcon}>
                  <img src={edit} alt="#" className={styles.socialIcon} />
                  <span className={styles.name}>Edit Price</span>
                </p>
                <AiOutlineArrowRight className={styles.arrow} />
              </div>
            </div>
          )}
        {/* <div
          className={styles.socialContainer}
          onClick={() => setShareModal((prev) => !prev)}
        >
          <div className={styles.socials}>
            <p className={styles.nameAndIcon}>
              <img src={share} alt="#" className={styles.socialIcon} />
              <span className={styles.name}>Share</span>
            </p>
            <AiOutlineArrowRight className={styles.arrow} />
          </div>
        </div> */}
        <div className={styles.socialContainer}>
          <div className={styles.socials}>
            <p className={styles.nameAndIcon}>
              <img src={copy} alt="#" className={styles.socialIcon} />
              <span className={styles.name}>Copy Link</span>
            </p>
            <AiOutlineArrowRight className={styles.arrow} onClick={handleCopyLink} />
          </div>
        </div>{" "}
        {/* {!author && (
          <div
            className={styles.socialContainer}
            onClick={() => setReportModal((prev) => !prev)}
          >
            <p className={styles.socials}>
              <p className={styles.nameAndIcon}>
                <img src={report} alt="#" className={styles.socialIcon} />
                <span className={styles.name}>Report</span>
              </p>
              <AiOutlineArrowRight className={styles.arrow} />
            </p>
          </div>
        )} */}
        {author && (
          <div className={styles.socialContainer} onClick={() => setDeletePostModal(prev => !prev)}>
            <div className={styles.socials}>
              <p className={styles.nameAndIcon}>
                <img src={deleteIcon} alt="#" className={styles.socialIcon} />
                <span className={styles.name}>Delete</span>
              </p>
              <AiOutlineArrowRight className={styles.arrow} />
            </div>
          </div>
        )}
        {/* {!author && (
          <div
            className={styles.socialContainer}
            onClick={() => setNftMore(false)}
          >
            <p className={styles.socials}>
              <p className={styles.nameAndIcon}>
                <img src={close} alt="#" className={styles.socialIcon} />
                <span className={styles.name}>Cancel</span>
              </p>
              <AiOutlineArrowRight className={styles.arrow} />
            </p>
          </div>
        )} */}
      </div>
      {editProfileModal && (
        <Modal setModal={setEditProfileModal} background={""}>
          <EditInformation setModal={setEditProfileModal} nft={nft!} />
        </Modal>
      )}
      {deletePostModal && (
        <DeletePost
          setModal={setDeletePostModal}
          nft={nft!}
          setCommentAndDetailsModal={setCommentAndDetailsModal}
        />
      )}
      {shareModal && (
        <Modal setModal={setShareModal} background={""}>
          <ShareModal setModal={setShareModal} />
        </Modal>
      )}{" "}
      {reportModal && (
        <Modal setModal={setReportModal} background={""}>
          <ReportModal setModal={setReportModal} setOtherReason={setOtherReason} />
        </Modal>
      )}{" "}
      {otherReason && (
        <Modal setModal={setOtherReason} background={""}>
          <OtherReasonModal setModal={setOtherReason} setThanksforReport={setThanksforReport} />
        </Modal>
      )}{" "}
      {thansksForReport && (
        <Modal setModal={setThanksforReport} background={""}>
          <ThanksForReport setModal={setThanksforReport} />
        </Modal>
      )}
    </>
  );
};

export default NftMore;
