import { NavLink } from "react-router-dom";

import { GroupDocInterface } from "@/Database/Message/Group.definition";
import { imagePlaceholder } from "@/assets/images/image";
import HexagonImage from "@/components/Hexagon/Hexagon";
import { useAuth } from "@/context/AuthContext";

import styles from "./ChatRoomSidebar.module.css";

type ChatRoomSidebarProps = {
  setRoomToggle: React.Dispatch<React.SetStateAction<boolean>>;
  group: GroupDocInterface;
};

const ChatRoomSidebar = ({ setRoomToggle, group }: ChatRoomSidebarProps) => {
  const { currentUserData, usersData } = useAuth();

  if (!group) return <></>;

  const isGroup = group.members.length > 2;
  const messageTo = !isGroup ? group?.members.find(e => e !== currentUserData?.uuid) : null;
  const typing = group.typing;
  const lastRead = group.readers?.find(e => e.uuid === currentUserData?.uuid)?.readUntil;
  const unread = !lastRead ? group.messages.length : group.messages.filter(e => e.sentAt > lastRead).length;
  const dateTime =
    group.messages?.length > 0
      ? new Date(group.messages[group.messages.length - 1].sentAt.seconds * 1000)
      : new Date();
  const time =
    group.messages?.length > 0
      ? dateTime.toDateString() === new Date().toDateString()
        ? dateTime.toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" })
        : dateTime.toLocaleDateString()
      : "";
  const groupPhoto = group.avatarPhoto;

  let lastMessageText = "";
  let messageCut = "";
  if (group.messages?.length > 0) {
    const lastMessage = group.messages[group.messages.length - 1];
    if (!lastMessage.type || lastMessage.type === "text") {
      lastMessageText = lastMessage.message.slice(0, 25);
      messageCut = lastMessage.message.length > 25 ? "..." : "";
    }
    if (lastMessage.type === "audio") lastMessageText = "🔊 Audio";
    if (lastMessage.type === "image") lastMessageText = "📷 Image";
  }

  return (
    <NavLink
      style={({ isActive, isPending }) => {
        return {
          backgroundColor: isActive ? "#D9D9D933" : "",
        };
      }}
      onClick={() => setRoomToggle(false)}
      to={`/messages/${group.uuid}`}
      className={styles.chatRoom}
    >
      {isGroup ? (
        <div className={styles.groupImages}>
          {groupPhoto ? (
            <HexagonImage src={groupPhoto} background={""} />
          ) : (
            <HexagonImage src={imagePlaceholder} background={""} />
          )}
        </div>
      ) : (
        <HexagonImage src={usersData?.get(messageTo!)?.avatarPhoto} background={""} />
      )}

      <div className={styles.rightRoom}>
        <div className={styles.roomDetails}>
          <h4 style={{ fontWeight: "700" }}>{isGroup ? group.name : usersData?.get(messageTo!)?.username}</h4>
          {typing.length > 0 && typing.some(e => e !== currentUserData?.uuid) ? (
            <p>
              <span>Typing...</span>
            </p>
          ) : (
            <div
              style={{
                color: unread > 0 ? "#FAFAFA" : "#FAFAFAB8",
                fontWeight: unread > 0 ? "500" : "400",
                width: "100%",
              }}
            >
              <div className={styles.lastMessage}>
                <div>
                  {lastMessageText}
                  {messageCut}
                </div>
                <div>{time}</div>
              </div>
            </div>
          )}
        </div>
        <div> {unread > 0 && <h5 className={styles.unread}>{unread}</h5>} </div>
      </div>
    </NavLink>
  );
};

export default ChatRoomSidebar;
