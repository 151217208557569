import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { BsShare } from "react-icons/bs";
import { Link } from "react-router-dom";

import Banner from "@/components/Banner/Banner";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Modal from "@/components/Modal/Modal";
import { useAuth } from "@/context/AuthContext";
import { formatShortAddress } from "@/utils";

import ForSale from "./Posts/ForSale/ForSale";
import NftPosts from "./Posts/NftPost/NftPosts";
import ShareModal from "./ShareModal/ShareModal";

import styles from "./MyProfile.module.css";

const MyProfile = () => {
  const { currentUserData, myPosts } = useAuth();
  const [nftPosts, setNftPosts] = useState(true);
  const [shareModal, setShareModal] = useState(false);

  return (
    <>
      <Banner user={currentUserData!} nftCount={myPosts?.length} />
      <div className={`${styles.pageContainer} wrapper`}>
        <div className={styles.profileHeader}>
          <div className={styles.userInfo}>
            <div className={styles.user}>
              <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
            </div>
            <p className={styles.name}>{currentUserData?.username}</p>
            <div className={styles.addressContainer}>
              <span className={`${styles.text} ${styles.address}`}>
                {formatShortAddress(currentUserData?.address)}
              </span>
              <BiChevronDown className={styles.downArrow} />
            </div>
            <p className={styles.text}>{currentUserData?.bio}</p>
            <a
              href={`https://${currentUserData?.link?.replace("https://", "")}`}
              rel="noreferrer"
              target="_blank"
              className={`${styles.url} ${styles.text}`}
            >
              {currentUserData?.link}
            </a>
          </div>
          <div className={styles.actionContainer}>
            <Link to="/editprofile" className={`${styles.button} ${styles.activeButton}`}>
              Edit profile
            </Link>{" "}
            <div className={styles.shareContainer} onClick={() => setShareModal(prev => !prev)}>
              <BsShare />
            </div>
          </div>
        </div>
        <div className={styles.postTypesContainer}>
          <div className={styles.postTypes}>
            <div
              className={`${styles.postType} ${nftPosts && styles.activePostType}`}
              onClick={() => setNftPosts(true)}
            >
              <span className={styles.postTypeText}>NFT posts</span>
            </div>{" "}
            <div
              className={`${styles.postType} ${!nftPosts && styles.activePostType}`}
              onClick={() => setNftPosts(false)}
            >
              <span className={styles.postTypeText}>For sale</span>
            </div>
          </div>
        </div>{" "}
        {nftPosts ? <NftPosts /> : <ForSale />}
        {shareModal && (
          <Modal setModal={setShareModal} background={""}>
            <ShareModal setModal={setShareModal} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default MyProfile;
