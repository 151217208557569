import { useEffect, useState } from "react";

import { imagePlaceholder } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import Loader from "@/components/Loader/Loader";
import { useAuth } from "@/context/AuthContext";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import { getBlockchain } from "@/utils";

import styles from "./styles.module.css";

type ProfilePicturesProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfilePictures = ({ setModal }: ProfilePicturesProps) => {
  const { setSelectedProfilePicture } = useGeneralContext();
  const [selectedPhoto, setSelectedPhoto] = useState({ img: "", id: -1 });
  const [pictures, setPictures] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const blockchain = getBlockchain(currentUser?.displayName);

  useEffect(() => {
    if (!currentUser?.displayName) return;
    setLoading(true);
    if (blockchain === "CARDANO") {
      getNftsBlockfrost(currentUser.displayName, "", undefined, undefined, true, true)
        .then(({ nfts }) => setPictures(nfts.map(e => e.image)))
        .finally(() => setLoading(false));
    } else {
      getNftsMoralis(currentUser.displayName, "", undefined, undefined, true, true, blockchain)
        .then(({ nfts }) => setPictures(nfts.map(e => e.image)))
        .finally(() => setLoading(false));
    }
  }, [currentUser?.displayName, blockchain]);

  return (
    <>
      <div onClick={() => setModal(false)} className={styles.overlay}></div>
      <div className={styles.modalWrapp}>
        <div className={styles.modalHeader}>
          <h3>Select Profile Picture</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className={`grScrollbar ${styles.photosDiv}`}>
          {loading ? (
            <Loader />
          ) : (
            pictures.map((elem: string, idx: number) => {
              return (
                <button onClick={() => setSelectedPhoto({ img: elem, id: idx })} key={idx + "photo"}>
                  <img
                    style={{
                      borderColor: selectedPhoto.id === idx ? "#42CCD7" : "transparent",
                    }}
                    src={elem}
                    onError={e => {
                      e.currentTarget.src = imagePlaceholder;
                    }}
                    alt=""
                  />
                </button>
              );
            })
          )}
        </div>
        {!loading && (
          <button
            style={{ opacity: selectedPhoto ? "1" : "0.5" }}
            className={styles.button}
            onClick={() => {
              setModal(false);
              setSelectedProfilePicture(selectedPhoto.img);
            }}
          >
            Select
          </button>
        )}
      </div>
    </>
  );
};

export default ProfilePictures;
