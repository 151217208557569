import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { NFTType } from "@/types";
import { DisplayAddr } from "@/utils";

import styles from "./styles.module.css";

type DetailsProps = {
  post: PostDocInterface;
  nft: NFTType | undefined;
};

const Details = ({ post, nft }: DetailsProps) => {
  const [showDetails, setShowDetails] = useState(true);
  const blockchain = nft ? (nft?.token_address.startsWith("0x") ? "Ethereum" : "Solana") : "...";
  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={{ border: !showDetails ? "none" : "" }}>
        <p className={styles.heading}>Details </p>
        {showDetails ? (
          <BsChevronUp className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        ) : (
          <BsChevronDown className={styles.arrow} onClick={() => setShowDetails(prev => !prev)} />
        )}
      </div>

      {showDetails && (
        <div className={styles.infoContainer}>
          <div>
            <div className={`${styles.titleContainer} `}>
              <p className={`${styles.text} ${styles.title}`}>Contract Address</p>{" "}
              <p className={`${styles.text} ${styles.title}`}>Token ID</p>{" "}
              <p className={`${styles.text} ${styles.title}`}>Token standard</p>
            </div>{" "}
            <div className={`${styles.info} `}>
              <p className={`${styles.text} ${styles.address}`}>{DisplayAddr(post?.tokenAddress)}</p>{" "}
              <p className={`${styles.text} `}>{post?.tokenId}</p>{" "}
              <p className={`${styles.text} `}>{nft?.contract_type ?? "..."}</p>
            </div>
          </div>
          <div>
            <div className={`${styles.titleContainer} `}>
              <p className={`${styles.text} ${styles.title}`}>Blockchain</p>{" "}
              {/* <p className={`${styles.text} ${styles.title}`}>Creator</p>{" "}
              <p className={`${styles.text} ${styles.title}`}>Total supply</p> */}
            </div>{" "}
            <div className={`${styles.info} `}>
              <p className={`${styles.text} `}>{blockchain}</p>{" "}
              {/* <p className={`${styles.text} ${styles.address}`}>{DisplayAddr( post?.tokenAddress )}</p>{" "}
              <p className={`${styles.text} `}>UNKNOWN</p> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
