import crypto from "crypto";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AddUserToDb,
  GetUserFromDbByReferral,
  GetUsersReferredByUser,
  addExperienceToUser,
  addUserToReferredUsers,
} from "@/Database/User";
import { UserDocInterface } from "@/Database/User/User.definition";
import { user } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import HexagonImage from "@/components/Hexagon/Hexagon";
import Loader from "@/components/Loader/Loader";
import ConnectUnsuccessFull from "@/components/pages/ConnectWallet/Modal/ConnectUnsuccessFull";
import { useAuth } from "@/context/AuthContext";
import { getGatingAvailability } from "@/services/moralis";

import ProfilePictures from "./ProfilePictures/ProfilePictures";

import styles from "./styles.module.css";

const SetUpYourProfile = () => {
  const { selectedProfilePicture } = useGeneralContext();
  const { referrer } = useGeneralContext();

  const { currentUser, currentUserData, setCurrentUserData, mapUsernameToUuid } = useAuth();

  const [userName, setUserName] = useState(currentUserData?.username ?? "");
  const [profilePicturesModal, setProfilePicturesModal] = useState(false);
  const [active, setActive] = useState(userName);
  const [inputLabel, setInputLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [canSignIn, setCanSignIn] = useState(false);

  useEffect(() => {
    setActive(userName);
  }, [userName]);

  useEffect(() => {
    if (!currentUser) return;

    getGatingAvailability(
      currentUser?.displayName!,
      process.env.GATING_ADDR || "0x6409fc5d2316ad04feb2447f22e81849a30d6224"
    )
      .then(isValid => {
        setCanSignIn(isValid);
        //setCanSignIn(true);
        setIsLoading(false);
      })
      .catch(e => {
        setCanSignIn(false);
        setIsLoading(false);
      });
  }, [currentUser]);

  const navigate = useNavigate();

  const addToReferred = async (user: string, referrerId: string) => {
    await addUserToReferredUsers(user, referrerId);
    await addExperienceToUser(referrerId, 50);
    await addExperienceToUser(user, 50);
    return true;
  };

  const userInReferral = async (user: string, referrer: string) => {
    const users = await GetUsersReferredByUser(referrer);
    if (users.length > 0) {
      return users.some(u => u == user);
    }
    return false;
  };

  const checkReferrer = async (user: string, referrer: string) => {
    const ref = await GetUserFromDbByReferral(referrer);
    if (ref) {
      const isInReferral = await userInReferral(user, ref.uuid);
      if (ref && !isInReferral) {
        addToReferred(user, ref.uuid);
      }
    }
  };

  const createReferral = async (salt?: string): Promise<string> => {
    // create hash with user uid and return first 8 chars, if already exists, create another one by adding the salt and hash again
    const hash = crypto.createHash("sha256");
    hash.update(currentUser?.uid! + salt);
    const referral = hash.digest("hex").slice(0, 8);
    // check if referral already exists
    const ref = await GetUserFromDbByReferral(referral);
    if (ref) {
      // if exists, add salt and hash again
      const salt = crypto.randomBytes(8).toString("hex");
      return createReferral(salt + "1");
    }
    return referral;
  };

  const handleClick = async () => {
    if (!currentUser) return;
    if (!userName) return;
    setSaving(true);
    const userToSetup: UserDocInterface = {
      username: userName.toLowerCase(),
      uuid: currentUser.uid,
      address: currentUser?.displayName!,
      avatarPhoto: selectedProfilePicture ? selectedProfilePicture : user,
      referral: await createReferral(),
      experience: 0,
    };
    try {
      await AddUserToDb(userToSetup);
      checkReferrer(userToSetup.uuid, referrer); //TODO: error if referrer is invalid?
      setCurrentUserData(userToSetup);
      navigate("/");
    } catch {
    } finally {
      setSaving(false);
    }
  };

  const isDuplicate = (username: string) => {
    if (!username) return false;
    const existingUsername = mapUsernameToUuid?.get(username);
    return existingUsername && existingUsername !== currentUserData?.uuid;
  };

  const SetupProfileComponent = () => {
    return (
      <>
        <div className={`marginTop ${styles.setUpProfile}`}>
          <h4 className={styles.heading}>Set up your profile</h4>
          <div className={styles.profilePhotoDiv}>
            {selectedProfilePicture ? (
              <div className={styles.profilePhoto}>
                {" "}
                <HexagonImage src={selectedProfilePicture} background={""} />
              </div>
            ) : (
              <div className={styles.profilePhoto}>
                {" "}
                <HexagonImage src={user} background={""} />
              </div>
            )}

            <button onClick={() => setProfilePicturesModal(true)}>
              {selectedProfilePicture ? "Change Profile Picture" : "Choose a Profile Picture"}
            </button>
          </div>
          <form className={styles.userNameContainer}>
            <div
              className={`${styles.inputContainer} ${(inputLabel || userName) && styles.paddingTop} ${
                isDuplicate(userName.toLocaleLowerCase()) && styles.warningBorder
              }`}
              onClick={() => setInputLabel(true)}
            >
              <label
                htmlFor="userName"
                className={`${styles.label} ${(inputLabel || userName) && styles.showLabel} ${
                  isDuplicate(userName.toLocaleLowerCase()) && styles.warning
                }`}
              >
                Create a username
              </label>

              <input
                type="text"
                id="userName"
                name="userName"
                autoFocus={true}
                value={userName}
                onChange={e => {
                  setUserName(e.target.value);
                }}
                className={styles.input}
              />
            </div>
            {isDuplicate(userName.toLocaleLowerCase()) && (
              <span className={`${styles.warning} ${styles.warningText}`}>This user name already exist.</span>
            )}
            <div className={`${styles.button} ${!active && styles.disableStyle} `} onClick={handleClick}>
              <div className={styles.buttonLoader}>
                {saving && <Loader size={20} padding={"0px"} width={"20px"} />}
                <span style={{ paddingLeft: saving ? "10px" : "0px" }}>Save</span>
              </div>
            </div>
          </form>
        </div>

        {profilePicturesModal && <ProfilePictures setModal={setProfilePicturesModal} />}
      </>
    );
  };

  if (isLoading) {
    return <></>;
  }

  return canSignIn ? (
    SetupProfileComponent()
  ) : (
    <ConnectUnsuccessFull setModal={b => {}} message="" haveToLogout={true} />
  );
};

export default SetUpYourProfile;
