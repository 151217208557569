import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";

import {
  CreateComment,
  GetCommentsFromPost,
  LikePost,
  /*, LikePost, UnlikePost */
  UnlikePost,
} from "@/Database/Posts";
import { CommentDocInterface, PostDocInterface } from "@/Database/Posts/Post.definition";
import { UserDocInterface } from "@/Database/User/User.definition";
import {
  // postImage,
  // user,
  // ethIcon,
  comment,
  love,
  lovefill,
} from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";
import Modal from "@/components/Modal/Modal";
import ShareModal from "@/components/pages/MyProfile/ShareModal/ShareModal";
import { useAuth } from "@/context/AuthContext";
import { formatDate, numberFormating } from "@/utils";

import Comment from "./Comment/Comment";

import styles from "./styles.module.css";

type CommentsAndDetailsProps = {
  data: PostDocInterface;
  userData: UserDocInterface;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CommentsAndDetails = ({ data, setModal, userData }: CommentsAndDetailsProps) => {
  const [postText, setPostText] = useState("");

  const { currentUserData } = useAuth();
  const { setShowConnectWalletModal } = useGeneralContext();

  const [shareModal, setShareModal] = useState(false);
  const [comments, setComments] = useState<CommentDocInterface[]>();
  const [isInteracting, setIsInteracting] = useState(false);
  const [like, setLike] = useState(data.likes.includes(currentUserData?.uuid ?? ""));
  const [likeCount, setLikeCount] = useState(data.likes.length ?? 0);

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (postText === "") return;
    if (isInteracting) return;
    setIsInteracting(true);
    CreateComment(data?.id!, currentUserData?.uuid!, postText).then(d => {
      setPostText("");
      FetchCommentPosts();
      setIsInteracting(false);
    });
  };

  const FetchCommentPosts = () => {
    GetCommentsFromPost(data.id!).then(cms => {
      if (cms) {
        setComments(cms);
      }
    });
  };

  useEffect(() => {
    FetchCommentPosts();
  }, []);

  const handleLike = async () => {
    if (!currentUserData) {
      setShowConnectWalletModal(true);
      return;
    }
    if (!data.id) return;
    if (like) {
      setLike(false);
      setLikeCount(prev => prev - 1);
      await UnlikePost(data.id, currentUserData?.uuid);
    } else {
      setLike(true);
      setLikeCount(prev => prev + 1);
      await LikePost(data.id, currentUserData?.uuid);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <img src={data.imageUrl} alt="#" className={styles.postImage} />
        <div className={styles.postDetails}>
          <div className={styles.header}>
            <div className={styles.userContainer}>
              <img src={userData.avatarPhoto} alt="#" className={styles.userImg} />{" "}
              <p className={styles.text}>{userData.username!}</p>
            </div>
          </div>
          <div className={styles.informationContainer}>
            <div className={styles.information}>
              <div className={styles.idAndbutton}>
                <p className={styles.text}>{data?.mtd?.name!}</p>
              </div>
              <div className={styles.aboutPost}>
                <p className={styles.text}>{userData.username!} </p>
                <p className={`${styles.text} ${styles.subText}`}>
                  {data.text} &nbsp;
                  <span className={styles.time}>{formatDate(new Date(data!.timestamp))}</span>
                </p>
              </div>
            </div>
            {/*data.ethAmount && (
              <div className={styles.ethContainer}>
                <span className={styles.eth}>{data.ethAmount}</span>
                <img src={ethIcon} alt="#" className={styles.ethIcon} />
              </div>
            )*/}
          </div>
          <div className={`${styles.commentsWrapper}  grScrollbar`}>
            {comments?.map((el, i) => <Comment data={el} key={i} />)}
          </div>{" "}
          <div className={styles.likeCommentSend}>
            <div className={styles.like}>
              <img src={like ? lovefill : love} alt="#" className={styles.icon} onClick={handleLike} />

              <span className={styles.number}>{numberFormating(likeCount)}</span>
            </div>
            <div className={styles.comment}>
              <img src={comment} alt="#" className={styles.icon} />
              <span className={styles.number}>{numberFormating(comments?.length ?? 0)}</span>
            </div>
          </div>
          <form className={styles.postInputContainer}>
            <input
              type="text"
              placeholder="Add new comment..."
              className={styles.postInput}
              name="post"
              value={postText}
              onChange={e => setPostText(e.target.value)}
            />
            <button type="submit" onClick={e => handleSubmit(e)} className={styles.postButton}>
              Post
            </button>
          </form>
        </div>
      </div>
      <MdOutlineClose className={styles.close} onClick={() => setModal(false)} />
      {shareModal && (
        <Modal setModal={setShareModal} background={""}>
          <ShareModal setModal={setShareModal} />
        </Modal>
      )}
      <div className={styles.overlay} onClick={() => setModal(false)}></div>
    </>
  );
};

export default CommentsAndDetails;
