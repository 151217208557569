import { Functions, getFunctions } from "@firebase/functions";
import { FirebaseApp } from "firebase/app";
import { Auth, UserCredential, getAuth, signInWithCustomToken } from "firebase/auth";

import { strToHex } from ".";
import { CIP30CardanoProvider, CardanoProvider } from "./CardanoProvider";
import { issueToken } from "./cardano-auth/issueToken";
import { CardanoNetwork, requestMessage } from "./cardano-auth/requestMessage";

export interface SignInWithCardanoOptions {
  provider?: CardanoProvider;
  network?: CardanoNetwork;
  wallet: string;
}

export interface SignInWithCardanoResponse {
  provider: CardanoProvider;
  credentials: UserCredential;
}

export async function signInWithCardano(
  tangocryptoAuth: TangocryptoAuth,
  options?: SignInWithCardanoOptions
): Promise<SignInWithCardanoResponse> {
  const wallet = options?.wallet || "eternl";
  const provider = options?.provider ?? getDefaultProvider(wallet);

  await provider.connect();

  const address = (await provider.getAddress()).to_bech32();
  const network = options?.network ?? (address.startsWith("addr_test") ? "testnet" : "mainnet");
  const context = await requestMessage(tangocryptoAuth, {
    networkType: "cardano",
    address: address,
    network: network,
    wallet,
  });

  const encodedMessage = strToHex(context.message);
  const signature = await provider.signMessage(encodedMessage);

  const token = await issueToken(tangocryptoAuth, {
    context,
    signature: signature.signature,
    key: signature.key,
  });
  const credentials = await signInWithCustomToken(tangocryptoAuth.auth, token);
  return {
    provider,
    credentials,
  };
}

function getDefaultProvider(wallet: string): CardanoProvider {
  // eslint-disable-next-line
  const provider = new CIP30CardanoProvider(wallet);
  return provider;
}

export interface TangocryptoAuth {
  auth: Auth;
  functions: Functions;
  functionNamePrefix: string;
}

export interface GetTangocryptoAuthOptions {
  /**
   * @default "ext-tangocrypto-auth-"
   */
  functionNamePrefix?: string;
  /**
   * @description The region the callable functions are located or a custom domain.
   * @example "asia-south1"
   */
  regionOrCustomDomain?: string;
  /**
   * @description Own instance of the `Auth` class.
   */
  auth?: Auth;
  /**
   * @description Own instance of the `Functions` class.
   */
  functions?: Functions;
}
export function getTangocryptoAuth(app: FirebaseApp, options?: GetTangocryptoAuthOptions): TangocryptoAuth {
  if (options?.regionOrCustomDomain && options?.functions) {
    throw new Error('You cannot set "regionOrCustomDomain" and "functions" parameters at the same time');
  }

  return {
    functionNamePrefix: options?.functionNamePrefix ?? "ext-tangocrypto-auth-",
    auth: options?.auth ?? getAuth(app),
    functions: options?.functions ?? getFunctions(app, options?.regionOrCustomDomain),
  };
}
