import { SET_USER_WALLET_CONNECT, UserAction, UserState } from "@/redux/types/user";

const INITIAL_STATE: UserState = {
  wallet: "",
};

export const UserReducer = (state = INITIAL_STATE, { type, payload }: UserAction) => {
  switch (type) {
    case SET_USER_WALLET_CONNECT:
      return {
        ...state,
        wallet: payload,
      };
    default:
      return state;
  }
};
