import { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";

import { GetPostById } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { GetUserFromDb } from "@/Database/User";
// import { ethIcon } from "@/assets/images/image";
import Modal from "@/components/Modal/Modal";
import { useAuth } from "@/context/AuthContext";
import { getNftsBlockfrost } from "@/services/blockfrost";
import { getNftsMoralis } from "@/services/moralis";
import { NFTType } from "@/types";
import { getBlockchain } from "@/utils";

// import PostBuy from "@/components/pages/Home/Posts/PostBuy/PostBuy";
import Description from "./DetailsDescriptionAndProperties/Description";
import Details from "./DetailsDescriptionAndProperties/Details";
import Properties from "./DetailsDescriptionAndProperties/Properties";

import styles from "./styles.module.css";

const PostDetails = () => {
  const navigate = useNavigate();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const params = useParams();
  const id = params.id ?? "";

  const [postData, setPostData] = useState<PostDocInterface>();
  const [, /*isLoading*/ setIsLoading] = useState<boolean>(true);

  const [, /*userName*/ setUserName] = useState<string>("Loading...");

  const [nft, setNft] = useState<NFTType>();
  const { usersData } = useAuth();

  useEffect(() => {
    GetPostById(id)
      .then(post => {
        if (post != null) {
          setPostData(post);
          const address = usersData?.get(post.userId)?.address ?? "";
          const blockchain = getBlockchain(address);
          if (blockchain === "CARDANO") {
            getNftsBlockfrost(address, "", post.tokenAddress, post.tokenId, false, false).then(({ nfts }) =>
              setNft(nfts[0])
            );
          } else {
            getNftsMoralis(address, "", post.tokenAddress, post.tokenId, false, false, blockchain).then(
              ({ nfts }) => setNft(nfts[0])
            );
          }
          return GetUserFromDb(post.userId);
        }
      })
      .then(u => {
        setUserName(u?.username!);
        setIsLoading(false);
      });
  }, [id, usersData]);

  return (
    <>
      <div className={`${styles.wrapper} marginTop`}>
        <div className={styles.postDetails}>
          <div>
            <div className={styles.header}>
              <HiOutlineArrowNarrowLeft className={styles.backArrow} onClick={() => navigate(-1)} />
              <h3 className={styles.title}>{postData?.mtd.name || "NAME"}</h3>
              {/* <p
                className={styles.price}
                onClick={() => setShowBuyModal((prev) => !prev)}
              >
                {" "}
                <span>0.038</span>
                <img src={ethIcon} alt="#" className={styles.ethIcon} />
              </p> */}
            </div>{" "}
            <p className={styles.name}>{""}</p>
            <div className={styles.imageContainer}>
              <img src={postData?.imageUrl} alt="#" className={styles.image} />
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <Details post={postData!} nft={nft} />
            <Description text={postData?.mtd.description} owner={postData?.tokenAddress || "UNK"} />
            {postData?.mtd.attributes && <Properties data={postData.mtd.attributes} />}
          </div>
        </div>
      </div>
      {showBuyModal && (
        <Modal setModal={setShowBuyModal} background={""}>
          {/* <PostBuy setModal={setShowBuyModal} {...data[id - 1]} /> */}
        </Modal>
      )}
    </>
  );
};

export default PostDetails;
