import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

import HexagonImage from "@/components/Hexagon/Hexagon";
import NotLoginWallet from "@/components/WhenNotLogin/NotLoginWallet/NotLoginWallet";
import { useAuth } from "@/context/AuthContext";
import { getBalance as getCardanoBalance } from "@/services/blockfrost";
import { getBalance } from "@/services/moralis";
import { formatShortAddress, getBlockchain, getStakeAddress, getTokenSymbol, lovelaceToAda } from "@/utils";

import Nfts from "./Posts/Nfts/NFts";

import styles from "./styles.module.css";

const Wallet = () => {
  const { currentUserData } = useAuth();

  const [nfts, setNfts] = useState(true);
  const [balance, setBalance] = useState("");

  const blockchain = getBlockchain(currentUserData?.address);
  const tokenSymbol = getTokenSymbol(blockchain);

  useEffect(() => {
    if (!currentUserData || !currentUserData.address) return;
    if (blockchain === "CARDANO") {
      getStakeAddress(currentUserData.address)
        .then(stakeAddress => getCardanoBalance(stakeAddress))
        .then(balance => {
          const adaBalance = lovelaceToAda(balance).toFixed(4);
          setBalance(adaBalance);
        });
    } else {
      getBalance(currentUserData.address, blockchain).then(currentBalance => {
        const balanceInEthers = ethers.utils.formatEther(currentBalance);
        const balanceInEthersWithDecimals = (+balanceInEthers).toFixed(4);
        setBalance(balanceInEthersWithDecimals);
      });
    }
  }, [currentUserData, blockchain]);

  const activeNft = () => {
    setNfts(true);
  };
  return (
    <>
      {currentUserData ? (
        <div className={`${styles.wrapper} marginTop`}>
          <div className={`${styles.pageContainer} wrapper `}>
            {" "}
            <h4 className={styles.title}>Portfolio</h4>
            <>
              <div className={styles.profileHeader}>
                <div className={styles.userInfo}>
                  <div className={styles.user}>
                    <HexagonImage src={currentUserData?.avatarPhoto} background={""} />
                  </div>

                  <div className={styles.priceAndAddress}>
                    {" "}
                    <div className={styles.addressContainer}>
                      <span className={`${styles.text} ${styles.address}`}>
                        {formatShortAddress(currentUserData?.address)}
                      </span>
                      <BiChevronDown className={styles.downArrow} />
                    </div>
                    <p className={styles.price}>
                      {balance.length > 0 ? `${balance} ${tokenSymbol}` : "..."}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.postCategoryContainer}>
                <div className={styles.postCategory}>
                  <div className={`${styles.post} ${nfts && styles.activePost}`} onClick={activeNft}>
                    <span className={styles.text}>NFTs</span>
                  </div>{" "}
                </div>
              </div>{" "}
              <Nfts currentUserData={currentUserData} blockchain={blockchain} />
            </>
          </div>
        </div>
      ) : (
        <NotLoginWallet />
      )}
    </>
  );
};

export default Wallet;
