import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GetLatestPosts } from "@/Database/Posts";
import { PostDocInterface } from "@/Database/Posts/Post.definition";
import { UserDocInterface } from "@/Database/User/User.definition";
import { useAuth } from "@/context/AuthContext";

import SingleUser from "./FindFriends/SingleUser/SingleUser";
import SingleNft from "./Nfts/SingleNft/SingleNft";

import styles from "./Explore.module.css";

const Explore = () => {
  const [latestPosts, setLatestPosts] = useState<PostDocInterface[]>();
  const [latestUsers, setLatestUsers] = useState<UserDocInterface[]>();

  const { currentUserData, usersData } = useAuth();

  useEffect(() => {
    GetLatestPosts(6).then(p => {
      setLatestPosts(p);
    });
  }, []);

  useEffect(() => {
    const users: UserDocInterface[] = [];

    usersData?.forEach(user => {
      if (user.uuid !== currentUserData?.uuid && !currentUserData?.following?.includes(user.uuid)) {
        users.push(user);
      }
    });

    const latestUsers: UserDocInterface[] = users.slice(0, 6);

    setLatestUsers(latestUsers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.explore}>
      <div className="wrapper marginTop">
        <div>
          {" "}
          <div className={styles.header}>
            <h3 className={styles.title}>{currentUserData ? "Who to Follow" : "Top Users"}</h3>
            <Link to="findfriends" className={styles.button}>
              See more
            </Link>
          </div>
          <div className={styles.wrapper}>
            {latestUsers?.map(userData => <SingleUser userData={userData} key={userData.uuid} />)}
          </div>
        </div>
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>NFTs</h3>
            <Link key={"nfts"} to="nfts" className={styles.button}>
              See more
            </Link>
          </div>
          <div className={styles.wrapper}>
            {latestPosts?.map(postData => <SingleNft postData={postData} key={postData.id} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
