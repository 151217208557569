import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { explore, home, leaderboard, marketplace, profile, wallet } from "@/assets/images/image.js";
import { useGeneralContext } from "@/components/Context";
import ConnectWalletModal from "@/components/WhenNotLogin/ConnectWalletModal/ConnectWalletModal";
import { useAuth } from "@/context/AuthContext";
import { NavItemType } from "@/types/index.js";

import styles from "./styles.module.css";

type SidebarProps = {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const Sidebar = ({ showSidebar, setShowSidebar }: SidebarProps) => {
  const { currentUserData } = useAuth();
  const { setUploadingProgress, showConnectWalletModal, setShowConnectWalletModal } = useGeneralContext();
  const { pathname } = useLocation();

  const [referralCopied, setReferralCopied] = useState(false);

  const handleReferralClick = () => {
    let link = window.location.href.replace(window.location.pathname, "");

    if (link.includes("?")) {
      link = link + "&ref=" + currentUserData?.referral;
    } else {
      link = link + "?ref=" + currentUserData?.referral;
    }

    navigator.clipboard.writeText(link);

    setReferralCopied(true);

    setTimeout(() => {
      setReferralCopied(false);
    }, 3000);
  };

  let path = pathname;
  if (path === "/feed") path = "/";
  if (path === "/editprofile") path = "/profile";
  if (path.startsWith("/nfts")) path = "/wallet";

  const navItems: NavItemType[] = [
    { icon: home, navItem: "Home", to: "/" },
    { icon: wallet, navItem: "Wallet", to: "/wallet" },
    { icon: marketplace, navItem: "Marketplace", to: "/marketplace" },
    { icon: explore, navItem: "Explore", to: "/explore" },
  ];
  if (currentUserData) navItems.push({ icon: profile, navItem: "Profile", to: "/profile" });

  const handleNewMessageClick = () => {
    setShowConnectWalletModal(true);
  };

  return (
    <>
      <div className={` ${styles.sidebar} ${showSidebar && styles.showSidebar}`}>
        {navItems.map((el: NavItemType, i: number) => (
          <NavLink
            to={el.to}
            className={({ isActive }) =>
              path === el.to ? [styles.navItemContainer, styles.navActive].join(" ") : styles.navItemContainer
            }
            onClick={() => setShowSidebar(false)}
            key={i}
          >
            {" "}
            <img src={el.icon} alt={el.navItem} className={styles.icon} />
            <p className={styles.navItem}>{el.navItem}</p>
          </NavLink>
        ))}
        {currentUserData ? (
          <>
            <Link
              to="/createNewPost"
              className={`primaryButton ${styles.button}`}
              onClick={() => setUploadingProgress(40)}
            >
              Create new post
            </Link>
            <div className={styles.referralContainer}>
              <NavLink
                to={"/leaderboard"}
                className={({ isActive }) =>
                  path === "/leaderboard"
                    ? [styles.navItemContainer, styles.navActive].join(" ")
                    : styles.navItemContainer
                }
                onClick={() => setShowSidebar(false)}
              >
                {" "}
                <img src={leaderboard} alt={""} className={styles.icon} />
                <p className={styles.navItem}>Leaderboard</p>
              </NavLink>
              <div className={styles.referralButton}>
                <div className={`${styles.button} ${styles.activeButton}`} onClick={handleReferralClick}>
                  {referralCopied ? (
                    <div>Copied to clipboard!</div>
                  ) : (
                    <>
                      <div>Invite a Friend</div>
                      <div>Get XP points Rewards 👀</div>
                    </>
                  )}
                </div>{" "}
              </div>
            </div>
          </>
        ) : (
          <div className={`primaryButton ${styles.button}`} onClick={handleNewMessageClick}>
            Create new post
          </div>
        )}
      </div>
      {showConnectWalletModal && <ConnectWalletModal />}
    </>
  );
};

export default Sidebar;
