import {
  Timestamp, //  //serverTimestamp,
  //  //arrayUnion
  //  //addDoc,
  //  //updateDoc,
  doc,
  documentId, //  //orderBy,
  //  //onSnapshot,
  // //  collection,
  //  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { addGroupToUser } from "@/Database/User";
import {
  /*db ,*/
  createCollection,
} from "@/firebase";

import { GroupDocInterface, UpdateableGroup } from "./Group.definition";

const GROUP_TABLE_NAME = "Group";
export const GroupCollection = createCollection<GroupDocInterface>(GROUP_TABLE_NAME);

export const AddGroupToDb = async (group: UpdateableGroup) => {
  const document = group.uuid.length > 0 ? doc(GroupCollection, group.uuid) : doc(GroupCollection);
  await setDoc<GroupDocInterface>(document, { ...group, uuid: document.id }, { merge: true });
  if (!group.deleted && group.members)
    for (let user of group.members) await addGroupToUser(user, document.id);
  return document.id;
};

export const GetGroupsFromDb = async (groupsId: string[]): Promise<GroupDocInterface[] | null> => {
  const r = await getDocs(query(GroupCollection, where(documentId(), "in", groupsId)));
  return r.docs.map(e => e.data());
};

export const updateTyping = async (group: GroupDocInterface, uuid: string, isTyping: boolean) => {
  const currentTyping: string[] = group.typing ? [...group.typing] : [];
  let newTyping: string[];
  if (isTyping) {
    newTyping = [...currentTyping];
    if (!newTyping.includes(uuid)) {
      newTyping.push(uuid);
    }
  } else {
    newTyping = currentTyping.filter(e => e !== uuid);
  }

  const document = doc(GroupCollection, group.uuid);
  await setDoc<GroupDocInterface>(document, { typing: newTyping }, { merge: true });
};

export const markMessagesRead = async (group: GroupDocInterface, uuid: string) => {
  const newReaders = group.readers ? group.readers.filter(e => e.uuid !== uuid) : [];
  newReaders.push({ uuid, readUntil: Timestamp.fromDate(new Date()) });
  const document = doc(GroupCollection, group.uuid);
  await setDoc<GroupDocInterface>(document, { readers: newReaders }, { merge: true });
};

export const getCurrentChat = async (uuid: string, uuid2: string) => {
  // Can't query with 2 array-contains, so I must query the chat for one user and then filter for the other
  const r = await getDocs(query(GroupCollection, where("members", "array-contains", uuid)));
  return r.docs
    .find(e => {
      const members = e.data().members;
      return members.length === 2 && members.includes(uuid2);
    })
    ?.data();
};
