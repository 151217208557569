import { connectWalletIcon } from "@/assets/images/image";
import { useGeneralContext } from "@/components/Context";

import styles from "./ConnectWalletButton.module.css";

const ConnectWalletButton = () => {
  const { setShowConnectWalletModal } = useGeneralContext();
  return (
    <button className={styles.connectWalletButton} onClick={() => setShowConnectWalletModal(true)}>
      <img src={connectWalletIcon} alt="#" className={styles.icon} /> Connect with wallet
    </button>
  );
};

export default ConnectWalletButton;
