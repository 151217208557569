import styles from "./Loader.module.css";

type LoaderProps = {
  size?: number;
  padding?: string;
  width?: string;
};

const Loader = ({ size = 0, padding = "", width }: LoaderProps) => {
  const paddingStyle = padding ? { padding } : {};
  const widthStyle = width ? { width } : {};
  const style = { ...paddingStyle, ...widthStyle };
  return (
    <div style={style} className={styles.loadingContainer}>
      <div
        style={size !== 0 ? { height: `${size}px`, width: `${size}px` } : {}}
        className={styles.loadingSpinner}
      ></div>
    </div>
  );
};

export default Loader;
